import React, { useState, useEffect } from "react";
import "./MainView.css";
import MoneyChart from "../components/MoneyChart";

import { IoMenu } from "react-icons/io5";
import { SiSocketdotio } from "react-icons/si";
import { BsSunFill } from "react-icons/bs";
import { FaUserCircle } from "react-icons/fa";

import { FcApproval } from "react-icons/fc";
import { MdCancel } from "react-icons/md";
import { BsArrowDownSquareFill } from "react-icons/bs";
import { BsArrowUpSquareFill } from "react-icons/bs";
import { FaFilter } from "react-icons/fa";

import { styled } from "@mui/system";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import SelectBox from "devextreme-react/select-box";

import Header from "../components/Header";

import { useDispatch } from "react-redux";
import { getTraders, getTotalInfo } from "../store";

const MainView = () => {
  const dispatch = useDispatch();
  const [traders, setTraders] = useState([]);
  const [selectedTrader, setSelectedTrader] = useState("all");
  const [selectedType, setSelectedType] = useState("Deposit");
  const [totalInfo, setTotalInfo] = useState({});

  useEffect(() => {
    dispatch(getTraders())
      .unwrap()
      .then((res) => {
        console.log(res);
        const allTradersOption = { traderId: "all", traderName: "All Traders" };
        setTraders([allTradersOption, ...res.data.data.traders]);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    dispatch(getTotalInfo())
      .unwrap()
      .then((res) => {
        console.log(res);
        setTotalInfo(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleTraderChange = (e) => {
    setSelectedTrader(e.value);
  };

  const handleChangeType = (event) => {
    setSelectedType(event.value);
  };

  function formatCurrency(amount) {
    return `${new Intl.NumberFormat("tr-TR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount)} ₼`;
  }

  return (
    <div className="dashboard">
      <div className="dashboard-information-container">
        <div className="information-deposit">
          <div className="information-header information-header--deposit">
            <BsArrowUpSquareFill className="information-header-icon" />
            <span>Deposit Today</span>
          </div>
          <div className="information-body">
            <div className="information-accepted-container">
              <div className="info-tag info-tag--accepted">Approved</div>
              <div className="info-body">
                <FcApproval className="info-icon" />
                <div className="info-count-container">
                  <div className="info-header">COUNT</div>
                  <div className="info-text">
                    {totalInfo?.depositApprovedCount}
                  </div>
                </div>
                <div className="info-amount-container">
                  <div className="info-header">AMOUNT</div>
                  <div className="info-text">
                    {formatCurrency(totalInfo?.depositApprovedTotal)}
                  </div>
                </div>
              </div>
            </div>

            <div className="information-rejected-container">
              <div className="info-tag info-tag--rejected">Declined</div>
              <div className="info-body">
                <MdCancel className="info-icon info-icon--reject" />
                <div className="info-count-container">
                  <div className="info-header">COUNT</div>
                  <div className="info-text">
                    {totalInfo?.depositDeclinedCount}
                  </div>
                </div>
                <div className="info-amount-container">
                  <div className="info-header">AMOUNT</div>
                  <div className="info-text">
                    {formatCurrency(totalInfo?.depositDeclinedTotal)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="information-incard">
          <div className="information-header information-header--incard">
            <BsArrowDownSquareFill className="information-header-icon" />
            <span>Withdrawal Today</span>
          </div>
          <div className="information-body">
            <div className="information-accepted-container">
              <div className="info-tag info-tag--accepted">Approved</div>
              <div className="info-body">
                <FcApproval className="info-icon" />
                <div className="info-count-container">
                  <div className="info-header">COUNT</div>
                  <div className="info-text">
                    {totalInfo?.withdrawalApprovedCount}
                  </div>
                </div>
                <div className="info-amount-container">
                  <div className="info-header">AMOUNT</div>
                  <div className="info-text">
                    {formatCurrency(totalInfo?.withdrawalApprovedTotal)}
                  </div>
                </div>
              </div>
            </div>
            <div className="information-rejected-container">
              <div className="info-tag info-tag--rejected">Declined</div>
              <div className="info-body">
                <MdCancel className="info-icon info-icon--reject" />
                <div className="info-count-container">
                  <div className="info-header">COUNT</div>
                  <div className="info-text">
                    {totalInfo?.withdrawalDeclinedCount}
                  </div>
                </div>
                <div className="info-amount-container">
                  <div className="info-header">AMOUNT</div>
                  <div className="info-text">
                    {formatCurrency(totalInfo?.withdrawalDeclinedTotal)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="information-filters">
          <div className="information-header information-header--filter">
            <FaFilter className="information-header-icon" />
            <span>Set Filter</span>
          </div>
          <div className="info-filter-body">
            <div className="info-input-group">
              <InputLabel
                id="demo-simple-select-label"
                style={{
                  color: "white",
                  fontSize: "1.3rem",
                  marginBottom: ".3rem",
                }}
              >
                Trader
              </InputLabel>
              {/* FIXME: */}
              <SelectBox
                items={traders}
                displayExpr="traderName"
                valueExpr="traderId"
                defaultValue="all"
                onValueChanged={handleTraderChange}
                showClearButton={false}
                style={{ backgroundColor: "#1B2434" }}
              />
            </div>
            <div className="info-input-group">
              <InputLabel
                id="demo-simple-select-label"
                style={{
                  color: "white",
                  fontSize: "1.3rem",
                  marginBottom: ".3rem",
                }}
              >
                Type
              </InputLabel>
              <SelectBox
                dataSource={[
                  { id: 1, name: "Deposit" },
                  { id: 2, name: "Withdrawal" },
                ]}
                displayExpr="name"
                valueExpr="name"
                value={selectedType}
                onValueChanged={handleChangeType}
                style={{ backgroundColor: "#1B2434" }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="dashboard-chart">
        <MoneyChart />
      </div>
    </div>
  );
};

export default MainView;
