import React, { useState, useEffect } from "react";
import "./AdminController.css";

import { IoMdRefresh } from "react-icons/io";
import { FaClock } from "react-icons/fa6";
import { FaUserGear } from "react-icons/fa6";
import { PiHandDepositFill } from "react-icons/pi";
import { PiDownloadSimpleBold } from "react-icons/pi";
import PaymentGateway from "./PaymentGateway";
import { TextBox, TagBox, SelectBox } from "devextreme-react";

import Button from "@mui/material/Button";

import { getTraders, createUser, getUsers } from "../store";
import { useDispatch, useSelector } from "react-redux";

import { showNotification } from "../components/NotificationProvider";
import { FaClipboard } from "react-icons/fa";
import { FaSave } from "react-icons/fa";

import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { IoPersonAddSharp } from "react-icons/io5";
import { IoIosListBox } from "react-icons/io";

import UserManagementPanel from "../components/UserManagementPanel";

const AdminController = () => {
  const dispatch = useDispatch();
  const traders = useSelector((state) => state.trader.traders || []);
  const [selectedControl, setSelectedControl] = useState("");
  const [userName, setUserName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [userAuthCode, setUserAuthCode] = useState("");
  const [selectedTraders, setSelectedTraders] = useState([]);
  const [userCard, setUserCard] = useState(null);
  const [permissions, setPermissions] = useState(["Deposit", "Withdrawal"]);
  const [dropPermission, setDropPermission] = useState("false");

  const [navValue, setNavValue] = useState(0);
  const [users, setUsers] = useState([]);

  const fetchUsers = async () => {
    try {
      const response = await dispatch(getUsers()).unwrap();
      console.log(response);
      setUsers(response.data.data.users);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleCreateUser = async () => {
    setUserCard(null);

    const userData = {
      name: userName,
      lastName: userLastName,
      email: userEmail,
      password: userPassword,
      permissions: permissions,
      dropDepositPermission: dropPermission,
      traders: selectedTraders,
    };

    try {
      const response = await dispatch(createUser(userData)).unwrap();
      console.log(response);
      setUserCard({
        email: userEmail,
        password: userPassword,
        authCode: response.data.googleAuthenticatorCode,
      });
      showNotification("Success", "success", "User created successfully");

      fetchUsers();
    } catch (err) {
      console.log(err);
      showNotification("Error", "danger", err);
    }
  };

  useEffect(() => {
    const fetchTraders = async () => {
      try {
        await dispatch(getTraders()).unwrap();
      } catch (err) {
        console.log(err);
      }
    };

    fetchTraders();
  }, [dispatch]);

  const handleTraderChange = (e) => {
    if (e.value.includes("all")) {
      setSelectedTraders(traders.map((trader) => trader._id));
    } else {
      setSelectedTraders(e.value);
    }
  };

  const handleButtonClick = (control) => {
    setSelectedControl(control);
  };

  const handleCopy = () => {
    const userInfo = `Email: ${userCard.email}\nPassword: ${userCard.password}\nAuthenticator Code: ${userCard.authCode}`;
    navigator.clipboard.writeText(userInfo).then(
      () => {
        showNotification("Copied", "success", "User info copied to clipboard");
      },
      (err) => {
        showNotification("Error", "danger", err);
      }
    );
  };

  return (
    <div className="admin-controller">
      <div className="deposit-body">
        <div className="deposit-header-container">
          <div className="deposit-header">
            <FaClock className="deposit-header-icon" />
            <span>Admin Controller </span>
          </div>

          <div className="deposit-header-refresh">
            <IoMdRefresh className="refresh-icon" />
            <span>Refresh</span>
          </div>
        </div>
        <div className="admin-controller-header">
          <div
            className={
              selectedControl === "user-management"
                ? "admin-button admin-button-active"
                : "admin-button"
            }
            onClick={() => handleButtonClick("user-management")}
          >
            <FaUserGear className="admin-button-icon" />
            <span>User Management</span>
          </div>

          {/* <div
            className={
              selectedControl === "deposit-management"
                ? "admin-button admin-button-active"
                : "admin-button"
            }
            // onClick={() => {
            //   handleButtonClick("deposit-management");
            // }}
          >
            <PiHandDepositFill className="admin-button-icon" />
            <span>Deposit Management</span>
          </div> */}

          {/* <div
            className={
              selectedControl === "withdrawal-management"
                ? "admin-button admin-button-active"
                : "admin-button"
            }
            onClick={() => {}}
          >
            <PiDownloadSimpleBold className="admin-button-icon" />
            <span>Withdrawal Management</span>
          </div> */}
        </div>
        <div className="admin-controller-body">
          {selectedControl === "user-management" && (
            <div className="selected-control-panel">
              <div className="control-panel-header">
                <div>User Management</div>
                <BottomNavigation
                  showLabels
                  value={navValue}
                  onChange={(event, newValue) => {
                    setNavValue(newValue);
                  }}
                  sx={{
                    marginLeft: "2rem",
                    backgroundColor: "#1C273A",
                    height: "80px", // Yüksekliği ayarlama
                    fontSize: "3rem",
                    "& .MuiBottomNavigationAction-root": {
                      color: "#FFFFFF", // Varsayılan metin ve ikon rengi
                    },
                    "& .Mui-selected": {
                      color: "#00E396", // Seçili öğenin rengi
                    },
                  }}
                >
                  <BottomNavigationAction
                    label="Create User"
                    icon={<IoPersonAddSharp />}
                  />
                  <BottomNavigationAction
                    label="User List"
                    icon={<IoIosListBox />}
                  />
                </BottomNavigation>
              </div>

              <div className="control-panel-body">
                {navValue === 0 && (
                  <div className="control-panel-user">
                    {userCard && (
                      <div className="user-card">
                        <div className="button-copy">
                          <Button
                            icon="copy"
                            type="default"
                            onClick={handleCopy}
                          />
                        </div>
                        <div>Email: {userCard.email}</div>
                        <div>Password: {userCard.password}</div>
                        <div>Authenticator Code: {userCard.authCode}</div>
                      </div>
                    )}
                    <div className="profile-input-group-col">
                      <label>Person Name</label>
                      <TextBox
                        className="custom-textbox"
                        value={userName}
                        onValueChanged={(e) => setUserName(e.value)}
                        placeholder="First Name"
                      />
                    </div>

                    <div className="profile-input-group-col">
                      <label>Last Name</label>
                      <TextBox
                        className="custom-textbox"
                        value={userLastName}
                        onValueChanged={(e) => setUserLastName(e.value)}
                        placeholder="Last Name"
                      />
                    </div>
                    <div className="profile-input-group-col">
                      <label>Email</label>
                      <TextBox
                        className="custom-textbox"
                        value={userEmail}
                        onValueChanged={(e) => setUserEmail(e.value)}
                        placeholder="Email"
                      />
                    </div>
                    <div className="profile-input-group-col">
                      <label>Password</label>
                      <TextBox
                        className="custom-textbox"
                        value={userPassword}
                        onValueChanged={(e) => setUserPassword(e.value)}
                        placeholder="Password"
                      />
                    </div>
                    <div className="profile-input-group-col">
                      <label>Permissions</label>
                      <TagBox
                        items={["Deposit", "Withdrawal"]}
                        value={permissions}
                        onValueChanged={(e) => {
                          setPermissions(e.value);
                          if (!e.value.includes("Deposit")) {
                            setDropPermission("false");
                          }
                        }}
                        showSelectionControls={true}
                        placeholder="Select Permissions"
                        width="100%"
                      />
                    </div>
                    {permissions.includes("Deposit") && (
                      <div className="profile-input-group-col">
                        <label>Deposit Drop Permission</label>
                        <SelectBox
                          className="custom-textbox"
                          dataSource={[
                            { id: "true", name: "True" },
                            { id: "false", name: "False" },
                          ]}
                          displayExpr="name"
                          valueExpr="id"
                          value={dropPermission}
                          onValueChanged={(e) => setDropPermission(e.value)}
                          showClearButton={false}
                        />
                      </div>
                    )}

                    <div className="profile-input-group-col">
                      <label>Traders</label>
                      <TagBox
                        items={[
                          { _id: "all", traderName: "All Traders" },
                          ...traders,
                        ]}
                        displayExpr="traderName"
                        valueExpr="_id"
                        value={selectedTraders}
                        onValueChanged={handleTraderChange}
                        showSelectionControls={true}
                        placeholder="Select Traders"
                        width="100%"
                      />
                    </div>
                    <Button
                      variant="contained"
                      sx={{
                        width: "25rem",
                        fontSize: "1.2rem",
                        fontWeight: "700",
                        height: "4rem",
                        // backgroundColor: "#D6336C",
                        // "&:hover": {
                        //   backgroundColor: "#D6336C",
                        // },
                      }}
                      color="secondary"
                      startIcon={<FaSave style={{ fontSize: "1.3rem" }} />}
                      onClick={handleCreateUser}
                    >
                      Create Panel User
                    </Button>
                  </div>
                )}
                {navValue === 1 && (
                  <UserManagementPanel
                    users={users}
                    traderList={traders}
                    fetchUsers={fetchUsers}
                  />
                )}
              </div>
            </div>
          )}

          {selectedControl === "deposit-management" && (
            <div className="selected-control-panel">
              <div className="control-panel-header">
                Create Deposit (for test)
              </div>
              <div className="control-panel-body">
                <PaymentGateway />
              </div>
            </div>
          )}

          {selectedControl === "withdrawal-management" && (
            <div className="selected-control-panel">
              <div className="control-panel-header">Withdrawal Management</div>
              <div className="control-panel-body"></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminController;
