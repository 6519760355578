import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";

import "./assets/css/dx.edark.css";

import { Provider, useDispatch } from "react-redux";
import NotificationProvider from "./components/NotificationProvider";
import { store } from "./store";

const element = document.getElementById("root");

const root = createRoot(element);

root.render(
  <Provider store={store}>
    <NotificationProvider>
      <App />
    </NotificationProvider>
  </Provider>
);
