import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "./axios";

const getWithdrawalQueue = createAsyncThunk(
  "withdrawals/getWithdrawalQueue",
  async (_, { rejectWithValue }) => {
    try {
      const headers = { "Content-Type": "application/json" };

      const response = await axios.get("/withdrawals/getWithdrawalQueue", {
        headers,
      });

      return response;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data.message);
    }
  }
);

const getWithdrawalHistory = createAsyncThunk(
  "withdrawals/getWithdrawalHistory",
  async (dateRange, { rejectWithValue }) => {
    try {
      const headers = { "Content-Type": "application/json" };

      const response = await axios.post(
        "/withdrawals/getWithdrawalHistory",
        { dateRange },
        { headers }
      );

      return response;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data.message);
    }
  }
);

const approveWithdrawal = createAsyncThunk(
  "withdrawals/approveWithdrawal",
  async (id, { rejectWithValue }) => {
    try {
      const headers = { "Content-Type": "application/json" };

      const response = await axios.post(
        "/withdrawals/approve",
        { id },
        {
          headers,
        }
      );

      return response;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data.message);
    }
  }
);

const declineWithdrawal = createAsyncThunk(
  "withdrawals/declineWithdrawal",
  async (id, { rejectWithValue }) => {
    try {
      const headers = { "Content-Type": "application/json" };

      const response = await axios.post(
        "/withdrawals/decline",
        { id },
        {
          headers,
        }
      );

      return response;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data.message);
    }
  }
);

export {
  getWithdrawalQueue,
  getWithdrawalHistory,
  approveWithdrawal,
  declineWithdrawal,
};
