// NotificationProvider.js

import React from "react";
import { ReactNotifications, Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css/animate.min.css";

const NotificationProvider = ({ children }) => {
  return (
    <div>
      <ReactNotifications />
      {children}
    </div>
  );
};

let notificationQueue = [];
const maxNotifications = 2;

export const showNotification = (title, type, message) => {
  if (notificationQueue.length >= maxNotifications) {
    const oldestNotificationId = notificationQueue.shift();
    Store.removeNotification(oldestNotificationId);
  }

  const notificationId = Store.addNotification({
    title: title,
    message: message,
    type: type,
    insert: "bottom",
    container: "bottom-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 3000,
      //   onScreen: true,
    },
  });

  notificationQueue.push(notificationId);
};

export default NotificationProvider;
