import React, { useEffect, useState } from "react";
import "./LoginScreen.css";
import { useNavigate } from "react-router-dom";

import TextField from "@mui/material/TextField";

import { styled } from "@mui/system";
import Divider from "@mui/material/Divider";

import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import { useDispatch } from "react-redux";
import { loginStep1, loginStep2, getMe } from "../store";
import axios from "axios";

import { Popup } from "devextreme-react/popup";
import OtpInput from "react-otp-input";

import { showNotification } from "../components/NotificationProvider";
import { Button } from "devextreme-react/button";

const CustomTextField = styled(TextField)({
  "& .MuiInputBase-input": {
    fontSize: "16px", // Text font size
  },
  "& .MuiInputLabel-root": {
    fontSize: "16px", // Label font size
  },
  "& .MuiFormHelperText-root": {
    fontSize: "16px", // Helper text font size (optional)
  },
  marginBottom: "8px",
});

const CustomButton = styled(Button)({
  fontSize: "15px", // Button text font size
});

const LoginScreen = ({ onLogin, onExit, onGetUsername }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();
  const [popupVisible, setPopupVisible] = useState(false);
  // const [otp, setOtp] = useState("");
  const [token, setToken] = useState("");

  const [
    { otp, numInputs, separator, minLength, maxLength, placeholder, inputType },
    setConfig,
  ] = useState({
    otp: "",
    numInputs: 6,
    separator: "-",
    minLength: 0,
    maxLength: 40,
    placeholder: "",
    inputType: "text",
  });
  const [popupDimensions, setPopupDimensions] = useState({
    width: 700,
    height: 300,
    scale: 1,
  });
  const [otpInputWidth, setOtpInputWidth] = useState("8rem");

  const breakpoints = {
    smallMobile: 296,
    mediumMobile: 333,
    largeMobile: 408,
    smallTablet: 500,
    mediumTablet: 600,
    largeTablet: 701,
  };

  const calculatePopupDimensions = (screenWidth, screenHeight) => {
    if (screenWidth <= breakpoints.smallMobile && screenWidth >= 200) {
      return { width: "100%", height: 150, scale: 0.4 };
    } else if (
      screenWidth <= breakpoints.mediumMobile &&
      screenWidth > breakpoints.smallMobile
    ) {
      return { width: "100%", height: 300, scale: 0.45 };
    } else if (
      screenWidth <= breakpoints.largeMobile &&
      screenWidth > breakpoints.mediumMobile
    ) {
      return { width: "100%", height: 300, scale: 0.5 };
    } else if (
      screenWidth <= breakpoints.smallTablet &&
      screenWidth > breakpoints.largeMobile
    ) {
      return { width: "100%", height: 300, scale: 0.65 };
    } else if (screenWidth <= breakpoints.mediumTablet) {
      return { width: "100%", height: 300, scale: 0.8 };
    } else if (screenWidth <= breakpoints.largeTablet) {
      return { width: screenWidth * 0.95, height: 300, scale: 0.9 };
    } else {
      return { width: 700, height: 300, scale: 1 };
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      setPopupDimensions(calculatePopupDimensions(screenWidth, screenHeight));
    };

    // Add event listener for screen resize
    window.addEventListener("resize", handleResize);

    // Initial check
    handleResize();

    // Cleanup on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (otp.length === numInputs) {
      console.log("OTP: ", otp);
      handle2FAVerification(otp);
    }
  }, [otp]);

  const showPopup = () => {
    setPopupVisible(true);
  };

  const hidePopup = () => {
    setPopupVisible(false);
  };

  const getUserInformation = async () => {
    dispatch(getMe())
      .unwrap()
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));
  };

  const handleLogin = async () => {
    try {
      const response = await dispatch(loginStep1({ email, password })).unwrap();
      clearOtp();
      showPopup();
    } catch (err) {
      console.log(err);
      showNotification("Error", "danger", err);
    }
  };

  const handle2FAVerification = async (otpToken) => {
    try {
      console.log(otpToken);
      const response = await dispatch(loginStep2({ token: otpToken })).unwrap();
      getUserInformation();
      onLogin();
    } catch (err) {
      showNotification("Error", "danger", err);
    }
  };

  const handleOTPChange = (otp) => {
    setConfig((prevConfig) => ({ ...prevConfig, otp }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setConfig((prevConfig) => ({ ...prevConfig, [name]: value }));
  };

  const handleNumInputsChange = (event) => {
    let numInputs = event.target.valueAsNumber;

    if (numInputs < minLength || numInputs > maxLength) {
      numInputs = 4;

      console.error(
        `Please enter a value between ${minLength} and ${maxLength}`
      );
    }

    setConfig((prevConfig) => ({ ...prevConfig, numInputs }));
  };

  const clearOtp = () => {
    setConfig((prevConfig) => ({ ...prevConfig, otp: "" }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    alert(otp);
  };

  const handlePasteAndLogin = async () => {
    try {
      const text = await navigator.clipboard.readText();
      if (/^\d{6}$/.test(text)) {
        handleOTPChange(text);

        // handle2FAVerification(text);
        console.log("Login işlemi çalıştırılıyor...", text);
      } else {
        console.error("Clipboard'da geçerli bir OTP kodu yok.");
      }
    } catch (err) {
      console.error("Clipboard'dan veri okunurken hata oluştu:", err);
    }
  };

  return (
    <div className="login">
      <div className="login__container">
        <div className="login__container__card">
          <div className="login__container__header">
            <img
              src={require("../assets/images/easypropaylogo.png")}
              alt="Logo"
              className="login__container__header__logo"
            />
            <div className="login__container__header__text">EASYPROPAY</div>
          </div>

          <div className="input__container">
            <div className="input__group">
              <CustomTextField
                id="filled-basic"
                label="Email"
                variant="filled"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="input__group">
              <CustomTextField
                id="filled-basic-2"
                label="Password"
                variant="filled"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <CustomButton
              variant="contained"
              onClick={handleLogin}
              sx={{ height: "4rem" }}
            >
              LOGIN
            </CustomButton>

            <Popup
              width={popupDimensions.width}
              height={popupDimensions.height}
              showTitle={false}
              title="Simple Popup"
              visible={popupVisible}
              onHiding={hidePopup}
              showCloseButton={true}
            >
              <div
                style={{
                  transform: `scale(${popupDimensions.scale})`,
                  transformOrigin: "center",
                  height: "fit-content",
                }}
              >
                <h3 style={{ textAlign: "center", fontSize: "3rem" }}>
                  Enter verification code
                </h3>
                <div className="otp-container">
                  <OtpInput
                    inputStyle={{
                      width: otpInputWidth,
                      height: "10rem",
                      margin: "0 1rem",
                      fontSize: "4rem",
                      borderRadius: "4px",
                      border: "1px solid rgba(0, 0, 0, 0.3)",
                      color: "#000",
                    }}
                    numInputs={numInputs}
                    onChange={handleOTPChange}
                    renderSeparator={<span>{separator}</span>}
                    value={otp}
                    placeholder={placeholder}
                    inputType={inputType}
                    renderInput={(props) => <input {...props} />}
                    shouldAutoFocus
                  />
                </div>
                <div className="buttons-container">
                  <Button
                    icon="back"
                    type="danger"
                    text="Cancel"
                    onClick={() => hidePopup()}
                  />
                  <Button
                    icon="lock"
                    type="default"
                    text="Paste & Login"
                    onClick={handlePasteAndLogin}
                  />
                </div>
              </div>
            </Popup>
          </div>
          <div></div>
          <div className="login__container__language">
            <div className="login__container__language__text">ENGLISH</div>
            <Divider orientation="vertical" flexItem />
            <div className="login__container__language__text">TURKISH</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
