import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { subDays, format } from "date-fns";
import { useDispatch } from "react-redux";
import { getLastMonthInfo } from "../store";

const MoneyChart = () => {
  const dispatch = useDispatch();
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    dispatch(getLastMonthInfo())
      .unwrap()
      .then((data) => {
        console.log("Fetched data:", data); // Veriyi logla
        if (Array.isArray(data.data)) {
          const formattedData = formatChartData(data.data);
          setChartData(formattedData);
        } else {
          console.error("Data is not an array", data.data);
        }
      });
  }, [dispatch]);

  const formatChartData = (data) => {
    // Son 30 günü içeren tarih aralığı oluşturma
    let dateMap = new Map();
    for (let i = 0; i < 30; i++) {
      let date = format(subDays(new Date(), 29 - i), "yyyy-MM-dd");
      dateMap.set(date, 0); // Default olarak her tarihe 0 atanıyor
    }

    // Gelen verileri dateMap'e yerleştirme
    data.forEach((item) => {
      if (dateMap.has(item.date)) {
        dateMap.set(item.date, item.amount);
      }
    });

    // dateMap'ten diziye dönüştürme
    let formattedData = Array.from(dateMap, ([date, amount]) => ({
      date,
      amount,
    }));

    return formattedData;
  };

  // const generateData = () => {
  //   let data = [];
  //   for (let i = 0; i < 30; i++) {
  //     let date = format(subDays(new Date(), 29 - i), "yyyy-MM-dd");
  //     let amount = Math.floor(Math.random() * (800000 - 400000 + 1)) + 400000;
  //     data.push({ date, amount });
  //   }
  //   return data;
  // };

  const seriesData = chartData.map((d) => d.amount);
  const categoriesData = chartData.map((d) => d.date);

  const options = {
    chart: {
      type: "line",
      height: 350,
      zoom: {
        enabled: false,
      },
      background: "#1C273A",
      foreColor: "#fff",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      colors: ["#00E396"],
    },
    title: {
      text: "Last 30 Days Deposit / Withdraw",
      align: "left",
      style: {
        fontSize: "16px",
        color: "#fff",
      },
    },
    grid: {
      borderColor: "#404040",
      row: {
        colors: ["#404040", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: categoriesData,
      labels: {
        style: {
          colors: "#fff",
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#fff",
          fontSize: "12px",
        },
      },
    },
    tooltip: {
      theme: "dark",
    },
    legend: {
      labels: {
        colors: "#fff",
      },
    },
  };

  const series = [
    {
      name: "Money Amount",
      data: seriesData,
    },
  ];

  return (
    <div className="chart">
      <Chart options={options} series={series} type="line" height={550} />
    </div>
  );
};

export default MoneyChart;
