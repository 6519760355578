import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "./axios";

const getTraders = createAsyncThunk(
  "trader/getTraders",
  async (_, { rejectWithValue }) => {
    try {
      const headers = { "Content-Type": "application/json" };
      const response = await axios.get("/traders/getTraders", {
        headers,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

// const getTraders = createAsyncThunk("trader/getTraders", async () => {
//   const headers = { "Content-Type": "application/json" };

//   const response = await axios
//     .get("trades/getTraders", {
//       headers,
//     })
//     .then((res) => res)
//     .catch((err) => {
//       console.log(err);
//       // Promise.reject(err.response.data.message);
//     });

//   return response;
// });

export { getTraders };
