import { createSlice } from "@reduxjs/toolkit";

import { getDepositQueue, getDepositHistory } from "../thunks/Deposit";

const depositSlice = createSlice({
  name: "deposits",
  initialState: {
    deposits: [],
    loading: false,
    error: null,
  },

  extraReducers(builder) {
    builder
      .addCase(getDepositQueue.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getDepositQueue.fulfilled, (state, action) => {
        state.loading = false;
        state.deposits = action.payload;
      })
      .addCase(getDepositQueue.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(getDepositHistory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getDepositHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.deposits = action.payload;
      })
      .addCase(getDepositHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const depositReducer = depositSlice.reducer;
