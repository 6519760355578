import React, { useState, useEffect } from "react";
import "./CashOperations.css";

import { FaCashRegister } from "react-icons/fa6";
import { IoMenu } from "react-icons/io5";
import { SiSocketdotio } from "react-icons/si";
import { BsSunFill } from "react-icons/bs";
import { FaUserCircle } from "react-icons/fa";
//
import { IoMdRefresh } from "react-icons/io";
import { FaClock } from "react-icons/fa6";
import { IoIosSearch } from "react-icons/io";

import { styled } from "@mui/system";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import "rsuite/dist/rsuite.min.css";
import { DateRangePicker } from "rsuite";

import { FaMoneyBillTransfer } from "react-icons/fa6";
import { BsArrowDownSquareFill } from "react-icons/bs";
import { BsArrowUpSquareFill } from "react-icons/bs";
import { MdOutlineAccountBalance } from "react-icons/md";
import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid

import { FaAngleLeft } from "react-icons/fa6";
import { FaAngleRight } from "react-icons/fa6";

import Header from "../components/Header";

import SelectBox from "devextreme-react/select-box";
import { useDispatch } from "react-redux";
import { getTraders, getTotalInfo } from "../store";

import DataGrid, {
  Export,
  Selection,
  GroupPanel,
  Paging,
  Pager,
  Scrolling,
  ColumnFixing,
  SearchPanel,
  Column,
  HeaderFilter,
  FilterRow,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#82828282",
    },
    "&:hover fieldset": {
      borderColor: "#1c77d25e",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#1c77d25e",
    },
    height: "4rem",
    width: "15rem",
    overflow: "hidden",
  },
  "& .MuiInputBase-input": {
    fontSize: "18px",
    color: "white",
    backgroundColor: "#1B2434",
    padding: ".9rem",
  },
  "& .MuiInputLabel-root": {
    fontSize: "18px",
    color: "white",
    scale: "0.73",
  },
});

const CustomSelect = styled(Select)({
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#82828282",
  },
  "& .MuiOutlinedInput-input": {
    fontSize: "14px",
    color: "white",
    display: "flex",
    alignItems: "center",
  },
  "& .MuiSelect-icon": {
    color: "white",
  },
  backgroundColor: "#1B2434",
  height: "4rem",
  width: "17rem",
  overflow: "hidden",
});

const CashOperations = () => {
  const dispatch = useDispatch();
  const [selectedReportStatus, setSelectedReportStatus] = useState("Deposit");
  const [selectedReportTrader, setSelectedReportTrader] = useState("All");
  const [selectedDateRange, setSelectedDateRange] = useState("");
  const [selectedType, setSelectedType] = useState("Deposit");
  const [traders, setTraders] = useState([]);
  const [selectedTrader, setSelectedTrader] = useState("all");
  const [dateRange, setDateRange] = useState([
    new Date(new Date().setHours(0, 0, 0, 0)),
    new Date(new Date().setHours(23, 59, 59, 999)),
  ]);

  const handleDateChange = (range) => {
    setDateRange(range);
  };

  useEffect(() => {
    dispatch(getTraders())
      .unwrap()
      .then((res) => {
        console.log(res);
        const allTradersOption = { traderId: "all", traderName: "All Traders" };
        setTraders([allTradersOption, ...res.data.data.traders]);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleChangeType = (event) => {
    setSelectedType(event.value);
  };

  const handleTraderChange = (e) => {
    setSelectedTrader(e.value);
  };

  const [colDefs, setColDefs] = useState([
    { field: "ID" },
    { field: "Step" },
    { field: "Transaction ID" },
    { field: "Trader" },
    { field: "User Host ID" },
    { field: "User Host Name" },
    { field: "Sender" },
    { field: "Amount" },
    { field: "Receipent" },
    { field: "Transaction Date" },
    { field: "Trc. Status" },
    { field: "Rezerv" },
    { field: "Detail" },
  ]);

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleChangeReportStatus = (event) => {
    setSelectedReportStatus(event.target.value);
  };
  const handleChangeReportTrader = (event) => {
    setSelectedReportTrader(event.target.value);
  };

  return (
    <div className="cash-operations">
      <div className="deposit-body cash-operations-body">
        <div className="deposit-header-container">
          <div className="deposit-header">
            <FaCashRegister className="deposit-header-icon" />
            <span>Cash Operations</span>
          </div>
          <div className="deposit-header-refresh">
            <IoMdRefresh className="refresh-icon" />
            <span>Refresh</span>
          </div>
        </div>
        <div className="deposit-report-filters">
          <div className="deposit-report-info-right cash-operations-top">
            <div className="deposit-input-group deposit-report-input-group">
              <InputLabel
                id="demo-simple-select-label"
                style={{
                  color: "white",
                  fontSize: "1.3rem",
                  marginBottom: ".3rem",
                }}
              >
                Type
              </InputLabel>
              <SelectBox
                dataSource={[
                  { id: 1, name: "Deposit" },
                  { id: 2, name: "Withdrawal" },
                ]}
                displayExpr="name"
                valueExpr="name"
                value={selectedType}
                onValueChanged={handleChangeType}
                style={{ backgroundColor: "#1B2434" }}
              />
            </div>
            <div className="deposit-input-group deposit-report-input-group">
              <InputLabel
                id="demo-simple-select-label"
                style={{
                  color: "white",
                  fontSize: "1.3rem",
                  marginBottom: ".3rem",
                }}
              >
                Trader
              </InputLabel>
              <SelectBox
                items={traders}
                displayExpr="traderName"
                valueExpr="traderId"
                defaultValue="all"
                onValueChanged={handleTraderChange}
                showClearButton={false}
                style={{ backgroundColor: "#1B2434" }}
              />
            </div>
            <div className="deposit-input-group deposit-report-input-group">
              <InputLabel
                id="demo-simple-select-label"
                style={{
                  color: "white",
                  fontSize: "1.4rem",
                  marginBottom: ".7rem",
                }}
              >
                Date
              </InputLabel>
              <DateRangePicker
                size="md"
                placeholder="Select Date Range"
                block
                format="MM/dd/yyyy HH:mm"
                placement="bottomEnd"
                preventOverflow
                value={dateRange}
                onChange={handleDateChange}
                defaultValue={[
                  new Date(new Date().setHours(0, 0, 0, 0)),
                  new Date(new Date().setHours(23, 59, 59, 999)),
                ]}
              />
            </div>
          </div>
          <div className="cash-operations-bottom">
            <div className="information-container info-approve">
              <div className="info-tag info-tag--transferred">Transferred</div>
              <div className="info-body cash-operations-info-body">
                <FaMoneyBillTransfer className="info-icon info-transferred-icon" />

                <div className="info-amount-container">
                  <div className="info-header">AMOUNT</div>
                  <div className="info-text">0 ₼</div>
                </div>
              </div>

              <div className="info-tag info-tag--transferred-desc">
                Deducted from the commission
              </div>
            </div>

            <div className="information-container info-approve">
              <div className="info-tag info-tag--accepted">Deposit</div>
              <div className="info-body cash-operations-info-body">
                <BsArrowUpSquareFill className="info-icon info-green-icon" />
                <div className="info-count-container">
                  <div className="info-header">COUNT</div>
                  <div className="info-text">0</div>
                </div>
                <div className="info-amount-container">
                  <div className="info-header">AMOUNT</div>
                  <div className="info-text">0 ₼</div>
                </div>
              </div>

              <div className="info-tag info-tag--orange-desc">
                Commison: 0 ₼
              </div>
            </div>

            <div className="information-container info-approve">
              <div className="info-tag info-tag--red">Withdrawal</div>
              <div className="info-body cash-operations-info-body">
                <BsArrowDownSquareFill className="info-icon info-red-icon" />
                <div className="info-count-container">
                  <div className="info-header">COUNT</div>
                  <div className="info-text">0</div>
                </div>
                <div className="info-amount-container">
                  <div className="info-header">AMOUNT</div>
                  <div className="info-text">0 ₼</div>
                </div>
              </div>

              <div className="info-tag info-tag--orange-desc">
                Commison: 0 ₼
              </div>
            </div>

            <div className="information-container info-approve">
              <div className="info-tag info-tag--blue">Balance</div>
              <div className="info-body cash-operations-info-body">
                <MdOutlineAccountBalance className="info-icon info-blue-icon" />

                <div className="info-amount-container">
                  <div className="info-header">AMOUNT</div>
                  <div className="info-text">0 ₼</div>
                </div>
              </div>

              <div className="info-tag info-tag--blue-desc">
                Net balance amount
              </div>
            </div>
          </div>

          <Divider
            orientation="horizontal"
            variant="middle"
            flexItem
            sx={{ borderColor: "#ffffff14", margin: "30px 0" }}
          />

          <div className="deposit-filters cash-filters-2">
            <div className="deposit-filter-left blacklist-left">
              <div
                className="deposit-info blacklist-info"
                onClick={handleClick}
              >
                Total 0 Piece
              </div>
            </div>
            <div className="deposit-filter-right">
              <div className="deposit-input-group">
                <IoIosSearch className="deposit-search-icon" />
                <CustomTextField
                  id="outlined-read-only-input"
                  label="Search"
                  endAdornment={
                    <InputAdornment position="end"></InputAdornment>
                  }
                />
              </div>
            </div>
          </div>
          <div className="deposit-table cash-table">
            <div
              className="ag-theme-quartz" // applying the grid theme
              style={{ height: 500 }} // the grid will fill the size of the parent container
            >
              <DataGrid
                // dataSource={dataGridSourceMemo}
                showBorders={false}
                showRowLines={false}
                hoverStateEnabled={true}
                rowAlternationEnabled={false}
                allowColumnResizing={true}
                columnAutoWidth={true}
                columnMinWidth={50}
                columnResizingMode="nextColumn"
                height={712}
              >
                <Selection mode="single" />
                <ColumnFixing enabled />
                <GroupPanel visible={false} />
                {/* <SearchPanel visible={true} width={240} placeholder="Search" /> */}
                <Paging defaultPageSize={25} />
                <Pager
                  visible
                  allowedPageSizes={[25, 50, 100]}
                  showPageSizeSelector
                  showInfo
                  showNavigationButtons
                />
                <Scrolling
                  useNative={false}
                  scrollByContent
                  scrollByThumb
                  showScrollbar="onHover"
                />
              </DataGrid>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CashOperations;
