import { createSlice } from "@reduxjs/toolkit";

import {
  login,
  getMe,
  updateMe,
  updatePassword,
  updateAuthenticatorCode,
  createUser,
} from "../thunks/User";

import { getDepositQueue, getDepositHistory } from "../thunks/Deposit";
import { getWithdrawalQueue, getWithdrawalHistory } from "../thunks/Withdrawal";

const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
  },
  //   reducers: {
  //     setUser: (state, action) => {
  //       state.user = action.payload;
  //     },
  //   },
  extraReducers(builder) {
    builder.addCase(getMe.fulfilled, (state, action) => {
      state.user = action.payload.data.data;
    });

    builder.addCase(updateMe.fulfilled, (state, action) => {
      state.user = action.payload.data.data;
    });

    builder.addCase(updatePassword.fulfilled, (state, action) => {
      console.log(action.payload.data);
      state.user = action.payload.data.data;
    });

    builder.addCase(updateAuthenticatorCode.fulfilled, (state, action) => {
      state.user.user.googleAuthenticatorCode =
        action.payload.data.googleAuthenticatorCode;
    });

    //isLoading and error setup Deposit withrawal and user http requests
  },
});

export const userReducer = userSlice.reducer;
