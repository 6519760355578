import React, { useState, useEffect } from "react";
import "./Header.css";

import { IoMenu } from "react-icons/io5";
import { SiSocketdotio } from "react-icons/si";
import { BsSunFill } from "react-icons/bs";
import { FaUserCircle } from "react-icons/fa";

import { FaCreditCard } from "react-icons/fa";
import { PiHandWithdrawFill } from "react-icons/pi";
import { MdBlockFlipped } from "react-icons/md";

import { BiSolidReport } from "react-icons/bi";
import { FaHome } from "react-icons/fa";
import { IoSettingsSharp } from "react-icons/io5";
import { RiAdminFill } from "react-icons/ri";
import { FaCashRegister } from "react-icons/fa6";
import { useSelector } from "react-redux";

const Header = ({
  onMenuHandle,
  tempUsername,
  type,
  navHide,
  handleHeaderNavClick,
  screens,
  activeScreen,
  role,
}) => {
  const menuItems = [
    { screen: "panel-main", label: "Home", Icon: FaHome },
    { screen: "panel-deposit", label: "Pending Deposits", Icon: FaCreditCard },
    {
      screen: "panel-deposit-report",
      label: "Deposit Report",
      Icon: BiSolidReport,
    },
    {
      screen: "panel-incard",
      label: "Pending Withdrawals",
      Icon: PiHandWithdrawFill,
    },
    {
      screen: "panel-incard-report",
      label: "Withdrawal Report",
      Icon: BiSolidReport,
    },
    {
      screen: "panel-blacklist",
      label: "Black List Management",
      Icon: MdBlockFlipped,
    },
    { screen: "panel-options", label: "Settings", Icon: IoSettingsSharp },
    ...(role === "admin"
      ? [
          {
            screen: "panel-cash-operations",
            label: "Cash Operations",
            Icon: FaCashRegister,
          },
        ]
      : []),
    ...(role === "admin"
      ? [{ screen: "panel-admin", label: "Admin Panel", Icon: RiAdminFill }]
      : []),
  ];

  const { user } = useSelector((state) => state.user);
  const [username, setUsername] = useState("");

  useEffect(() => {
    if (user && user.user) {
      setUsername(user.user.name + " " + user.user.lastName);
    }
  }, [user]);

  return (
    <div
      className="options-header"
      style={type === "mobile" ? { backgroundColor: "#0E1825" } : {}}
    >
      <div className="header-container">
        <IoMenu className="menu-button" onClick={onMenuHandle} />
        {type === "mobile" && (
          <div
            className="header-brand"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: 2 + "rem",
              fontWeight: "700",
            }}
          >
            <img
              src={require("../assets/images/easypropaylogo.png")}
              alt="Logo"
              className="logo"
            />
            EASYPROPAY
          </div>
        )}
        <div className="header-right-container">
          <SiSocketdotio className="header-socket-status" />
          <BsSunFill className="header-theme" />
          {type !== "mobile" && (
            <div className="language-selector">
              <img
                src={require("../assets/images/en.svg").default}
                className="lang-en"
                alt="usa"
              />
              <span className="language-name">English</span>
            </div>
          )}

          <div className="header-user">
            <FaUserCircle className="header-user-icon" />
            <span className="header-user-text">{username}</span>
          </div>
        </div>
      </div>
      <div
        className={
          navHide
            ? "panel-menu panel-menu-header panel-menu-header-hide"
            : "panel-menu panel-menu-header"
        }
      >
        <ul className="header-nav-list">
          {menuItems.map((item) => (
            <li
              className={
                activeScreen === item.screen ? "active" : "panel-menu-item"
              }
              key={item.screen}
              onClick={() => handleHeaderNavClick(item.screen)}
            >
              <item.Icon className="panel-menu-item-logo" />
              {item.label}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Header;
