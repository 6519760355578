import React, { useState, useCallback, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import { MdOutlineCopyAll } from "react-icons/md";
import { showNotification } from "./NotificationProvider";
import Popup from "devextreme-react/popup";
import TagBox from "devextreme-react/tag-box";

import { useDispatch } from "react-redux";
import { updateUser, changeUserPassword, blockUser } from "../store";

const UserManagementPanel = ({ users, traderList, fetchUsers }) => {
  const dispatch = useDispatch();
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUserForPassword, setSelectedUserForPassword] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [dropPermission, setDropPermission] = useState(false);
  const [traders, setTraders] = useState([]); // Add this state
  const [newPassword, setNewPassword] = useState("");
  const [editPopupVisible, setEditPopupVisible] = useState(false);
  const [passwordPopupVisible, setPasswordPopupVisible] = useState(false);

  useEffect(() => {
    if (!editPopupVisible && selectedUser !== null) {
      setSelectedUser(null);
    }
  }, [editPopupVisible, selectedUser]);

  const handleUserSelect = (user) => {
    console.log("User selected:", user);
    setSelectedUser({
      ...user,
      userId: user._id,
      traders: user.traders.map((trader) => trader._id),
      permissions: user.permissions,
    });
    setEditPopupVisible(true);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSelectedUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handlePermissionChange = (e) => {
    const value = e.value;
    setSelectedUser((prevUser) => ({
      ...prevUser,
      permissions: value,
    }));
  };

  const handleDropDepositPermissionChange = () => {
    setSelectedUser((prevUser) => ({
      ...prevUser,
      dropDepositPermission: !prevUser.dropDepositPermission,
    }));
  };

  const handleTradersChange = (e) => {
    if (!selectedUser) return; // Prevent the function from running if selectedUser is null

    const selectedTraderIds = e.value.map((id) => id);
    if (
      JSON.stringify(selectedTraderIds) !==
      JSON.stringify(selectedUser?.traders)
    ) {
      setSelectedUser((prevUser) => ({
        ...prevUser,
        traders: selectedTraderIds,
      }));
    }
  };

  const handleSave = async () => {
    try {
      console.log(selectedUser);
      const response = await dispatch(updateUser(selectedUser)).unwrap();

      showNotification("Success", "success", "User updated successfully.");

      fetchUsers();

      setEditPopupVisible(false);
    } catch (error) {
      console.error("Failed to update user:", error);
      showNotification("Error", "danger", "Failed to update user.");
    }
  };

  const handleCopyCode = (code) => {
    navigator.clipboard.writeText(code).then(() => {
      showNotification(
        "Success",
        "success",
        "Google Authenticator Code copied to clipboard!"
      );
    });
  };

  const handleBlockUnblock = async (user) => {
    try {
      const updatedStatus = !user.blocked;

      await dispatch(
        blockUser({ userId: user._id, block: updatedStatus })
      ).unwrap();

      showNotification(
        "Success",
        "success",
        `User ${updatedStatus ? "blocked" : "unblocked"} successfully.`
      );

      await fetchUsers();
    } catch (error) {
      console.error("Failed to update user:", error);
      showNotification("Error", "error", "Failed to update user.");
    }
  };

  const handlePasswordChangeSave = () => {
    try {
      dispatch(
        changeUserPassword({
          userId: selectedUserForPassword._id,
          newPassword: newPassword,
        })
      );

      showNotification("Success", "success", "Password updated successfully.");

      fetchUsers();
      setNewPassword("");
      setPasswordPopupVisible(false);
    } catch (error) {
      console.error("Failed to update password:", error);
      showNotification("Error", "danger", "Failed to update password.");
    }
  };

  const handlePasswordChangeClick = (user) => {
    setSelectedUserForPassword(user);
    setPasswordPopupVisible(true);
  };

  const handleHidingEditPopup = () => {
    // setSelectedUser(null);
    // setPermissions([]);
    // setDropPermission(false);
    // setTraders([]);
    setEditPopupVisible(false);
  };

  const renderEditPopup = useCallback(
    () => (
      <div
        className="popup-edit-user"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <TextField
          label="Name"
          name="name"
          value={selectedUser?.name || ""}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          sx={{
            "& .MuiInputBase-input": { fontSize: "1.5rem", color: "#E0E6ED" },
            "& .MuiInputLabel-root": { fontSize: "1.5rem", color: "#E0E6ED" },
          }}
        />
        <TextField
          label="Email"
          name="email"
          value={selectedUser?.email || ""}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          sx={{
            "& .MuiInputBase-input": { fontSize: "1.5rem", color: "#E0E6ED" },
            "& .MuiInputLabel-root": { fontSize: "1.5rem", color: "#E0E6ED" },
          }}
        />
        <TextField
          label="Last Name"
          name="lastName"
          value={selectedUser?.lastName || ""}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          sx={{
            "& .MuiInputBase-input": { fontSize: "1.5rem", color: "#E0E6ED" },
            "& .MuiInputLabel-root": { fontSize: "1.5rem", color: "#E0E6ED" },
          }}
        />
        <label>Traders</label>
        <TagBox
          items={[{ _id: "all", traderName: "All Traders" }, ...traderList]}
          displayExpr="traderName"
          valueExpr="_id"
          value={selectedUser?.traders || []} // Ensure value is an array of IDs
          onValueChanged={handleTradersChange} // Updated handler
          showSelectionControls={true}
          placeholder="Select Traders"
          width="100%"
          style={{ marginTop: "2px", marginBottom: "1rem" }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={selectedUser?.dropDepositPermission || false}
              onChange={handleDropDepositPermissionChange}
              sx={{ color: "#E0E6ED" }}
            />
          }
          label="Drop Deposit Permission"
          sx={{
            "& .MuiTypography-root": {
              fontSize: "1.5rem",
              color: "#E0E6ED",
            },
          }}
        />
        <TagBox
          items={["Deposit", "Withdrawal"]}
          value={selectedUser?.permissions || []}
          onValueChanged={handlePermissionChange}
          showSelectionControls={true}
          placeholder="Select Permissions"
          width="100%"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          sx={{
            marginTop: "1.6rem",
            fontSize: "1.5rem",
            backgroundColor: "#556CD6",
          }}
        >
          Save
        </Button>
      </div>
    ),
    [selectedUser, traderList]
  );

  const renderPasswordPopup = useCallback(
    () => (
      <div className="popup-change-password">
        <h3>
          Change Password for {selectedUserForPassword?.name || "Undefined"}
        </h3>
        <TextField
          label="New Password"
          name="newPassword"
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          fullWidth
          margin="normal"
          sx={{
            "& .MuiInputBase-input": { fontSize: "1.5rem", color: "#E0E6ED" },
            "& .MuiInputLabel-root": { fontSize: "1.5rem", color: "#E0E6ED" },
          }}
        />
        <Button
          variant="contained"
          color="secondary"
          onClick={handlePasswordChangeSave}
          sx={{
            marginTop: "1.6rem",
            fontSize: "1.5rem",
            backgroundColor: "#FF4081",
          }}
        >
          Save Password
        </Button>
      </div>
    ),
    [newPassword, selectedUserForPassword]
  );

  return (
    <div
      className="control-panel-user-list"
      style={{
        backgroundColor: "#1C273A",
        minHeight: "100vh",

      }}
    >
      <TableContainer
        component={Paper}
        sx={{
          width: "100%",
          margin: "1rem auto",
          backgroundColor: "#2E3B55", // Darker background for the table
          color: "#E0E6ED", // Light font color
        }}
      >
        <Table
          sx={{
            "& .MuiTableCell-root": {
              fontSize: "1.2rem", // Increase the font size
              color: "#E0E6ED", // Light font color for table cells
              borderBottom: "1px solid #3A4663", // Adjust the border color to match the theme
            },
            "& .MuiTableHead-root": {
              backgroundColor: "#3A4663", // Slightly darker background for the header
            },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Google Authenticator Code</TableCell>
              <TableCell>Permissions</TableCell>
              <TableCell>Traders</TableCell>
              <TableCell>Drop Permission</TableCell>
              <TableCell>Actions</TableCell>
              <TableCell>Change Password</TableCell>
              <TableCell>Block User</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user._id}>
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>
                  <Tooltip title="Click to copy">
                    <Button
                      onClick={() =>
                        handleCopyCode(user.googleAuthenticatorCode)
                      }
                      startIcon={<MdOutlineCopyAll />}
                      sx={{
                        color: "#E0E6ED", // Light font color for the button
                        textTransform: "none",
                      }}
                    >
                      {user.googleAuthenticatorCode.slice(0, 10)}...
                    </Button>
                  </Tooltip>
                </TableCell>
                <TableCell>{user.permissions.join(", ")}</TableCell>
                <TableCell>
                  {user.traders.map((trader) => trader.traderName).join(", ")}
                </TableCell>
                <TableCell>
                  <Checkbox checked={user.dropDepositPermission} disabled />
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleUserSelect(user)}
                  >
                    Edit
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handlePasswordChangeClick(user)}
                  >
                    Change Password
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    // color={user.blocked ? "error" : "default"}
                    onClick={() => handleBlockUnblock(user)}
                    sx={{
                      width: "7.7rem",
                      backgroundColor: user.blocked ? "#FF5733" : "#28a745",
                      color: "#fff",
                      "&:hover": {
                        backgroundColor: user.blocked ? "#FF2E00" : "#218838",
                      },
                    }}
                  >
                    {user.blocked ? "Unblock" : "Block"}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Popup
        width={600}
        height={520}
        showTitle={true}
        dragEnabled={false}
        hideOnOutsideClick={true}
        visible={editPopupVisible}
        onHiding={handleHidingEditPopup}
        contentRender={renderEditPopup}
        showCloseButton={true}
        container=".dx-viewport"
        title={`User Editing for, ${selectedUser?.name || ""}`}
        animation={{
          show: { type: "slide", duration: 400, from: { top: -50 } },
        }}
        position={{ my: "center", at: "center", of: window }}
      />

      <Popup
        width={600}
        height={270}
        showTitle={true}
        dragEnabled={false}
        hideOnOutsideClick={true}
        visible={passwordPopupVisible}
        onHiding={() => {
          setPasswordPopupVisible(false);
          setNewPassword("");
        }}
        contentRender={renderPasswordPopup}
        showCloseButton={true}
        container=".dx-viewport"
        title="Change Password"
        animation={{
          show: { type: "slide", duration: 400, from: { top: -50 } },
        }}
        position={{ my: "center", at: "center", of: window }}
      />
    </div>
  );
};

export default UserManagementPanel;
