import React, { useState, useEffect } from "react";
import "./OptionView.css";

import { IoMenu } from "react-icons/io5";
import { SiSocketdotio } from "react-icons/si";
import { BsSunFill } from "react-icons/bs";
import { FaUserCircle } from "react-icons/fa";
import { LiaFlagUsaSolid } from "react-icons/lia";

import { styled } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";

import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";

import { MdArrowBackIosNew } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { FaSave } from "react-icons/fa";

import { FaUser } from "react-icons/fa";
import { RiLockPasswordFill } from "react-icons/ri";

import TextBox from "devextreme-react/text-box";
import { Button } from "devextreme-react/button";
import TagBox from "devextreme-react/tag-box";
import axios from "axios";

import { useDispatch, useSelector } from "react-redux";
import {
  updateMe,
  getMe,
  updatePassword,
  updateAuthenticatorCode,
} from "../store";

import { showNotification } from "../components/NotificationProvider";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const OptionView = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [dealer, setDealer] = useState("Main");
  const [userGroup, setUserGroup] = useState("Finance Client");
  const [optionTab, setOptionTab] = useState("Informations");

  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [authCode, setAuthCode] = useState("");
  const [email, setEmail] = useState("");
  const [userTraders, setUserTraders] = useState([]);

  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [currenPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [selectedItems, setSelectedItems] = useState([]);

  const [passwordData, setPasswordData] = useState({
    currentPassword: "",
    newPassword: "",
    newPasswordConfirm: "",
  });

  const handlePasswordChange = (e) => {
    const { name, value } = e;
    setPasswordData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (user && user.user) {
      setName(user.user.name);
      setLastName(user.user.lastName);
      setAuthCode(user.user.googleAuthenticatorCode);
      setEmail(user.user.email);
      setUserTraders(user.user.traders);
    }
  }, [user]);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  const handleAuthCopy = () => {
    navigator.clipboard
      .writeText(authCode)
      .then(() => {
        console.log("Text copied to clipboard");
        showNotification("Success", "success", "Authenticator code copied");
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
        showNotification("Error", "danger", "Authenticator code copy failed");
      });
  };

  const getUserInformation = async () => {
    dispatch(getMe())
      .unwrap()
      .then((res) => {})
      .catch((err) => console.log(err));
  };

  const handleUpdateInformation = () => {
    const data = {
      name,
      lastName,
    };

    dispatch(updateMe(data))
      .unwrap()
      .then((res) => {
        showNotification(
          "Success",
          "success",
          "Information updated successfully"
        );
        getUserInformation();
      })
      .catch((err) => console.log(err));
  };

  const handlePasswordUpdate = () => {
    dispatch(updatePassword(passwordData))
      .unwrap()
      .then((res) => {
        setPasswordData({
          currentPassword: "",
          newPassword: "",
          newPasswordConfirm: "",
        });
        setShowPasswordForm(false);
        showNotification("Success", "success", "Password updated successfully");
      })
      .catch((err) => {
        console.log(err);
        showNotification("Error", "danger", err);
      });
  };

  const handleUpdateAuthenticatorCode = () => {
    dispatch(updateAuthenticatorCode())
      .unwrap()
      .then((res) => {
        setAuthCode(res.data.googleAuthenticatorCode);
        console.log("Authenticator code updated successfully");
        showNotification(
          "Success",
          "success",
          "Authenticator code updated successfully"
        );
      })
      .catch((err) => {
        console.log(err);
        showNotification("Error", "danger", "Authenticator code update failed");
      });
  };

  return (
    <div className="options">
      <div className="options-buttons">
        <div className="options-buttons-left"></div>
        <div className="options-buttons-right">
          <Button
            icon="save"
            type="success"
            text="Save Changes"
            onClick={handleUpdateInformation}
          />
        </div>
      </div>
      <div className="options-body">
        <div className="options-profile">
          <div className="profile-icon-container">
            <FaUser className="profile-icon" />
          </div>
          <div className="profile-name">{``}</div>
          <div className="profile-input-group">
            <label>Google Authorization Code</label>
            <div className="input-with-button">
              <TextBox
                className="custom-textbox"
                value={authCode}
                readOnly={true}
                placeholder="Google Authorization Code"
                width={196}
                onFocusIn={() => handleAuthCopy()}
              />
              <Button icon="refresh" onClick={handleUpdateAuthenticatorCode} />
            </div>
          </div>

          <div className="profile-input-group">
            <label>Email Address</label>
            <TextBox
              className="custom-textbox"
              value={email}
              readOnly={true}
              placeholder="Email Address"
              width={230}
            />
          </div>
          <Button
            icon="lock"
            type={showPasswordForm ? "danger" : "default"}
            text={showPasswordForm ? "Close Password Form" : "Change Password"}
            onClick={() => setShowPasswordForm(!showPasswordForm)}
          />
          {showPasswordForm && (
            <div className="password-change-form">
              <TextBox
                className="custom-textbox"
                value={passwordData.currentPassword}
                onValueChanged={(e) =>
                  handlePasswordChange({
                    name: "currentPassword",
                    value: e.value,
                  })
                }
                placeholder="Current Password"
                inputAttr={{ type: "password" }}
              />
              <TextBox
                className="custom-textbox new-password-input"
                value={passwordData.newPassword}
                onValueChanged={(e) =>
                  handlePasswordChange({ name: "newPassword", value: e.value })
                }
                placeholder="New Password"
                inputAttr={{ type: "password" }}
              />
              <TextBox
                className="custom-textbox"
                value={passwordData.newPasswordConfirm}
                onValueChanged={(e) =>
                  handlePasswordChange({
                    name: "newPasswordConfirm",
                    value: e.value,
                  })
                }
                placeholder="Confirm Password"
                inputAttr={{ type: "password" }}
              />
              <Button
                style={{ marginTop: "2rem" }}
                icon="save"
                type="success"
                text="Save Password"
                onClick={handlePasswordUpdate}
              />
            </div>
          )}
        </div>
        <div className="options-configurations">
          <div className="tabs-container">
            <div
              className={
                optionTab === "Informations" ? "tab tab-active" : "tab"
              }
              onClick={() => setOptionTab("Informations")}
            >
              <span className="tab-text">Informations</span>
            </div>
            {/* <div
              className={optionTab === "Sounds" ? "tab tab-active" : "tab"}
              onClick={() => setOptionTab("Sounds")}
            >
              <span className="tab-text">Sounds</span>
            </div> */}
          </div>
          {optionTab === "Informations" ? (
            <div className="tab-content">
              <div className="name-surname-container">
                <div className="profile-input-group-col">
                  {" "}
                  <label>Name</label>
                  <TextBox
                    className="custom-textbox"
                    value={name}
                    onValueChanged={(e) => setName(e.value)}
                    placeholder="Name"
                  />
                </div>
                <div className="profile-input-group-col">
                  <label>Last Name</label>
                  <TextBox
                    className="custom-textbox"
                    value={lastName}
                    onValueChanged={(e) => setLastName(e.value)}
                    placeholder="Last Name"
                  />
                </div>
              </div>
              <div className="profile-input-group">
                <label>Traders</label>
                <TagBox
                  items={userTraders?.map((trader) => ({
                    value: trader._id,
                    label: trader.traderName,
                  }))}
                  displayExpr="label"
                  valueExpr="value"
                  value={userTraders?.map((trader) => trader._id)}
                  showSelectionControls={true}
                  placeholder="Select Trader"
                  multiline={true}
                  width="100%"
                  disabled={true}
                />
              </div>
            </div>
          ) : (
            <div className="tab-content"></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OptionView;
