import React, { useState, useEffect, useCallback } from "react";
import "./InCardReport.css";

import { IoMdRefresh } from "react-icons/io";
import { FaClock } from "react-icons/fa6";
import { FcApproval } from "react-icons/fc";
import { MdCancel } from "react-icons/md";

import InputLabel from "@mui/material/InputLabel";

import "rsuite/dist/rsuite.min.css";
import { DateRangePicker } from "rsuite";
import Divider from "@mui/material/Divider";

import Popup from "devextreme-react/popup";
import { Button } from "devextreme-react/button";

import DataGrid, {
  Export,
  Selection,
  GroupPanel,
  Paging,
  Pager,
  Scrolling,
  ColumnFixing,
  SearchPanel,
  Column,
  HeaderFilter,
  FilterRow,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";

import SelectBox from "devextreme-react/select-box";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { IoIosSearch } from "react-icons/io";
import { IoCheckmarkDoneSharp } from "react-icons/io5";

import { useDispatch } from "react-redux";
import { getTraders, getWithdrawalHistory } from "../store";

const CustomSearchField = ({ value, onChange, placeholder }) => {
  return (
    <OutlinedInput
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      sx={{
        backgroundColor: "#1B2434",
        borderRadius: "4px",
        color: "#dedede",
        height: "4rem",
        fontSize: "1.6rem",

        width: "25rem",

        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#29364a",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: "#225978",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "#19a9dd",
          borderWidth: "1px",
        },
        "& .MuiInputBase-input": {
          color: "#dedede",
          fontSize: "1.6rem",
        },
        "& .MuiInputBase-input::placeholder": {
          color: "#afafaf",
          fontSize: "1.6rem",
        },
      }}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="clear input"
            onClick={() => onChange({ target: { value: "" } })}
            edge="end"
          >
            <IoIosSearch color="#afafaf" />
          </IconButton>
        </InputAdornment>
      }
    />
  );
};

const InCardReport = () => {
  const dispatch = useDispatch();

  const [selectedReportStatus, setSelectedReportStatus] = useState("All");
  const [selectedReportTrader, setSelectedReportTrader] = useState("All");
  const [selectedDateRange, setSelectedDateRange] = useState(
    "01/01/2018 - 01/15/2018"
  );
  const [reportDetailPopupVisible, setReportDetailPopupVisible] =
    useState(false);

  const handleReportDetailPopupHidden = useCallback(() => {
    setReportDetailPopupVisible(false);
  }, [setReportDetailPopupVisible]);

  const handleReportDetailClick = () => {
    setReportDetailPopupVisible(true);
  };

  const handleChangeReportTrader = (event) => {
    setSelectedReportTrader(event.target.value);
  };
  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift({
      location: "before",
      widget: "dxButton",
      options: {
        text: "Total 5 Piece",

        onClick: () => {
          navigator.clipboard.writeText("Total 16 Piece").then(
            () => {},
            () => {}
          );
        },
        elementAttr: {
          class: "info-button",
        },
        width: 150,
        stylingMode: "outlined",
      },
    });
  };

  const [traders, setTraders] = useState([]);
  const [selectedTrader, setSelectedTrader] = useState("all");
  const [dateRange, setDateRange] = useState([
    new Date(new Date().setHours(0, 0, 0, 0)),
    new Date(new Date().setHours(23, 59, 59, 999)),
  ]);
  const [search, setSearch] = useState("");

  const [approvedCount, setApprovedCount] = useState(0);
  const [approvedAmount, setApprovedAmount] = useState(0);
  const [declinedCount, setDeclinedCount] = useState(0);
  const [declinedAmount, setDeclinedAmount] = useState(0);

  const [dataGridSource, setDataGridSource] = useState([]);
  const [withdrawalHistory, setWithdrawalHistory] = useState([]);
  const [selectedWithdrawal, setSelectedWithdrawal] = useState(null);
  const dataGridSourceMemo = React.useMemo(
    () => dataGridSource,
    [dataGridSource]
  );

  const getHistory = async () => {
    dispatch(getWithdrawalHistory(dateRange))
      .unwrap()
      .then((res) => {
        console.log(res);
        setWithdrawalHistory(res.data.data.withdrawalHistory);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getHistory();
  }, [dateRange]);

  useEffect(() => {
    dispatch(getTraders())
      .unwrap()
      .then((res) => {
        console.log(res);
        const allTradersOption = { traderId: "all", traderName: "All Traders" };
        setTraders([allTradersOption, ...res.data.data.traders]);
      })
      .catch((err) => console.log(err));
  }, []);

  const applyGridSource = useCallback(() => {
    if (withdrawalHistory.length > 0) {
      let formattedData = withdrawalHistory.map((withdrawal) => ({
        // UPDATE HERE
        // FIXME:
        id: withdrawal._id.substr(withdrawal._id.length - 8),
        transaction_id: withdrawal.transactionId,
        trader: withdrawal.traderName,
        host_user_id: withdrawal.userId,
        bank: withdrawal.bank,
        receipent: withdrawal.recipientName,
        receipent_account: withdrawal.recipientAccount,
        expiry_date: withdrawal.expirationDate,
        amount: withdrawal.amount,
        status: withdrawal.status,
        request_date: withdrawal.createdAt,
        complete_date: withdrawal.completedAt,
        action_time: withdrawal.actionTime,
        reservedPersonName: withdrawal.reservedPersonName,
        reported: withdrawal.reported,
      }));

      if (search.trim() !== "") {
        formattedData = formattedData.filter(
          (item) =>
            item.reservedPersonName &&
            item.reservedPersonName
              .toLowerCase()
              .includes(search.trim().toLowerCase())
        );
      }

      if (selectedReportStatus !== "All") {
        formattedData = formattedData.filter(
          (item) =>
            item.status.toLowerCase() === selectedReportStatus.toLowerCase()
        );
      }

      if (selectedTrader !== "all") {
        formattedData = formattedData.filter(
          (item) =>
            item.trader ===
            traders.find((trader) => trader.traderId === selectedTrader)
              ?.traderName
        );
      }

      const approvedData = formattedData.filter(
        (item) => item.status.toLowerCase() === "approved"
      );
      const declinedData = formattedData.filter(
        (item) => item.status.toLowerCase() === "declined"
      );

      setApprovedCount(approvedData.length);
      setApprovedAmount(
        approvedData.reduce((sum, item) => sum + item.amount, 0)
      );

      setDeclinedCount(declinedData.length);
      setDeclinedAmount(
        declinedData.reduce((sum, item) => sum + item.amount, 0)
      );

      setDataGridSource(formattedData);
      // handleCalculateTotalByFilters(formattedData);
    } else {
      setDataGridSource([]);
    }
  }, [
    withdrawalHistory,
    selectedReportStatus,
    selectedTrader,
    traders,
    dateRange,
    search,
  ]);

  useEffect(() => {
    applyGridSource();
  }, [
    withdrawalHistory,
    selectedReportStatus,
    selectedTrader,
    applyGridSource,
  ]);

  const handleRefreshClick = () => {
    getHistory();
  };

  const handleTraderChange = (e) => {
    setSelectedTrader(e.value);

    // applyGridSource();
  };

  const handleChangeReportStatus = (value) => {
    setSelectedReportStatus(value);

    // applyGridSource();
  };

  const handleDateChange = (range) => {
    setDateRange(range);
  };

  const handleRowDblClick = (e) => {
    console.log(e.data);
    setSelectedWithdrawal(e.data);
    handleReportDetailClick();
  };

  const formatActionTime = (actionTime) => {
    const totalSeconds = Math.floor(actionTime);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${hours} hr ${minutes} min ${seconds} sec`;
  };

  const renderReportDetailPopup = useCallback(
    () => (
      <div className="report-detail-popup">
        <div class="table_component" role="region" tabindex="0">
          <table>
            <thead>
              <tr></tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <p>Status</p>
                </td>
                <td>
                  <span className="badge badge-green">
                    {selectedWithdrawal?.status}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <p>ID</p>
                </td>
                <td>{selectedWithdrawal?.id}</td>
              </tr>
              <tr>
                <td>
                  <p>Transaction ID</p>
                </td>
                <td>
                  <span className="badge badge-red">
                    {selectedWithdrawal?.transaction_id}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Trader</p>
                </td>
                <td>
                  <span className="badge badge-orange">
                    {selectedWithdrawal?.trader}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Website User ID</p>
                </td>
                <td>{selectedWithdrawal?.host_user_id}</td>
              </tr>
              <tr>
                <td>
                  <p>Website Username</p>
                </td>
                <td>{selectedWithdrawal?.host_user_id}</td>
              </tr>

              <tr>
                <td>
                  <p>Recipient</p>
                </td>
                <td>
                  {" "}
                  <span className="badge badge-red">
                    {selectedWithdrawal?.receipent}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Recipient Account</p>
                </td>
                <td>
                  <span className="badge badge-red">
                    {selectedWithdrawal?.receipent_account}
                  </span>
                </td>
              </tr>

              <tr>
                <td>
                  <p>Amount</p>
                </td>
                <td>
                  <span
                    className=" badge badge-green"
                    style={{ fontSize: "2rem" }}
                  >
                    {selectedWithdrawal
                      ? new Intl.NumberFormat("tr-TR", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(selectedWithdrawal.amount)
                      : ""}{" "}
                    <span>₼</span>
                  </span>
                </td>
              </tr>

              <tr>
                <td>
                  <p>Process&nbsp;Starting</p>
                </td>
                <td>
                  {new Date(selectedWithdrawal?.request_date).toLocaleString()}
                </td>
              </tr>

              <tr>
                <td>
                  <p>Results Date</p>
                </td>
                <td>
                  {new Date(selectedWithdrawal?.complete_date).toLocaleString()}
                </td>
              </tr>
              <tr>
                <td>
                  <p>Action Time</p>
                </td>
                <td>
                  <span className="badge badge-orange">
                    {formatActionTime(selectedWithdrawal?.action_time)}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Employee</p>
                </td>
                <td>
                  <span className="badge badge-orange">
                    {selectedWithdrawal?.reservedPersonName}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Report?</p>
                </td>
                <td>
                  <span className="badge badge-green">
                    {selectedWithdrawal?.reported
                      ? "Reported!"
                      : "Not Reported"}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="popup-footer">
          <div className="popup-footer-left report-detail-footer">
            <Button
              width={120}
              text="Cancel"
              type="danger"
              stylingMode="outlined"
              onClick={() => setReportDetailPopupVisible(false)}
            />
          </div>
          <div className="popup-footer-right"></div>
        </div>
      </div>
    ),
    [selectedWithdrawal]
  );

  return (
    <div className="InCardReport">
      <div className="deposit-report-container">
        <div className="deposit-header-container">
          <div className="deposit-header">
            <FaClock className="deposit-header-icon" />
            <span>InCard Withdrawals Report</span>
          </div>
          <div className="deposit-header-refresh" onClick={handleRefreshClick}>
            <IoMdRefresh className="refresh-icon" />
            <span>Refresh</span>
          </div>
          <Popup
            baseZIndex={1000}
            width={530}
            height={777}
            showTitle={true}
            dragEnabled={false}
            hideOnOutsideClick={true}
            visible={reportDetailPopupVisible}
            onHiding={handleReportDetailPopupHidden}
            contentRender={renderReportDetailPopup}
            showCloseButton={true}
            container=".dx-viewport"
            title="E-Manat Transaction Details"
            animation={{
              show: { type: "fade", duration: 400, from: { top: 0 } },
            }}
            position={{ my: "center", at: "center", of: window }}
          />
        </div>
        <div className="deposit-report-filters">
          <div className="deposit-report-filter-top">
            <div className="information-body deposit-report-info-left">
              <div className="information-accepted-container info-approve">
                <div className="info-tag info-tag--accepted">Approved</div>
                <div className="info-body info-report-body">
                  <FcApproval className="info-icon" />
                  <div className="info-count-container">
                    <div className="info-header">COUNT</div>
                    <div className="info-text">{approvedCount}</div>
                  </div>
                  <div className="info-amount-container">
                    <div className="info-header">AMOUNT</div>
                    <div className="info-text">
                      {new Intl.NumberFormat("tr-TR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(approvedAmount)}{" "}
                      ₼
                    </div>
                  </div>
                </div>
              </div>

              <div className="information-rejected-container info-decline">
                <div className="info-tag info-tag--rejected">Declined</div>
                <div className="info-body info-report-body">
                  <MdCancel className="info-icon info-icon--reject" />
                  <div className="info-count-container">
                    <div className="info-header">COUNT</div>
                    <div className="info-text">{declinedCount}</div>
                  </div>
                  <div className="info-amount-container">
                    <div className="info-header">AMOUNT</div>
                    <div className="info-text">
                      {new Intl.NumberFormat("tr-TR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(declinedAmount)}{" "}
                      ₼
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="deposit-report-info-right">
              <div className="deposit-input-group deposit-report-input-group">
                <InputLabel
                  id="demo-simple-select-label"
                  style={{
                    color: "white",
                    fontSize: "1.4rem",
                    marginBottom: ".7rem",
                  }}
                >
                  Status
                </InputLabel>
                <SelectBox
                  dataSource={[
                    { id: 1, name: "All" },
                    { id: 2, name: "Approved" },
                    { id: 3, name: "Declined" },
                  ]}
                  displayExpr="name"
                  valueExpr="name"
                  value={selectedReportStatus} // Ensure this reflects the current state
                  onValueChanged={(e) => handleChangeReportStatus(e.value)}
                  style={{ backgroundColor: "#1B2434" }}
                />
              </div>
              <div className="deposit-input-group deposit-report-input-group">
                <InputLabel
                  id="demo-simple-select-label"
                  style={{
                    color: "white",
                    fontSize: "1.4rem",
                    marginBottom: ".7rem",
                  }}
                >
                  Trader
                </InputLabel>
                <SelectBox
                  items={traders}
                  displayExpr="traderName"
                  valueExpr="traderId"
                  defaultValue="all"
                  onValueChanged={handleTraderChange}
                  showClearButton={false}
                  style={{ backgroundColor: "#1B2434" }}
                />
              </div>
              <div className="deposit-input-group deposit-report-input-group">
                <InputLabel
                  id="demo-simple-select-label"
                  style={{
                    color: "white",
                    fontSize: "1.4rem",
                    marginBottom: ".7rem",
                  }}
                >
                  Date
                </InputLabel>

                <DateRangePicker
                  size="md"
                  placeholder="Select Date Range"
                  block
                  format="MM/dd/yyyy HH:mm"
                  placement="bottomEnd"
                  preventOverflow
                  value={dateRange}
                  onChange={handleDateChange}
                  defaultValue={[
                    new Date(new Date().setHours(0, 0, 0, 0)),
                    new Date(new Date().setHours(23, 59, 59, 999)),
                  ]}
                />
              </div>
            </div>
          </div>
          <Divider
            orientation="horizontal"
            variant="middle"
            flexItem
            sx={{ borderColor: "#ffffff14", margin: "10px 0 0px 0" }}
          />
        </div>
        <div className="deposit-table ag-theme-quartz report-table">
          <div className="grid-search-container">
            <CustomSearchField
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <DataGrid
            dataSource={dataGridSourceMemo}
            showBorders={false}
            showRowLines={false}
            hoverStateEnabled={true}
            rowAlternationEnabled={false}
            allowColumnResizing={true}
            columnAutoWidth={true}
            columnMinWidth={50}
            columnResizingMode="nextColumn"
            height="100%"
            onRowDblClick={handleRowDblClick}
            // onToolbarPreparing={onToolbarPreparing}
          >
            <Selection mode="single" />
            <ColumnFixing enabled />
            <GroupPanel visible={false} />
            {/* <SearchPanel visible={true} width={240} placeholder="Search" /> */}
            <Paging defaultPageSize={25} />
            <Pager
              visible
              allowedPageSizes={[25, 50, 100]}
              showPageSizeSelector
              showInfo
              showNavigationButtons
            />
            <Scrolling
              useNative={false}
              scrollByContent
              scrollByThumb
              showScrollbar="onHover"
            />
            <Export
              enabled={false}
              fileName="easypropay-pending-deposit"
              allowExportSelectedData={false}
            />
            <Column
              caption="ID"
              dataField="id"
              alignment="center"
              fixed
              fixedPosition="left"
              cellRender={(data) => (
                <div className="badge badge-blue">{data.value}</div>
              )}
            />
            <Column
              caption="Transaction ID"
              dataField="transaction_id"
              alignment="center"
              fixed
              fixedPosition="left"
              cellRender={(data) => (
                <div className="badge badge-pink">{data.value}</div>
              )}
            />
            <Column
              caption="Trader"
              dataField="trader"
              alignment="center"
              cellRender={(data) => (
                <div className="badge badge-blue">{data.value}</div>
              )}
            />
            <Column
              caption="Host User ID"
              dataField="host_user_id"
              alignment="center"
              cellRender={(data) => (
                <div className="badge badge-orange">{data.value}</div>
              )}
            />
            <Column
              caption="Bank Name"
              dataField="bank"
              alignment="center"
              cellRender={(data) => (
                <div className="badge badge-blue">{data.value}</div>
              )}
            />
            <Column
              caption="Receipent"
              dataField="receipent"
              alignment="center"
              cellRender={(data) => (
                <div className="badge badge-pink">{data.value}</div>
              )}
            />
            <Column
              caption="Receipent Account"
              dataField="receipent_account"
              alignment="center"
              cellRender={(data) => (
                <div className="badge badge-red">{data.value}</div>
              )}
            />
            <Column
              caption="Expiry Date"
              dataField="expiry_date"
              alignment="center"
              cellRender={(data) => (
                <div className="badge badge-red">{data.value}</div>
              )}
            />
            <Column
              caption="Amount"
              dataField="amount"
              alignment="center"
              dataType="number"
              cellRender={(data) => {
                // Format the amount using Intl.NumberFormat
                const formattedAmount = new Intl.NumberFormat("tr-TR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(data.value);

                // TODO:
                return (
                  <div className="grid-cell-amount">
                    <div className="grid-amount">
                      <span
                        className={`badge badge-green`}
                      >{`${formattedAmount} ₼`}</span>
                    </div>
                  </div>
                );
              }}
            />
            <Column
              caption="Status"
              dataField="status"
              alignment="center"
              cellRender={(data) => (
                <div
                  className={`badge ${
                    data.value === "declined" ? "badge-red" : "badge-green"
                  }`}
                >
                  {data.value === "declined" ? "Declined" : "Approved"}
                </div>
              )}
            />
            <Column
              caption="Request Date"
              dataField="request_date"
              alignment="center"
              dataType="datetime"
              customizeText={(cellInfo) => {
                const options = {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                };
                return new Date(cellInfo.value)
                  .toLocaleDateString("tr-TR", options)
                  .replace(",", "");
              }}
            />
            <Column
              caption="Result Date"
              dataField="complete_date"
              alignment="center"
              dataType="datetime"
              sortIndex={0}
              sortOrder="asc"
              customizeText={(cellInfo) => {
                const options = {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                };
                return new Date(cellInfo.value)
                  .toLocaleDateString("tr-TR", options)
                  .replace(",", "");
              }}
            />
            <Column
              caption="Action Time"
              dataField="action_time"
              alignment="center"
              cellRender={(data) => {
                const totalSeconds = Math.floor(data.value);
                const hours = Math.floor(totalSeconds / 3600);
                const minutes = Math.floor((totalSeconds % 3600) / 60);
                const seconds = totalSeconds % 60;
                return (
                  <div className="badge badge-orange">{`${hours} hr ${minutes} min ${seconds} sec`}</div>
                );
              }}
            />
            <Column
              caption="Reserved Person"
              dataField="reservedPersonName"
              alignment="center"
              cellRender={(data) => <div className="">{data.value}</div>}
            />
            <Column
              caption="Reported"
              dataField="reported"
              alignment="center"
              cellRender={(data) => {
                return (
                  <div className="badge">
                    {data.value ? (
                      <IoCheckmarkDoneSharp style={{ color: "green" }} />
                    ) : (
                      "--"
                    )}
                  </div>
                );
              }}
            />
          </DataGrid>
        </div>
      </div>
    </div>
  );
};

export default InCardReport;
