import React, { useEffect, useState } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";

import PanelScreen from "./screens/PanelScreen";
import LoginScreen from "./screens/LoginScreen";

import { licenseKey } from "./assets/devextreme-license";
import config from "devextreme/core/config";
import axios from "axios";

import { useDispatch } from "react-redux";
import { getMe, logout, getTraders } from "./store";

config({ licenseKey });

const App = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState("login");
  const [tempUsername, setTempUserName] = useState("");
  const [role, setRole] = useState("person");

  useEffect(() => {
    const checkSession = async () => {
      try {
        const response = await dispatch(getMe()).unwrap();
        if (response.status === 200) {
          setPage("panel"); // Redirect to the dashboard or appropriate page
          console.log(response);
          setRole(response.data.data.user.role);
          dispatch(getTraders())
            .unwrap()
            .then((res) => {
              console.log(res);
            });
        }
      } catch (err) {
        setPage("login");
      }
    };

    checkSession();
  }, [dispatch]);

  const handleExit = async () => {
    try {
      const response = await dispatch(logout()).unwrap();
      if (response.status === 200) {
        setPage("login"); // Redirect to the dashboard or appropriate page
      }
    } catch (err) {
      setPage("login");
    }
  };

  const handleUsername = (username) => {
    setTempUserName(username);
  };

  console.log("tempUsername", tempUsername);

  const handleLogin = () => {
    setPage("panel");
  };

  // const handleExit = async () => {
  //   try {
  //     const response = await axios.post(
  //       "http://localhost:3001/user/logout",
  //       {},
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         withCredentials: true,
  //       }
  //     );
  //     if (response.status === 200) {
  //       setPage("login");
  //     }
  //   } catch (error) {
  //     console.error("Network error:", error);
  //   }
  // };

  return (
    <div className="app">
      {page === "login" && (
        <LoginScreen
          onLogin={handleLogin}
          onExit={handleExit}
          onGetUsername={handleUsername}
        />
      )}
      {page === "panel" && (
        <PanelScreen
          onExit={handleExit}
          tempUsername={tempUsername}
          role={role}
        />
      )}
    </div>
  );
};

export default App;
