import React, { useState, useEffect, useCallback, useRef } from "react";
import "./DepositReportView.css";

import { IoMdRefresh } from "react-icons/io";
import { FaClock } from "react-icons/fa6";
import { FcApproval } from "react-icons/fc";
import { MdCancel } from "react-icons/md";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import { GiCancel } from "react-icons/gi";

import InputLabel from "@mui/material/InputLabel";

import "rsuite/dist/rsuite.min.css";
import { DateRangePicker } from "rsuite";
import Divider from "@mui/material/Divider";

import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid

import DataGrid, {
  Export,
  Selection,
  GroupPanel,
  Paging,
  Pager,
  Scrolling,
  ColumnFixing,
  SearchPanel,
  Column,
  HeaderFilter,
  FilterRow,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import { Button } from "devextreme-react/button";
import SelectBox from "devextreme-react/select-box";
import Popup from "devextreme-react/popup";

import { useDispatch } from "react-redux";
import { getDepositHistory, getTraders } from "../store";

import { showNotification } from "../components/NotificationProvider";

import TextField from "@mui/material/TextField";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { IoIosSearch } from "react-icons/io";

const CustomSearchField = ({ value, onChange, placeholder }) => {
  return (
    <OutlinedInput
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      sx={{
        backgroundColor: "#1B2434",
        borderRadius: "4px",
        color: "#dedede",
        height: "4rem",
        fontSize: "1.6rem",

        width: "25rem",

        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#29364a",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: "#225978",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "#19a9dd",
          borderWidth: "1px",
        },
        "& .MuiInputBase-input": {
          color: "#dedede",
          fontSize: "1.6rem",
        },
        "& .MuiInputBase-input::placeholder": {
          color: "#afafaf",
          fontSize: "1.6rem",
        },
      }}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="clear input"
            onClick={() => onChange({ target: { value: "" } })}
            edge="end"
          >
            <IoIosSearch color="#afafaf" />
          </IconButton>
        </InputAdornment>
      }
    />
  );
};

const DepositReportView = () => {
  const dispatch = useDispatch();
  const [tableHeight, setTableHeight] = useState(window.innerHeight);
  const [containerHeight, setContainerHeight] = useState(window.innerHeight);
  const [selectedReportStatus, setSelectedReportStatus] = useState("All");
  const [selectedReportTrader, setSelectedReportTrader] = useState("All");

  const [selectedRowData, setSelectedRowData] = useState({});
  const [selectedDeposit, setSelectedDeposit] = useState(null);

  const [reportDetailPopupVisible, setReportDetailPopupVisible] =
    useState(false);

  const formatActionTime = (actionTime) => {
    const totalSeconds = Math.floor(actionTime);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes} min ${seconds} sec`;
  };

  const renderReportDetailPopup = useCallback(
    () => (
      <div className="report-detail-popup">
        <div className="table_component" role="region" tabIndex="0">
          <table>
            <thead>
              <tr></tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <p>Status</p>
                </td>
                <td>
                  <span
                    className={`badge badge-${
                      selectedDeposit.status === "approved" ? "green" : "red"
                    }`}
                  >
                    {selectedDeposit.status}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <p>ID</p>
                </td>
                <td>{selectedDeposit.id}</td>
              </tr>
              <tr>
                <td>
                  <p>Trader</p>
                </td>
                <td>
                  <span className="badge badge-orange">
                    {selectedDeposit.trader}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Website Username</p>
                </td>
                <td>{selectedDeposit.host_user_name}</td>
              </tr>
              <tr>
                <td>
                  <p>Website User ID</p>
                </td>
                <td>{selectedDeposit.host_user_id}</td>
              </tr>
              <tr>
                <td>
                  <p>Sender</p>
                </td>
                <td>
                  <span className="badge badge-purple">
                    {selectedDeposit.sender_full_name}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Sender Account</p>
                </td>
                <td>
                  <span className="badge badge-purple">
                    {selectedDeposit.sender_account}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Recipient</p>
                </td>
                <td>
                  <span className="badge badge-red">
                    {selectedDeposit.recipient}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Recipient Account</p>
                </td>
                <td>
                  <span className="badge badge-red">
                    {selectedDeposit.recipient_account}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Request Amount</p>
                </td>
                <td>
                  <span className="badge badge-orange">
                    {selectedDeposit.old_amount?.toFixed(2)}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Approved Tutar</p>
                </td>
                <td>
                  <span className="badge badge-green">
                    {selectedDeposit.amount?.toFixed(2)}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Code</p>
                </td>
                <td>{selectedDeposit.code || ""}</td>
              </tr>
              <tr>
                <td>
                  <p>IP Address</p>
                </td>
                <td>{selectedDeposit.ipAddress}</td>
              </tr>
              <tr>
                <td>
                  <p>Transaction ID</p>
                </td>
                <td>
                  <span className="badge badge-red">
                    {selectedDeposit.transaction_id}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Process Starting</p>
                </td>
                <td>
                  {new Date(selectedDeposit.create_date).toLocaleString()}
                </td>
              </tr>
              <tr>
                <td>
                  <p>Member Deposit Confirmation</p>
                </td>
                <td>
                  {new Date(selectedDeposit.create_date).toLocaleString()}
                </td>
              </tr>
              <tr>
                <td>
                  <p>Results Date</p>
                </td>
                <td>
                  {new Date(selectedDeposit.complete_date).toLocaleString()}
                </td>
              </tr>
              <tr>
                <td>
                  <p>Action Time</p>
                </td>
                <td>
                  <span className="badge badge-orange">
                    {formatActionTime(selectedDeposit.action_time)}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Employee</p>
                </td>
                <td>
                  <span className="badge badge-orange">
                    {selectedDeposit.reservedPersonName}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Notification</p>
                </td>
                <td>
                  <span className="badge badge-green">
                    {selectedDeposit.reported ? "Reported!" : "Not Reported"}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="popup-footer">
          <div className="popup-footer-left report-detail-footer">
            <Button
              width={120}
              text="Cancel"
              type="danger"
              stylingMode="outlined"
              onClick={() => setReportDetailPopupVisible(false)}
            />
          </div>
          <div className="popup-footer-right"></div>
        </div>
      </div>
    ),
    [selectedDeposit]
  );

  const handleReportDetailPopupHidden = useCallback(() => {
    setReportDetailPopupVisible(false);
  }, [setReportDetailPopupVisible]);

  const handleReportDetailClick = () => {
    setReportDetailPopupVisible(true);
  };

  // useEffect(() => {
  //   const handleResize = () => {
  //     const remInPx = parseFloat(
  //       getComputedStyle(document.documentElement).fontSize
  //     );
  //     const newHeight = window.innerHeight - 7 * remInPx;
  //     setContainerHeight(newHeight);

  //     const newTableHeight = window.innerHeight - 25 * remInPx;
  //     setTableHeight(newTableHeight);
  //   };

  //   handleResize();

  //   window.addEventListener("resize", handleResize);

  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);

  // useEffect(() => {
  //   document.documentElement.style.setProperty("--lolo", `${tableHeight}px`);
  // }, [tableHeight]);

  const handleChangeReportStatus = (value) => {
    setSelectedReportStatus(value);

    applyGridSource();
  };

  const handleChangeReportTrader = (event) => {
    setSelectedReportTrader(event.target.value);
  };

  const formatCardNumber = (number) => {
    return number.replace(/\D/g, "").replace(/(\d{4})(?=\d)/g, "$1 ");
  };

  const [traders, setTraders] = useState([]);
  const [selectedTrader, setSelectedTrader] = useState("all");
  const [dateRange, setDateRange] = useState([
    new Date(new Date().setHours(0, 0, 0, 0)),
    new Date(new Date().setHours(23, 59, 59, 999)),
  ]);
  const [search, setSearch] = useState("");

  const [approvedCount, setApprovedCount] = useState(0);
  const [approvedAmount, setApprovedAmount] = useState(0);
  const [declinedCount, setDeclinedCount] = useState(0);
  const [declinedAmount, setDeclinedAmount] = useState(0);

  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    getHistory();
  }, [dateRange]);

  useEffect(() => {
    dispatch(getTraders())
      .unwrap()
      .then((res) => {
        console.log(res);
        const allTradersOption = { traderId: "all", traderName: "All Traders" };
        setTraders([allTradersOption, ...res.data.data.traders]);
      })
      .catch((err) => console.log(err));
  }, []);

  const getHistory = async () => {
    dispatch(getDepositHistory(dateRange))
      .unwrap()
      .then((res) => {
        console.log(res);
        setDepositHistory(res.data.data.depositHistory);
      })
      .catch((err) => console.log(err));
  };

  const handleRefreshClick = () => {
    getHistory();
  };

  const handleTraderChange = (e) => {
    setSelectedTrader(e.value);
    // Burada seçilen trader'ı işlemek için ek kodlar yazabilirsiniz
    applyGridSource();
  };

  // const handleCalculateTotalByFilters = (showingDeposits) => {
  //   const approvedTransactions = showingDeposits.filter(
  //     (item) => item.status.toLowerCase() === "approved"
  //   );
  //   const declinedTransactions = showingDeposits.filter(
  //     (item) => item.status.toLowerCase() === "declined"
  //   );

  //   setApprovedCount(approvedTransactions.length);
  //   setApprovedAmount(
  //     approvedTransactions.reduce((total, item) => total + item.amount, 0)
  //   );

  //   setDeclinedCount(declinedTransactions.length);
  //   setDeclinedAmount(
  //     declinedTransactions.reduce((total, item) => total + item.amount, 0)
  //   );
  // };

  const [debouncedSearch, setDebouncedValue] = useState("");

  const [dataGridSource, setDataGridSource] = useState([]);
  const [depositHistory, setDepositHistory] = useState([]);
  const dataGridSourceMemo = React.useMemo(
    () => dataGridSource,
    [dataGridSource]
  );

  const handleDateChange = (range) => {
    setDateRange(range);
  };

  const handleRowDblClick = (e) => {
    console.log(e.data);
    setSelectedDeposit(e.data);
    handleReportDetailClick();
  };

  const applyGridSource = useCallback(() => {
    if (depositHistory.length > 0) {
      let formattedData = depositHistory.map((deposit) => ({
        id: deposit.id.substr(deposit.id.length - 8),
        transaction_id: deposit.transactionId,
        trader: deposit.traderName,
        host_user_id: deposit.userId,
        host_user_name: deposit.userId,
        sender_full_name: deposit.cardInfo?.name || "N/A",
        sender_account: deposit.cardInfo?.number || "N/A",
        recipient: deposit.recipientName,
        recipient_account: deposit.recipientAccount,
        is_amount_changed: deposit.isAmountChanged,
        old_amount: deposit.oldAmount,
        amount: deposit.amount,
        status: deposit.status,
        explanation: deposit.explanation,
        reservedPerson: deposit.reservedPerson,
        reservedPersonName: deposit.reservedPersonName,
        create_date: deposit.createdAt,
        complete_date: deposit.completedAt,
        action_time: deposit.actionTime,
        reported: deposit.reported,
      }));

      if (search.trim() !== "") {
        formattedData = formattedData.filter(
          (item) =>
            item.reservedPersonName &&
            item.reservedPersonName
              .toLowerCase()
              .includes(search.trim().toLowerCase())
        );
      }

      if (selectedReportStatus !== "All") {
        formattedData = formattedData.filter(
          (item) =>
            item.status.toLowerCase() === selectedReportStatus.toLowerCase()
        );
      }

      if (selectedTrader !== "all") {
        formattedData = formattedData.filter(
          (item) =>
            item.trader ===
            traders.find((trader) => trader.traderId === selectedTrader)
              ?.traderName
        );
      }

      const approvedData = formattedData.filter(
        (item) => item.status.toLowerCase() === "approved"
      );
      const declinedData = formattedData.filter(
        (item) => item.status.toLowerCase() === "declined"
      );

      setApprovedCount(approvedData.length);
      setApprovedAmount(
        approvedData.reduce((sum, item) => sum + item.amount, 0)
      );

      setDeclinedCount(declinedData.length);
      setDeclinedAmount(
        declinedData.reduce((sum, item) => sum + item.amount, 0)
      );

      setDataGridSource(formattedData);
      // handleCalculateTotalByFilters(formattedData);
    } else {
      setDataGridSource([]);
    }
  }, [
    depositHistory,
    selectedReportStatus,
    selectedTrader,
    traders,
    dateRange,
    search,
  ]);

  useEffect(() => {
    applyGridSource();
  }, [depositHistory, selectedReportStatus, selectedTrader, applyGridSource]);

  // useEffect(() => {
  //   const debounceTimer = setTimeout(() => {
  //     setDebouncedValue(search);
  //   }, 300);

  //   return () => {
  //     clearTimeout(debounceTimer);
  //   };
  // }, [search]);

  return (
    <div className="deposit-report">
      <div
        className="deposit-report-container"
        style={{ height: containerHeight }}
      >
        <div className="deposit-header-container">
          <div className="deposit-header">
            <FaClock className="deposit-header-icon" />
            <span>Deposit Report</span>
          </div>
          <div className="deposit-header-refresh" onClick={handleRefreshClick}>
            <IoMdRefresh className="refresh-icon" />
            <span>Refresh</span>
          </div>
        </div>
        <div className="deposit-report-filters">
          <div className="deposit-report-filter-top">
            <div className="information-body deposit-report-info-left">
              <div className="information-accepted-container info-approve">
                <div className="info-tag info-tag--accepted">Approved</div>
                <div className="info-body info-report-body">
                  <FcApproval className="info-icon" />
                  <div className="info-count-container">
                    <div className="info-header">COUNT</div>
                    <div className="info-text">{approvedCount}</div>
                  </div>
                  <div className="info-amount-container">
                    <div className="info-header">AMOUNT</div>
                    <div className="info-text">
                      {new Intl.NumberFormat("tr-TR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(approvedAmount)}{" "}
                      ₼
                    </div>
                  </div>
                </div>
              </div>

              <div className="information-rejected-container info-decline">
                <div className="info-tag info-tag--rejected">Declined</div>
                <div className="info-body info-report-body">
                  <MdCancel className="info-icon info-icon--reject" />
                  <div className="info-count-container">
                    <div className="info-header">COUNT</div>
                    <div className="info-text">{declinedCount}</div>
                  </div>
                  <div className="info-amount-container">
                    <div className="info-header">AMOUNT</div>
                    <div className="info-text">
                      {new Intl.NumberFormat("tr-TR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(declinedAmount)}{" "}
                      ₼
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="deposit-report-info-right">
              <div className="deposit-input-group deposit-report-input-group">
                <InputLabel
                  id="demo-simple-select-label"
                  style={{
                    color: "white",
                    fontSize: "1.4rem",
                    marginBottom: ".7rem",
                  }}
                >
                  Status
                </InputLabel>
                <SelectBox
                  dataSource={[
                    { id: 1, name: "All" },
                    { id: 2, name: "Approved" },
                    { id: 3, name: "Declined" },
                  ]}
                  displayExpr="name"
                  valueExpr="name"
                  value={selectedReportStatus} // Ensure this reflects the current state
                  onValueChanged={(e) => handleChangeReportStatus(e.value)}
                  style={{ backgroundColor: "#1B2434" }}
                />
              </div>
              <div className="deposit-input-group deposit-report-input-group">
                <InputLabel
                  id="demo-simple-select-label"
                  style={{
                    color: "white",
                    fontSize: "1.4rem",
                    marginBottom: ".7rem",
                  }}
                >
                  Trader
                </InputLabel>
                {/* FIXME: */}
                <SelectBox
                  items={traders}
                  displayExpr="traderName"
                  valueExpr="traderId"
                  defaultValue="all"
                  onValueChanged={handleTraderChange}
                  showClearButton={false}
                  style={{ backgroundColor: "#1B2434" }}
                />
              </div>
              <div className="deposit-input-group deposit-report-input-group">
                <InputLabel
                  id="demo-simple-select-label"
                  style={{
                    color: "white",
                    fontSize: "1.4rem",
                    marginBottom: ".7rem",
                  }}
                >
                  Date
                </InputLabel>
                {/* TODO: */}
                <DateRangePicker
                  size="md"
                  placeholder="Select Date Range"
                  block
                  format="MM/dd/yyyy HH:mm"
                  placement="bottomEnd"
                  preventOverflow
                  value={dateRange}
                  onChange={handleDateChange}
                  defaultValue={[
                    new Date(new Date().setHours(0, 0, 0, 0)),
                    new Date(new Date().setHours(23, 59, 59, 999)),
                  ]}
                />
              </div>
            </div>
          </div>
          <Divider
            orientation="horizontal"
            variant="middle"
            flexItem
            sx={{ borderColor: "#ffffff14", margin: "10px 0 0 0" }}
          />
        </div>
        <div className="deposit-table ag-theme-quartz report-table">
          <div className="grid-search-container">
            <CustomSearchField
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <DataGrid
            dataSource={dataGridSourceMemo}
            showBorders={false}
            showRowLines={false}
            hoverStateEnabled={true}
            rowAlternationEnabled={false}
            allowColumnResizing={true}
            columnAutoWidth={true}
            columnMinWidth={50}
            columnResizingMode="nextColumn"
            height="100%"
            onRowDblClick={handleRowDblClick}
            // onToolbarPreparing={onToolbarPreparing}
          >
            <Selection mode="single" />
            <ColumnFixing enabled />
            <GroupPanel visible={false} />
            {/* <SearchPanel visible={true} width={240} placeholder="Search" /> */}
            <Paging defaultPageSize={25} />
            <Pager
              visible
              allowedPageSizes={[25, 50, 100]}
              showPageSizeSelector
              showInfo
              showNavigationButtons
            />
            <Scrolling
              useNative={false}
              scrollByContent
              scrollByThumb
              showScrollbar="onHover"
            />
            <Export
              enabled={false}
              fileName="easypropay-pending-deposit"
              allowExportSelectedData={false}
            />

            <Column
              caption="ID"
              dataField="id"
              alignment="center"
              fixed
              fixedPosition="left"
              cellRender={(data) => (
                <div className="badge badge-blue">{data.value}</div>
              )}
            />
            <Column
              caption="Transaction ID"
              dataField="transaction_id"
              alignment="center"
              fixed
              fixedPosition="left"
              cellRender={(data) => (
                <div className="badge badge-pink">{data.value}</div>
              )}
            />
            <Column
              caption="Trader"
              dataField="trader"
              alignment="center"
              cellRender={(data) => (
                <div className="badge badge-blue">{data.value}</div>
              )}
            />
            <Column
              caption="Host User Name"
              dataField="host_user_name"
              alignment="center"
              cellRender={(data) => (
                <div className="badge badge-pink">{data.value}</div>
              )}
            />
            <Column
              caption="Sender"
              dataField="sender_full_name"
              alignment="center"
              cellRender={(data) => (
                <div className="badge badge-pink">{data.value}</div>
              )}
            />
            <Column
              caption="Sender Account"
              dataField="sender_account"
              alignment="center"
              cellRender={(data) => (
                <div className="badge badge-pink">
                  {formatCardNumber(data.value)}
                </div>
              )}
            />
            <Column
              caption="Recipient"
              dataField="recipient"
              alignment="center"
              cellRender={(data) => (
                <div className="badge badge-red">{data.value}</div>
              )}
            />
            <Column
              caption="Recipient Account"
              dataField="recipient_account"
              alignment="center"
              cellRender={(data) => (
                <div className="badge badge-red">{data.value}</div>
              )}
            />
            <Column
              caption="Amount"
              dataField="amount"
              alignment="center"
              dataType="number"
              cellRender={(data) => {
                // Format the amount using Intl.NumberFormat
                const formattedAmount = new Intl.NumberFormat("tr-TR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(data.value);

                return (
                  <div className="badge badge-green">{`${formattedAmount} ₼`}</div>
                );
              }}
            />
            <Column
              caption="Status"
              dataField="status"
              alignment="center"
              cellRender={(data) => (
                <div
                  className={`badge ${
                    data.value === "declined" ? "badge-red" : "badge-green"
                  }`}
                >
                  {data.value === "declined" ? "Declined" : "Approved"}
                </div>
              )}
            />
            <Column
              caption="Explanation"
              dataField="explanation"
              alignment="center"
              cellRender={(data) => <div className="">{data.value}</div>}
            />
            <Column
              caption="Reserved Person"
              dataField="reservedPersonName"
              alignment="center"
              cellRender={(data) => <div className="">{data.value}</div>}
            />
            <Column
              caption="Request Date"
              dataField="create_date"
              alignment="center"
              dataType="datetime"
              sortIndex={0}
              sortOrder="asc"
              customizeText={(cellInfo) => {
                const options = {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                };
                return new Date(cellInfo.value)
                  .toLocaleDateString("tr-TR", options)
                  .replace(",", "");
              }}
            />
            <Column
              caption="Result Date"
              dataField="complete_date"
              alignment="center"
              dataType="datetime"
              sortIndex={0}
              sortOrder="asc"
              customizeText={(cellInfo) => {
                const options = {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                };
                return new Date(cellInfo.value)
                  .toLocaleDateString("tr-TR", options)
                  .replace(",", "");
              }}
            />
            <Column
              caption="Action Time"
              dataField="action_time"
              alignment="center"
              cellRender={(data) => {
                const totalSeconds = Math.floor(data.value);
                const minutes = Math.floor(totalSeconds / 60);
                const seconds = totalSeconds % 60;
                return (
                  <div className="badge badge-orange">{`${minutes} min ${seconds} sec`}</div>
                );
              }}
            />
            <Column
              caption="Reported"
              dataField="reported"
              alignment="center"
              cellRender={(data) => {
                return (
                  <div className="badge">
                    {data.value ? (
                      <IoCheckmarkDoneSharp style={{ color: "green" }} />
                    ) : (
                      "--"
                    )}
                  </div>
                );
              }}
            />
          </DataGrid>
        </div>
      </div>

      <Popup
        baseZIndex={1000}
        width={530}
        height={800}
        showTitle={true}
        dragEnabled={false}
        hideOnOutsideClick={true}
        visible={reportDetailPopupVisible}
        onHiding={handleReportDetailPopupHidden}
        contentRender={renderReportDetailPopup}
        showCloseButton={true}
        container=".dx-viewport"
        title="E-Manat Transaction Details"
        animation={{
          show: { type: "fade", duration: 400, from: { top: 0 } },
        }}
        position={{ my: "center", at: "center", of: window }}
      />
    </div>
  );
};

export default DepositReportView;
