import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "./axios";

const getDepositQueue = createAsyncThunk(
  "deposits/getDepositQueue",
  async (_, { rejectWithValue }) => {
    try {
      const headers = { "Content-Type": "application/json" };

      const response = await axios.get("/deposits/getDepositQueue", {
        headers,
      });

      return response;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data.message);
    }
  }
);

const getDepositHistory = createAsyncThunk(
  "deposits/getDepositHistory",
  async (dateRange, { rejectWithValue }) => {
    try {
      const headers = { "Content-Type": "application/json" };

      const response = await axios.post(
        "/deposits/getDepositHistory",
        { dateRange },
        { headers }
      );

      return response;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data.message);
    }
  }
);

export { getDepositQueue, getDepositHistory };
