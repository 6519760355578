import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import "./InCardView.css";

import { IoMdRefresh } from "react-icons/io";
import { FaClock } from "react-icons/fa6";

import TomSelect from "tom-select";
import "tom-select/dist/css/tom-select.css";

import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid

import DataGrid, {
  Export,
  Selection,
  GroupPanel,
  Paging,
  Pager,
  Scrolling,
  ColumnFixing,
  SearchPanel,
  Column,
  HeaderFilter,
  FilterRow,
} from "devextreme-react/data-grid";

import Popup from "devextreme-react/popup";
import Divider from "@mui/material/Divider";
import { RiArrowRightDoubleFill } from "react-icons/ri";
import { MdCancel } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";

import { NumericFormat } from "react-number-format";
import { Button } from "devextreme-react/button";
import { showNotification } from "../components/NotificationProvider";

import { useDispatch, useSelector } from "react-redux";
import {
  getWithdrawalQueue,
  approveWithdrawal,
  declineWithdrawal,
  getTraders,
} from "../store";

import { BsPencilSquare } from "react-icons/bs";

const ManatNumberFormat = React.forwardRef(function ManatNumberFormat(
  props,
  ref
) {
  const { onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      isNumericString
      prefix="₼ "
    />
  );
});

const dataSource = [];

const InCardView = () => {
  const { user } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const selectRef = useRef(null);
  const [detailPopupVisible, setDetailPopupVisible] = useState(false);
  const [confirmationPopupVisible, setConfirmationPopupVisible] =
    useState(false);
  const [declinePopupVisible, setDeclinePopupVisible] = useState(false);

  const [containerHeight, setContainerHeight] = useState(window.innerHeight);
  const [tableHeight, setTableHeight] = useState(window.innerHeight);

  useEffect(() => {
    if (selectRef.current) {
      // If TomSelect is already initialized, destroy it first
      if (selectRef.current.tomselect) {
        selectRef.current.tomselect.destroy();
      }

      // Initialize TomSelect
      new TomSelect(selectRef.current, {
        maxItems: 3,
        create: true,
        sortField: { field: "text" },
      });
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const remInPx = parseFloat(
        getComputedStyle(document.documentElement).fontSize
      );
      const newHeight = window.innerHeight - 7 * remInPx;
      setContainerHeight(newHeight);

      const newTableHeight = window.innerHeight - 16.5 * remInPx;
      setTableHeight(newTableHeight);
    };

    // Set initial height
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    document.documentElement.style.setProperty("--lolo", `${tableHeight}px`);
  }, [tableHeight]);

  const handleConfirmationPopupHidden = useCallback(() => {
    setConfirmationPopupVisible(false);
  }, [setConfirmationPopupVisible]);

  const handleDeclinePopupHidden = useCallback(() => {
    setDeclinePopupVisible(false);
  }, [setDeclinePopupVisible]);

  const handleDetailPopupHidden = useCallback(() => {
    setDetailPopupVisible(false);
  }, [setDetailPopupVisible]);

  const [dataGridSource, setDataGridSource] = useState([]);
  const [traders, setTraders] = useState([]);
  const [personId, setPersonId] = useState("");
  const [traderNames, setTraderNames] = useState([]);
  const [selectedTrader, setSelectedTrader] = useState("All Traders");
  const [groups, setGroups] = useState(["All groups"]);
  const [selectedGroup, setSelectedGroup] = useState("All groups");
  const [myPermissions, setMyPermissions] = useState("");

  useEffect(() => {
    if (user && user.user) {
      setPersonId(user.user._id);
      setMyPermissions(user.user.permissions);
    }
  }, [user]);

  useEffect(() => {
    dispatch(getTraders())
      .unwrap()
      .then((res) => {
        console.log(res);
        const allTradersOption = { traderId: "all", traderName: "All Traders" };
        setTraders([allTradersOption, ...res.data.data.traders]);
      })
      .catch((err) => console.log(err));
  }, []);

  const getWithdrawals = async () => {
    dispatch(getWithdrawalQueue())
      .unwrap()
      .then((res) => {
        const fetchedWithdrawals = res.data.data.withdrawalQueue;
        setWithdrawals(fetchedWithdrawals);

        const uniqueGroups = [
          "All groups",
          ...Array.from(new Set(fetchedWithdrawals.map((w) => w.group)))
            .sort((a, b) => a - b)
            .map((group) => `Group ${group}`),
        ];
        setGroups(uniqueGroups);
        console.log("okk");
      })
      .catch((error) => {
        console.error("Failed to fetch deposits", error);
      });
  };

  useEffect(() => {
    getWithdrawals();
  }, [dispatch]);

  const handleRefreshClick = () => {
    getWithdrawals();
  };

  const handleDetailClick = () => {
    setDetailPopupVisible(true);
  };

  useEffect(() => {
    const ws = new WebSocket("wss://panel.easypropay.com/ws/panelWithdrawal");
    // const ws = new WebSocket("ws://localhost:3001/ws/panelWithdrawal");

    ws.onopen = () => {
      console.log("Withdrawal WebSocket connected successfuly!");
    };

    ws.onmessage = (message) => {
      const data = JSON.parse(message.data);
      console.log("Received WebSocket message:", data);

      switch (data.type) {
        case "created":
          console.log("New withdrawal created", data);

          setWithdrawals((prevWithdrawals) => {
            const exists = prevWithdrawals.some(
              (withdrawal) => withdrawal.id === data.withdrawal._id
            );
            if (!exists) {
              return [...prevWithdrawals, data.withdrawal];
            }
            return prevWithdrawals;
          });

          break;

        case "canceled":
          console.log("Cancel Triggered, received data:", data);
          setWithdrawals((prevWithdrawals) => {
            return prevWithdrawals.filter(
              (withdrawal) => withdrawal._id !== data.withdrawalID
            );
          });
          break;

        case "rejectedWithdrawal":
          console.log("WithdrawalID: ", data.withdrawalID);
          setWithdrawals((prevWithdrawals) => {
            return prevWithdrawals.filter(
              (withdrawal) => withdrawal._id !== data.withdrawalID
            );
          });
          break;

        case "approvedWithdrawal":
          console.log("WithdrawalID: ", data.withdrawalID);
          setWithdrawals((prevWithdrawals) => {
            return prevWithdrawals.filter(
              (withdrawal) => withdrawal._id !== data.withdrawalID
            );
          });
          break;

        default:
          console.log("Unknow message type", data.type);
          showNotification("Error", "danger", "Unknown message type");
      }
    };

    ws.onerror = (error) => {
      console.error("WebSocket error", error);
    };

    ws.onclose = () => {
      console.log("Withdrawal WebSocket connection closed.");
    };

    return () => {
      ws.close();
    };
  }, []);

  //FIXME:
  const [socket, setSocket] = useState(null);
  const [withdrawals, setWithdrawals] = useState([]);
  const dataGridSourceMemo = useMemo(() => dataGridSource, [dataGridSource]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [selectedWithdrawal, setSelectedWithdrawal] = useState(null);

  const applyGridSource = useCallback(
    (traderFilter, groupFilter) => {
      if (withdrawals.length > 0) {
        let filteredWithdrawals = withdrawals;

        if (traderFilter && traderFilter !== "All Traders") {
          filteredWithdrawals = withdrawals.filter(
            (withdrawal) => withdrawal.traderName === traderFilter
          );
        }

        if (groupFilter && groupFilter !== "All groups") {
          const groupNumber = parseInt(groupFilter.replace("Group ", ""), 10);
          filteredWithdrawals = filteredWithdrawals.filter(
            (withdrawal) => withdrawal.group === groupNumber
          );
        }

        let formattedData = filteredWithdrawals.map((withdrawal, index) => ({
          id: withdrawal._id,
          transaction_id: withdrawal.transactionId,
          trader: withdrawal.traderName,
          host_user_id: withdrawal.userId,
          bank: withdrawal.bank,
          receipent: withdrawal.recipientName,
          receipent_account: withdrawal.recipientAccount,
          expiry_date: withdrawal.expirationDate,
          amount: withdrawal.amount,
          request_date: withdrawal.createdAt,
          approve: "Approve",
          reject: "Reject",
        }));

        setDataGridSource(formattedData);
      } else {
        setDataGridSource([]);
      }
    },
    [withdrawals]
  );

  useEffect(() => {
    applyGridSource(selectedTrader, selectedGroup);
  }, [selectedTrader, selectedGroup, applyGridSource, withdrawals]);

  const calculateTotalAmount = () => {
    return dataGridSource.reduce(
      (total, deposit) => total + (deposit.amount || 0),
      0
    );
  };

  useEffect(() => {
    setTotalAmount(calculateTotalAmount());
  }, [dataGridSource]);

  const handleTraderChange = (e) => {
    setSelectedTrader(e.value);
  };

  const handleApprove = (withdrawalId) => {
    if (!myPermissions.includes("Withdrawal")) {
      showNotification(
        "Error",
        "danger",
        "You do not have permission to reserve deposits."
      );
      return; // Exit the function if the user does not have "Deposit" permission
    }
    //TODO:
    dispatch(approveWithdrawal(withdrawalId))
      .unwrap()
      .then((res) => {
        console.log(res);
        showNotification(
          "Success",
          "success",
          "Withdrawal approved successfully"
        );
        setConfirmationPopupVisible(false);
      })
      .catch((err) => showNotification("Error", "danger", err));
  };

  const handleDecline = (withdrawalId) => {
    if (!myPermissions.includes("Withdrawal")) {
      showNotification(
        "Error",
        "danger",
        "You do not have permission to reserve deposits."
      );
      return; // Exit the function if the user does not have "Deposit" permission
    }
    console.log(withdrawalId);
    dispatch(declineWithdrawal(withdrawalId))
      .unwrap()
      .then((res) => {
        console.log(res);
        showNotification(
          "Success",
          "default",
          "Withdrawal declined successfully"
        );
        setDeclinePopupVisible(false);
      })
      .catch((err) => showNotification("Error", "danger", err));
  };

  const handleRowDblClick = (e) => {
    console.log(e.data);
    setSelectedWithdrawal(e.data);
    setDetailPopupVisible(true);
  };

  const renderDetailPopup = useCallback(
    () => (
      <div className="report-detail-popup">
        <div class="table_component" role="region" tabindex="0">
          <table>
            <thead>
              <tr></tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <p>ID</p>
                </td>
                <td>{selectedWithdrawal?.id}</td>
              </tr>
              <tr>
                <td>
                  <p>Transaction ID</p>
                </td>
                <td>
                  <span className="badge badge-red">
                    {selectedWithdrawal?.transaction_id}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Trader</p>
                </td>
                <td>
                  <span className="badge badge-orange">
                    {selectedWithdrawal?.trader}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Website User ID</p>
                </td>
                <td>{selectedWithdrawal?.host_user_id}</td>
              </tr>
              <tr>
                <td>
                  <p>Website Username</p>
                </td>
                <td>{selectedWithdrawal?.host_user_id}</td>
              </tr>

              <tr>
                <td>
                  <p>Recipient</p>
                </td>
                <td>
                  {" "}
                  <span className="badge badge-red">
                    {selectedWithdrawal?.receipent}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Recipient Account</p>
                </td>
                <td>
                  <span className="badge badge-red">
                    {selectedWithdrawal?.receipent_account}
                  </span>
                </td>
              </tr>

              <tr>
                <td>
                  <p>Amount</p>
                </td>
                <td>
                  <span
                    className=" badge badge-green"
                    style={{ fontSize: "2rem" }}
                  >
                    {selectedWithdrawal
                      ? new Intl.NumberFormat("tr-TR", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(selectedWithdrawal.amount)
                      : ""}{" "}
                    <span>₼</span>
                  </span>
                </td>
              </tr>

              <tr>
                <td>
                  <p>Process&nbsp;Starting</p>
                </td>
                <td>
                  {new Date(selectedWithdrawal?.request_date).toLocaleString()}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="popup-footer">
          <div className="popup-footer-left report-detail-footer">
            <Button
              width={120}
              text="Cancel"
              type="danger"
              stylingMode="outlined"
              onClick={() => setDetailPopupVisible(false)}
            />
          </div>
          <div className="popup-footer-right"></div>
        </div>
      </div>
    ),
    [selectedWithdrawal]
  );

  const renderConfirmationPopup = useCallback(
    () => (
      <div className="confirmation-popup">
        <div className="confirmation-box">
          <div className="icon-info icon-green">
            <FaCheckCircle />
          </div>
          <h3>Transaction Will Be Confirmed!</h3>
          <p style={{ marginBottom: "2rem" }}>
            Are you sure you want to continue?
          </p>
          <Divider orientation="horizontal" variant="middle" flexItem />
          <div className="transaction-info">
            <div className="info">
              <span className="label">Receipent</span>
              <span className="label">Receipent Account</span>
            </div>
            <div className="info">
              <span className="value badge badge-pink">
                {selectedWithdrawal?.receipent}
              </span>
              <span className="value badge badge-pink">
                {selectedWithdrawal?.receipent_account}
              </span>
            </div>
            <div className="amount">
              <span className=" badge badge-green" style={{ fontSize: "2rem" }}>
                {selectedWithdrawal
                  ? new Intl.NumberFormat("tr-TR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(selectedWithdrawal.amount)
                  : ""}{" "}
                <span>₼</span>
              </span>
            </div>
          </div>
          <Divider orientation="horizontal" variant="middle" flexItem />
          <div className="buttons">
            <Button
              width={120}
              text="Cancel"
              type="danger"
              stylingMode="contained"
              onClick={() => setConfirmationPopupVisible(false)}
            />
            <Button
              width={120}
              text="Approve!"
              type="success"
              stylingMode="contained"
              onClick={() => handleApprove(selectedWithdrawal?.id)}
            />
          </div>
        </div>
      </div>
    ),
    [confirmationPopupVisible]
  );

  const renderDeclinePopup = useCallback(
    () => (
      <div className="confirmation-popup">
        <div className="confirmation-box">
          <div className="icon-info icon-red">
            <MdCancel />
          </div>
          <h3>Transaction Will Be Rejected!</h3>
          <p style={{ marginBottom: "2rem" }}>
            Are you sure you want to continue?
          </p>
          <Divider orientation="horizontal" variant="middle" flexItem />
          <div className="transaction-info">
            <div className="info">
              <span className="label">Recepient</span>
              <span className="label">Receipent Account</span>
            </div>
            <div className="info">
              <span className="value badge badge-pink">
                {selectedWithdrawal?.receipent}
              </span>
              <span className="value badge badge-pink">
                {selectedWithdrawal?.receipent_account}
              </span>
            </div>
            <div className="amount">
              <span className=" badge badge-red" style={{ fontSize: "2rem" }}>
                {selectedWithdrawal
                  ? new Intl.NumberFormat("tr-TR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(selectedWithdrawal.amount)
                  : ""}{" "}
                <span>₼</span>
              </span>
            </div>
          </div>
          <Divider orientation="horizontal" variant="middle" flexItem />
          <div className="buttons">
            <Button
              width={120}
              text="Cancel"
              type="default"
              stylingMode="outlined"
              onClick={() => setDeclinePopupVisible(false)}
            />
            <Button
              width={120}
              text="Reject!"
              type="danger"
              stylingMode="contained"
              onClick={() => handleDecline(selectedWithdrawal?.id)}
            />
          </div>
        </div>
      </div>
    ),
    [declinePopupVisible]
  );

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: "before",
        widget: "dxButton",
        options: {
          text: `Total ${dataGridSource.length} Piece`,

          onClick: () => {
            navigator.clipboard
              .writeText(`Total ${dataGridSource.length} Piece`)
              .then(
                () => {
                  showNotification(
                    "Success",
                    "success",
                    "Total deposit count copied to clipboard"
                  );
                },
                () => {}
              );
          },
          elementAttr: {
            class: "info-button",
          },
          width: 150,
          stylingMode: "outlined",
        },
      },
      {
        location: "before",
        widget: "dxButton",
        options: {
          text: `${new Intl.NumberFormat("tr-TR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(totalAmount)} ₼`,
          size: "large",
          onClick: () => {
            const formattedAmount = new Intl.NumberFormat("tr-TR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(totalAmount);
            navigator.clipboard.writeText(`Total: ${formattedAmount} ₼`).then(
              () => {
                showNotification(
                  "Success",
                  "success",
                  "Total amount copied to clipboard"
                );
              },
              () => {}
            );
          },
          elementAttr: {
            class: "info-button info-button--amount",
          },
          width: 120,
          stylingMode: "outlined",
        },
      },
      //TODO:
      {
        location: "before",
        widget: "dxSelectBox",
        options: {
          items: traders,
          displayExpr: "traderName",
          valueExpr: "traderName",
          value: selectedTrader,
          showClearButton: false,
          width: 200,
          elementAttr: {
            class: "transaction-type-select",
          },
          onValueChanged: handleTraderChange,
        },
      },
      {
        location: "before",
        widget: "dxSelectBox",
        options: {
          items: groups,
          value: selectedGroup,
          showClearButton: false,
          width: 200,
          elementAttr: {
            class: "transaction-type-select",
          },
          onValueChanged: function (e) {
            setSelectedGroup(e.value);
          },
        },
      }
    );
  };

  return (
    <div className="InCard">
      <div className="deposit-body">
        <div className="deposit-header-container incard-header-container">
          <div className="deposit-header">
            <FaClock className="deposit-header-icon" />
            <span>Pending Withdrawals</span>
          </div>
          <div className="deposit-header-refresh" onClick={handleRefreshClick}>
            <IoMdRefresh className="refresh-icon" />
            <span>Refresh</span>
          </div>
        </div>

        <div className="deposit-table ag-theme-quartz">
          <DataGrid
            dataSource={dataGridSourceMemo}
            showBorders={false}
            showRowLines={false}
            hoverStateEnabled={true}
            rowAlternationEnabled={false}
            allowColumnResizing={true}
            columnAutoWidth={true}
            columnMinWidth={50}
            columnResizingMode="nextColumn"
            height="100%"
            onToolbarPreparing={onToolbarPreparing}
            onRowDblClick={handleRowDblClick}
          >
            <Selection mode="single" />
            <ColumnFixing enabled />
            <GroupPanel visible={false} />
            <SearchPanel visible={true} width={240} placeholder="Search" />
            <Paging defaultPageSize={25} />
            <Pager
              visible
              allowedPageSizes={[25, 50, 100]}
              showPageSizeSelector
              showInfo
              showNavigationButtons
            />
            <Scrolling
              useNative={false}
              scrollByContent
              scrollByThumb
              showScrollbar="onHover"
            />
            <Export
              enabled={false}
              fileName="easypropay-pending-withdrawals"
              allowExportSelectedData={false}
            />
            <Column
              caption="ID"
              dataField="id"
              alignment="center"
              cellRender={(data) => (
                <div className="badge badge-blue">{data.value}</div>
              )}
            />

            <Column
              caption="Transaction ID"
              dataField="transaction_id"
              alignment="center"
              cellRender={(data) => (
                <div className="badge badge-pink">{data.value}</div>
              )}
            />
            <Column
              caption="Trader"
              dataField="trader"
              alignment="center"
              cellRender={(data) => (
                <div className="badge badge-blue">{data.value}</div>
              )}
            />

            <Column
              caption="Host User ID"
              dataField="host_user_id"
              alignment="center"
              cellRender={(data) => (
                <div className="badge badge-orange">{data.value}</div>
              )}
            />
            <Column
              caption="Bank Name"
              dataField="bank"
              alignment="center"
              cellRender={(data) => (
                <div className="badge badge-blue">{data.value}</div>
              )}
            />
            <Column
              caption="Receipent"
              dataField="receipent"
              alignment="center"
              cellRender={(data) => (
                <div className="badge badge-pink">{data.value}</div>
              )}
            />
            <Column
              caption="Receipent Account"
              dataField="receipent_account"
              alignment="center"
              cellRender={(data) => (
                <div className="badge badge-red">{data.value}</div>
              )}
            />
            <Column
              caption="Expiry Date"
              dataField="expiry_date"
              alignment="center"
              cellRender={(data) => (
                <div className="badge badge-red">{data.value}</div>
              )}
            />
            <Column
              caption="Amount"
              dataField="amount"
              alignment="center"
              dataType="number"
              cellRender={(data) => {
                // Format the amount using Intl.NumberFormat
                const formattedAmount = new Intl.NumberFormat("tr-TR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(data.value);

                // TODO:
                return (
                  <div className="grid-cell-amount">
                    <div className="grid-amount">
                      <span
                        className={`badge badge-green`}
                      >{`${formattedAmount} ₼`}</span>
                    </div>
                  </div>
                );
              }}
            />
            <Column
              caption="Request Date"
              dataField="request_date"
              alignment="center"
              dataType="datetime"
              customizeText={(cellInfo) => {
                const options = {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                };
                return new Date(cellInfo.value)
                  .toLocaleDateString("tr-TR", options)
                  .replace(",", "");
              }}
            />

            <Column
              width={110}
              caption="Approve"
              dataField="approve"
              alignment="center"
              fixed
              fixedPosition="right"
              cellRender={(data) => (
                <Button
                  width={95}
                  height={24}
                  text="Approve"
                  stylingMode="contained"
                  style={{
                    backgroundColor: "#74B815",
                    color: "#F4F5F7",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "13px",
                    fontWeight: "700",
                    padding: "0",
                    margin: "0",
                    textAlign: "center",
                  }}
                  onClick={() => {
                    if (!myPermissions.includes("Withdrawal")) {
                      showNotification(
                        "Error",
                        "danger",
                        "You do not have permission to approve withdrawals."
                      );
                      return;
                    }
                    setSelectedWithdrawal(data.row.data);
                    setConfirmationPopupVisible(true);
                  }}
                />
              )}
            />

            <Column
              caption="Reject"
              dataField="reject"
              alignment="center"
              fixed
              fixedPosition="right"
              cellRender={(data) => (
                <Button
                  width={90}
                  height={24}
                  text="Reject"
                  stylingMode="contained"
                  style={{
                    backgroundColor: "#D63939",
                    color: "#F4F5F7",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "13px",
                    fontWeight: "700",
                    padding: "0",
                    margin: "0",
                  }}
                  onClick={() => {
                    if (!myPermissions.includes("Withdrawal")) {
                      showNotification(
                        "Error",
                        "danger",
                        "You do not have permission to reject withdrawals."
                      );
                      return; // Exit the function if the user does not have "Deposit" permission
                    }
                    setSelectedWithdrawal(data.row.data);
                    setDeclinePopupVisible(true);
                  }}
                />
              )}
            />
          </DataGrid>
        </div>
        <Popup
          width={400}
          height={420}
          hideOnOutsideClick={true}
          showTitle={true}
          title="Deposit Confirmation"
          visible={confirmationPopupVisible}
          onHiding={handleConfirmationPopupHidden}
          contentRender={renderConfirmationPopup}
          container=".dx-viewport"
          animation={{
            show: { type: "fade", duration: 400, from: { top: 0 } },
          }}
          position={{ my: "center", at: "center", of: window }}
        />
        <Popup
          width={400}
          height={420}
          hideOnOutsideClick={true}
          showTitle={true}
          title="Deposit Decline"
          visible={declinePopupVisible}
          onHiding={handleDeclinePopupHidden}
          contentRender={renderDeclinePopup}
          container=".dx-viewport"
          animation={{
            show: { type: "fade", duration: 400, from: { top: 0 } },
          }}
          position={{ my: "center", at: "center", of: window }}
        />
        <Popup
          baseZIndex={1000}
          width={530}
          height={555}
          showTitle={true}
          dragEnabled={false}
          hideOnOutsideClick={true}
          visible={detailPopupVisible}
          onHiding={handleDetailPopupHidden}
          contentRender={renderDetailPopup}
          showCloseButton={true}
          container=".dx-viewport"
          title="E-Manat Transaction Details"
          animation={{
            show: { type: "fade", duration: 400, from: { top: 0 } },
          }}
          position={{ my: "center", at: "center", of: window }}
        />
      </div>
    </div>
  );
};

export default InCardView;
