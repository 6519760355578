import React, { useState, useEffect } from "react";
import "./BlackListView.css";

import { IoMenu } from "react-icons/io5";
import { SiSocketdotio } from "react-icons/si";
import { BsSunFill } from "react-icons/bs";
import { FaUserCircle } from "react-icons/fa";

import { IoMdRefresh } from "react-icons/io";
import { FaClock } from "react-icons/fa6";
import { IoIosSearch } from "react-icons/io";

import { styled } from "@mui/system";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid

import { FaAngleLeft } from "react-icons/fa6";
import { FaAngleRight } from "react-icons/fa6";

import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import { SiAdblock } from "react-icons/si";
import { IoMdAdd } from "react-icons/io";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  Selection,
  DataGridTypes,
} from "devextreme-react/data-grid";

import Header from "../components/Header";
import { showNotification } from "../components/NotificationProvider";

const dataSource = [];

const traderNames = ["All"];

const CustomFormControl = styled(FormControl)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#82828282", // Normal durumdaki border rengi
    },
    "&:hover fieldset": {
      borderColor: "white", // Hover durumundaki border rengi
    },
    "&.Mui-focused fieldset": {
      borderColor: "1C76D2", // Focus durumundaki border rengi
    },
  },
});

const CustomSelect = styled(Select)({
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#82828282",
  },
  "& .MuiOutlinedInput-input": {
    fontSize: "14px",
    color: "white",
    display: "flex",
    alignItems: "center",
  },
  "& .MuiSelect-icon": {
    color: "white",
  },
  backgroundColor: "#1B2434",
  height: "4rem",
  width: "17rem",
  overflow: "hidden",
});

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#82828282",
    },
    "&:hover fieldset": {
      borderColor: "#1c77d25e",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#1c77d25e",
    },
    height: "4rem",
    width: "15rem",
    overflow: "hidden",
  },
  "& .MuiInputBase-input": {
    fontSize: "18px",
    color: "white",
    backgroundColor: "#1B2434",
    padding: ".9rem",
  },
  "& .MuiInputLabel-root": {
    fontSize: "18px",
    color: "white",
    scale: "0.73",
  },
});

const BlackListView = () => {
  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: "before",
        widget: "dxSelectBox",
        options: {
          items: traderNames,
          value: "All",
          showClearButton: false,
          width: 200,
          elementAttr: {
            class: "transaction-type-select",
          },
          onValueChanged: function (e) {},
        },
      },
      {
        location: "before",
        widget: "dxButton",
        options: {
          text: "Total 0 Piece",

          onClick: () => {
            navigator.clipboard.writeText("Total 16 Piece").then(
              () => {},
              () => {}
            );
          },
          elementAttr: {
            class: "info-button",
          },
          width: 150,
          stylingMode: "outlined",
        },
      }
    );
  };

  return (
    <div className="blacklist">
      <div className="deposit-body">
        <div className="deposit-header-container">
          <div className="deposit-header">
            <SiAdblock className="deposit-header-icon blacklist-header-icon" />
            <span>Black List</span>
          </div>
          <div
            className="deposit-header-refresh"
            onClick={() =>
              showNotification(
                "Info",
                "default",
                "The blacklist function is not yet active."
              )
            }
          >
            <IoMdAdd className="refresh-icon" />
            <span>Add new rule</span>
          </div>
        </div>

        <div className="deposit-table blacklist-table">
          <div
            className="ag-theme-quartz" // applying the grid theme
            style={{ height: 800 }} // the grid will fill the size of the parent container
          >
            <DataGrid
              dataSource={dataSource}
              showBorders={false}
              showRowLines={false}
              hoverStateEnabled={true}
              rowAlternationEnabled={false}
              allowColumnResizing={true}
              columnAutoWidth={true}
              columnMinWidth={1}
              columnResizingMode="nextColumn"
              columnFixing={{ enabled: true }}
              groupPanel={{ visible: false }}
              searchPanel={{
                visible: true,
                placeholder: "Search...",
              }}
              paging={{ pageSize: 25 }}
              pager={{
                visible: true,
                allowedPageSizes: [25, 50, 100],
                showPageSizeSelector: true,
                showInfo: true,
                showNavigationButtons: true,
              }}
              scrolling={{
                useNative: false,
                scrollByContent: true,
                scrollByThumb: true,
                showScrollbar: "onHover",
              }}
              export={{
                enabled: false,
                fileName: "Easypropay_deposit_snapshot",
                allowExportSelectedData: false,
              }}
              height="100%"
              onToolbarPreparing={onToolbarPreparing}
            ></DataGrid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlackListView;
