import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import "./DepositView.css";

import { IoMdRefresh } from "react-icons/io";
import { FaClock } from "react-icons/fa6";
import { IoIosSearch } from "react-icons/io";
import { FaInfoCircle } from "react-icons/fa";

import { styled } from "@mui/system";
import TextField from "@mui/material/TextField";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { RiDeleteBack2Fill } from "react-icons/ri";

import Select from "@mui/material/Select";

import "tom-select/dist/css/tom-select.css";

import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid

import DataGrid, {
  Export,
  Selection,
  GroupPanel,
  Paging,
  Pager,
  Scrolling,
  ColumnFixing,
  SearchPanel,
  Column,
  HeaderFilter,
  FilterRow,
} from "devextreme-react/data-grid";

import Popup from "devextreme-react/popup";
import Divider from "@mui/material/Divider";
import { RiArrowRightDoubleFill } from "react-icons/ri";
import { MdCancel } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";

// import "devextreme/dist/css/dx.light.css";

import { NumericFormat } from "react-number-format";
// import { Button } from "devextreme-react/button";
import NumberBox from "devextreme-react/number-box";

import Accordion, { Item } from "devextreme-react/accordion";

import { BsSendArrowUpFill } from "react-icons/bs";
import { RiUserReceivedFill } from "react-icons/ri";
import { GrTarget } from "react-icons/gr";
import { BsPencilSquare } from "react-icons/bs";

import { TextBox } from "devextreme-react/text-box";

import { useSelector } from "react-redux";
import { showNotification } from "../components/NotificationProvider";

import { useDispatch } from "react-redux";
import { getDepositQueue, getMe } from "../store";

import Button from "@mui/material/Button";
import { FaCheck } from "react-icons/fa";
import { ImCancelCircle } from "react-icons/im";
import { AiOutlineMessage } from "react-icons/ai";
import { MdRoomPreferences } from "react-icons/md";
import { FiTarget } from "react-icons/fi";
import { RiProgress5Line } from "react-icons/ri";
import { FaUser } from "react-icons/fa";
import { MdOutlinePending } from "react-icons/md";
import { MdOutlineDoNotDisturbOff } from "react-icons/md";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import { CgDetailsMore } from "react-icons/cg";

import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import ContextMenu from "devextreme-react/context-menu";
import _ from "lodash";

const CustomAmountTextField = ({ value, onChange, placeholder }) => {
  const handleInputChange = (e) => {
    const inputValue = e.target.value;

    const regex = /^[0-9]*[.,]?[0-9]*$/;

    if (regex.test(inputValue)) {
      onChange(e);
    }
  };

  return (
    <OutlinedInput
      value={value}
      onChange={handleInputChange}
      placeholder={placeholder}
      sx={{
        backgroundColor: "#1B2434",
        borderRadius: "4px", // Input'un köşelerini hafif yuvarlamak için
        color: "#dedede",
        height: "4rem",
        fontSize: "1.6rem",
        marginRight: "1rem",
        width: "15rem",

        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#29364a",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: "#225978",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "#19a9dd",
          borderWidth: "1px",
        },
        "& .MuiInputBase-input": {
          color: "#dedede",
          fontSize: "1.6rem",
        },
        "& .MuiInputBase-input::placeholder": {
          color: "#afafaf",
          fontSize: "1.6rem",
        },
      }}
      endAdornment={
        value && (
          <InputAdornment position="end">
            <IconButton
              aria-label="clear input"
              onClick={() => onChange({ target: { value: "" } })}
              edge="end"
            >
              <RiDeleteBack2Fill color="#afafaf" />
            </IconButton>
          </InputAdornment>
        )
      }
    />
  );
};

const dataSource = [];

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#82828282",
    },
    "&:hover fieldset": {
      borderColor: "#1c77d25e",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#1c77d25e",
    },
    height: "4rem",
    width: "15rem",
    overflow: "hidden",
  },
  "& .MuiInputBase-input": {
    fontSize: "18px",
    color: "white",
    backgroundColor: "#1B2434",
    padding: ".9rem",
    textAlign: "center",
  },
  "& .MuiInputLabel-root": {
    fontSize: "18px",
    color: "white",
    scale: "0.73",
  },
});
//TODO:
const depositsArray = [];

const customStore = new CustomStore({
  key: "id",
  load: () => {
    return depositsArray.map((deposit) => ({
      id: deposit._id,
      method: deposit.method,
      transaction_id: deposit.transactionId,
      trader: deposit.traderName,
      host_user_id: deposit.userId,
      host_user_name: deposit.userId,
      sender_full_name: deposit.cardInfo?.name || "N/A",
      amount: deposit.amount,
      isAmountChanged: deposit.isAmountChanged,
      oldAmount: deposit.oldAmount,
      recipient: deposit.recipientName,
      recipient_account: deposit.recipientAccount,
      create_date: deposit.createdAt,
      status: deposit.status,
      smsStatus: deposit.smsStatus,
      smsCode: deposit.smsCode,
      reservedPerson: deposit.reservedPerson,
      reservedPersonName: deposit.reservedPersonName,
      // rezerv: deposit.reservedPerson,
      detail: "Detail",
    }));
  },
  byKey: (key) => {
    // Find and return the deposit with the given key
    const deposit = depositsArray.find((item) => item._id === key);
    return deposit
      ? Promise.resolve(deposit)
      : Promise.reject(new Error("Deposit not found"));
  },
  insert: (values) => {
    depositsArray.push(values);
    return Promise.resolve(); // Promise döndürmek, işlemin tamamlandığını belirtir
  },
  update: (key, values) => {
    const index = depositsArray.findIndex((item) => item._id === key);
    depositsArray[index] = { ...depositsArray[index], ...values };
    return Promise.resolve();
  },
  remove: (key) => {
    const index = depositsArray.findIndex((item) => item._id === key);
    depositsArray.splice(index, 1);
    return Promise.resolve();
  },
});

//TODO:
const DepositView = () => {
  const { user } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const [containerHeight, setContainerHeight] = useState(window.innerHeight);
  const [tableHeight, setTableHeight] = useState(window.innerHeight);

  const [changeAmountPopupVisible, setChangeAmountPopupVisible] =
    useState(false);
  const [detailPopupVisible, setDetailPopupVisible] = useState(false);
  const [manualAccessPopupVisible, setManualAccessPopupVisible] =
    useState(false);
  const [manualAccessCode, setManualAccessCode] = useState("");
  const [isApproveDisabled, setIsApproveDisabled] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      const remInPx = parseFloat(
        getComputedStyle(document.documentElement).fontSize
      );
      const newHeight = window.innerHeight - 7 * remInPx;
      setContainerHeight(newHeight);

      const newTableHeight = window.innerHeight - 16.5 * remInPx;
      setTableHeight(newTableHeight);
    };

    // Set initial height
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    document.documentElement.style.setProperty("--lolo", `${tableHeight}px`);
  }, [tableHeight]);

  const [dataGridSource, setDataGridSource] = useState(dataSource);

  const [confirmationPopupVisible, setConfirmationPopupVisible] =
    useState(false);
  const [declinePopupVisible, setDeclinePopupVisible] = useState(false);

  const [personId, setPersonId] = useState("");
  const [myPermissions, setMyPermissions] = useState("");
  const [dropPermission, setDropPermission] = useState(false);

  useEffect(() => {
    if (user && user.user) {
      setPersonId(user.user._id);
      setDropPermission(user.user.dropDepositPermission);
      setMyPermissions(user.user.permissions);
      setDropPermission(user.user.dropDepositPermission);
    }
  }, [user]);

  const getDeposits = async () => {
    try {
      depositsArray.length = 0;

      const res = await dispatch(getDepositQueue()).unwrap();
      console.log(res);

      // setDeposits(res.data.data.deposits);

      const deposits = res.data.data.deposits;

      const insertPromises = deposits.map((deposit) => {
        const newRow = { ...deposit };
        return customStore.insert(newRow);
      });

      await Promise.all(insertPromises);
      dxSource.reload();
    } catch (error) {
      console.error("Failed to fetch deposits", error);
    }
  };

  useEffect(() => {
    getDeposits();
  }, [dispatch]);

  const handleRefreshClick = () => {
    // getDeposits();
    showNotification("Success", "success", "Deposits refreshed");
  };

  const handleManualAccessCodeChange = (e) => {
    setManualAccessCode(e.target.value);
  };

  const handleChangeAmountPopupHidden = useCallback(() => {
    setChangeAmountPopupVisible(false);
    setAmount(null);
    setSender(null);
    setRecipient(null);
    setNewAmount(null);
  }, [setChangeAmountPopupVisible]);

  const handleDetailPopupHidden = useCallback(() => {
    setDetailPopupVisible(false);
    setSelectedDeposit(null);
    setSmsReqButtonDisabled(false);
    setIsManualSms(false);
    setExplanation("");
    setApproveButtonDisabled(true);
  }, [setDetailPopupVisible]);

  const handleManualAccessPopupHidden = useCallback(() => {
    setManualAccessPopupVisible(false);
  }, [setManualAccessPopupVisible]);

  const handleConfirmationPopupHidden = useCallback(() => {
    setConfirmationPopupVisible(false);
  }, [setConfirmationPopupVisible]);

  const handleDeclinePopupHidden = useCallback(() => {
    setDeclinePopupVisible(false);
  }, [setDeclinePopupVisible]);

  const handleManualAccessClick = () => {
    setManualAccessPopupVisible(true);
  };

  const onSelectionChanged = useCallback(({ selectedRowsData }) => {
    const data = selectedRowsData[0];
  });

  //TODO:
  const [socket, setSocket] = useState(null);
  const [deposits, setDeposits] = useState([]);
  const dataGridSourceMemo = useMemo(() => dataGridSource, [dataGridSource]);
  const [explanation, setExplanation] = useState("");
  const [selectedDepositId, setSelectedDepositId] = useState(null);
  const [depositIdforAmount, setDepositIdforAmount] = useState(null);
  const [amount, setAmount] = useState(null);
  const [sender, setSender] = useState(null);
  const [recipient, setRecipient] = useState(null);
  const [newAmount, setNewAmount] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [gridFilterSelectedValue, setGridFilterSelectedValue] =
    useState("All Transactions");
  const [selectedDeposit, setSelectedDeposit] = useState(null);
  const [smsReqButtonDisabled, setSmsReqButtonDisabled] = useState(false);
  const [isManualSms, setIsManualSms] = useState(false);
  const [approveButtonDisabled, setApproveButtonDisabled] = useState(true);

  const [minAmount, setMinAmount] = useState("");
  const [maxAmount, setMaxAmount] = useState("");

  const [dxSource, setDxSource] = useState(
    new DataSource({ store: customStore })
  );

  // TEST ADD ROW
  // const handleAddRow = () => {
  //   const intervalId = setInterval(() => {}, 50);

  //   setTimeout(() => {
  //     clearInterval(intervalId);
  //   }, 60000);
  // };

  // const applyGridSource = (filterType) => {
  //   if (deposits.length > 0) {
  //     let formattedData = deposits.map((deposit, index) => ({
  //       id: deposit._id,
  //       transaction_id: deposit.transactionId,
  //       trader: deposit.traderName,
  //       host_user_id: deposit.userId,
  //       host_user_name: deposit.userId,
  //       sender_full_name: deposit.cardInfo?.name || "N/A",
  //       amount: new Intl.NumberFormat("tr-TR", {
  //         minimumFractionDigits: 2,
  //         maximumFractionDigits: 2,
  //       }).format(deposit.amount),
  //       isAmountChanged: deposit.isAmountChanged,
  //       oldAmount: deposit.oldAmount,
  //       recipient: deposit.recipientName,
  //       recipient_account: deposit.recipientAccount,
  //       create_date: deposit.createdAt,
  //       status: deposit.smsStatus,
  //       smsCode: deposit.smsCode,
  //       reservedPerson: deposit.reservedPerson,
  //       reservedPersonName: deposit.reservedPersonName,
  //       rezerv: deposit.reservedPerson,
  //       detail: "Detail",
  //     }));

  //     if (minAmount !== undefined && minAmount !== "" && !isNaN(minAmount)) {
  //       formattedData = formattedData.filter(
  //         (item) => item.amount >= parseFloat(minAmount)
  //       );
  //     }
  //     if (maxAmount !== undefined && maxAmount !== "" && !isNaN(maxAmount)) {
  //       formattedData = formattedData.filter(
  //         (item) => item.amount <= parseFloat(maxAmount)
  //       );
  //     }

  //     if (filterType === "Free and My Transactions") {
  //       formattedData = formattedData.filter(
  //         (item) => !item.reservedPerson || item.reservedPerson === personId
  //       );
  //     } else if (filterType === "My Transactions") {
  //       formattedData = formattedData.filter(
  //         (item) => item.reservedPerson === personId
  //       );
  //     } else if (filterType === "Free Transactions") {
  //       formattedData = formattedData.filter((item) => !item.reservedPerson);
  //     }

  //     setDataGridSource(formattedData);
  //   } else {
  //     setDataGridSource([]);
  //     setTotalAmount(0);
  //   }
  // };

  //   // 200ms aralıklı veri işleme
  // const throttle = (func, limit) => {
  //   let inThrottle;
  //   return function () {
  //     const args = arguments;
  //     const context = this;
  //     if (!inThrottle) {
  //       func.apply(context, args);
  //       inThrottle = true;
  //       setTimeout(() => (inThrottle = false), limit);
  //     }
  //   };
  // };
  //   const throttledHandleNewDeposit = throttle(handleNewDeposit, 200);

  //TODO:
  const selectedDepositRef = useRef(null);

  useEffect(() => {
    const ws = new WebSocket(`wss://panel.easypropay.com/ws/panel`);
    // const ws = new WebSocket(`ws://localhost:3001/ws/panel`);

    // const ws = new WebSocket(`ws://192.168.50.187:3001/ws/panel`);

    ws.onopen = () => {
      console.log("Connected to panel WebSocket");
    };

    ws.onmessage = (message) => {
      const data = JSON.parse(message.data);
      console.log(data);
      switch (data.type) {
        case "newDeposit":
          // throttledHandleNewDeposit(data.deposit);
          // setDeposits((prevDeposits) => [...prevDeposits, data.deposit]);

          const newRow = { ...data.deposit };
          customStore.insert(newRow).then(() => {
            dxSource.reload();
          });

          break;

        case "reservedByPerson":
          customStore
            .update(data.depositId, {
              reservedPerson: data.reservedPerson,
              reservedPersonName: data.reservedPersonName,
              status: data.status,
            })
            .then(() => {
              dxSource.reload();
            });

          // setDeposits((prevDeposits) =>
          //   prevDeposits.map((deposit) =>
          //     deposit._id === data.depositId
          //       ? {
          //           ...deposit,
          //           reservedPerson: data.reservedPerson,
          //           reservedPersonName: data.reservedPersonName,
          //           status: data.status,
          //         }
          //       : deposit
          //   )
          // );

          break;
        case "reserveResult":
          if (data.message === "Reserved successfully") {
            showNotification(
              "Success",
              "success",
              "Deposit reserved successfully."
            );
          } else {
            showNotification("Error", "danger", data.message);
          }
          break;

        case "smsStatusUpdate":
          customStore
            .update(data.depositId, {
              smsStatus: data.smsStatus,
            })
            .then(() => {
              dxSource.reload();
            });

          // setDeposits((prevDeposits) =>
          //   prevDeposits.map((deposit) =>
          //     deposit._id === data.depositId
          //       ? {
          //           ...deposit,
          //           smsStatus: data.smsStatus,
          //         }
          //       : deposit
          //   )
          // );

          break;

        case "smsRequestResult": // Add this new case
          showNotification(
            data.message.includes("success") ? "Success" : "Error",
            data.message.includes("success") ? "success" : "danger",
            data.message
          );
          break;

        case "smsCodeReceived":
          customStore
            .update(data.depositId, {
              smsStatus: data.smsStatus,
              smsCode: data.smsCode,
            })
            .then(() => {
              dxSource.reload();
            });

          // setDeposits((prevDeposits) =>
          //   prevDeposits.map((deposit) =>
          //     deposit._id === data.depositId
          //       ? {
          //           ...deposit,
          //           smsStatus: data.smsStatus,
          //           smsCode: data.smsCode,
          //         }
          //       : deposit
          //   )
          // );
          console.log(selectedDepositRef.current?._id);
          if (data.depositId === selectedDepositRef.current?._id) {
            const smsArea = document.querySelector(".deposit-detail-sms-area");
            if (smsArea) {
              smsArea.textContent = `${data.smsCode}`; // Update content as needed
              showNotification("Success", "success", "SMS code received");
              setApproveButtonDisabled(false);
            }
          }
          break;

        case "setManualSmsResult":
          showNotification(
            data.message.includes("success") ? "Success" : "Error",
            data.message.includes("success") ? "success" : "danger",
            data.message
          );
          break;

        case "amountChanged":
          customStore
            .update(data.depositId, {
              amount: data.amount,
              oldAmount: data.oldAmount,
              isAmountChanged: data.isAmountChanged,
            })
            .then(() => {
              dxSource.reload();
            });

          // setDeposits((prevDeposits) =>
          //   prevDeposits.map((deposit) =>
          //     deposit._id === data.depositId
          //       ? {
          //           ...deposit,
          //           amount: data.amount,
          //           oldAmount: data.oldAmount,
          //           isAmountChanged: data.isAmountChanged,
          //         }
          //       : deposit
          //   )
          // );

          break;

        case "amountChangeResult":
          if (data.message === "Amount changed successfully") {
            showNotification("Success", "success", data.message);
          } else {
            showNotification("Error", "danger", data.message);
          }
          break;

        case "depositDropped":
          customStore
            .update(data.depositId, {
              ...data.deposit,
            })
            .then(() => {
              dxSource.reload();
            });

          // setDeposits((prevDeposits) =>
          //   prevDeposits.map((deposit) =>
          //     deposit._id === data.depositId ? data.deposit : deposit
          //   )
          // );
          break;

        case "dropDepositResult":
          if (data.message === "Deposit dropped successfully") {
            showNotification("Success", "success", data.message);
          } else {
            showNotification("Error", "danger", data.message);
          }
          break;

        case "depositApproved":
          customStore.remove(data.depositId).then(() => {
            dxSource.reload();
          });

          // setDeposits((prevDeposits) =>
          //   prevDeposits.filter((deposit) => deposit._id !== data.depositId)
          // );

          break;

        case "approveResult":
          if (data.message === "Deposit approved successfully") {
            showNotification("Success", "success", data.message);
          } else {
            showNotification("Error", "danger", data.message);
          }
          break;

        case "depositDeclined":
          if (data.explanation === "Deposit abandoned by user") {
            // showNotification("Error", "danger", data.explanation);
          }
          customStore.remove(data.depositId).then(() => {
            dxSource.reload();
          });

          // setDeposits((prevDeposits) =>
          //   prevDeposits.filter((deposit) => deposit._id !== data.depositId)
          // );

          break;

        case "declineResult":
          // showNotification("Success", "success", data.message);
          if (data.message === "Deposit declined successfully") {
            showNotification("Success", "success", data.message);
          } else {
            showNotification("Error", "danger", data.message);
          }
          break;

        case "depositNotFound":
          customStore.remove(data.depositId).then(() => {
            dxSource.reload();
          });

          break;

        case "reserveDepositNotFound":
          showNotification(
            "Error",
            "danger",
            "Deposit not found, it will be removed from the queue."
          );
          break;

        case "error":
          showNotification("Error", "danger", data.message);
          break;

        case "depositFinish":
          // Handle deposit completion and update state
          break;

        default:
          console.log("Unknown message type:", data.type);
          showNotification("Error", "danger", "Unknown message type");
      }
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    ws.onclose = () => {
      console.log("WebSocket connection closed");
    };

    setSocket(ws);

    return () => {
      ws.close();
    };
  }, []);

  useEffect(() => {
    if (selectedDeposit) {
      const updatedDeposit = deposits.find(
        (deposit) => deposit._id === selectedDeposit._id
      );
      if (updatedDeposit) {
        setSelectedDeposit(updatedDeposit);
      }
    }
  }, [deposits, selectedDeposit?._id]);

  //TODO:
  const handleReserve = (depositId) => {
    if (!myPermissions.includes("Deposit")) {
      showNotification(
        "Error",
        "danger",
        "You do not have permission to reserve deposits."
      );
      return; // Exit the function if the user does not have "Deposit" permission
    }

    if (personId !== "") {
      if (socket) {
        try {
          socket.send(JSON.stringify({ type: "reserve", depositId, personId }));
          dxSource.reload();
        } catch (error) {
          console.error("Failed to reserve deposit", error);
          showNotification(
            "Error",
            "danger",
            "Invalid request, please log in again."
          );
        }
      }
    } else {
      showNotification("error", "Error", "User not found, please login again");
    }
  };

  const handleDetailClick = (id) => {
    customStore
      .byKey(id)
      .then((tempDeposit) => {
        if (tempDeposit) {
          setSelectedDeposit(tempDeposit);
          selectedDepositRef.current = tempDeposit;

          const senderCardNumber = tempDeposit?.cardInfo?.number;
          if (senderCardNumber && senderCardNumber.startsWith("4098")) {
            setIsManualSms(true);
          }
        } else {
          console.error("Deposit not found");
          showNotification(
            "Error",
            "danger",
            "Deposit not found, socket stuck, please refresh the page."
          );
        }
      })
      .catch((error) => {
        console.error("Failed to retrieve deposit", error);
        showNotification(
          "Error",
          "danger",
          "Deposit not found, socket stuck, please refresh the page."
        );
      });
  };

  useEffect(() => {
    if (selectedDeposit) {
      setDetailPopupVisible(true);
      console.log(selectedDeposit);
    }
  }, [selectedDeposit]);

  const handleGetSms = (depositId) => {
    if (personId !== "") {
      try {
        if (socket) {
          socket.send(JSON.stringify({ type: "getSms", depositId, personId }));
          setSmsReqButtonDisabled(true);
          showNotification(
            "Success",
            "success",
            "SMS request successfully sent"
          );
        }
      } catch (error) {
        console.error("Failed to get sms", error);
        showNotification(
          "Error",
          "Error",
          "Invalid request, please log in again."
        );
      }
    } else {
      showNotification("error", "Error", "User not found, please login again");
    }
  };

  const handleSetManualSms = (depositId) => {
    if (manualAccessCode === "1111") {
      // setIsApproveDisabled(false);
      if (personId !== "") {
        try {
          if (socket) {
            socket.send(
              JSON.stringify({
                type: "setManualSms",
                depositId,
                personId,
                smsCode: "1111",
              })
            );
          }
          setIsManualSms(false);
          setManualAccessPopupVisible(false);
        } catch (error) {
          console.error("Failed to set manual sms", error);
          showNotification(
            "Error",
            "Error",
            "Invalid request, please log in again."
          );
        }
      } else {
        showNotification(
          "error",
          "Error",
          "User not found, please login again"
        );
      }
    }
  };

  const handleApprove = (depositId) => {
    if (socket) {
      try {
        socket.send(
          JSON.stringify({
            type: "depositApproved",
            depositId,
            personId,
          })
        );

        setConfirmationPopupVisible(false);
        setDetailPopupVisible(false);
      } catch (error) {
        console.error("Failed to approve deposit", error);
        showNotification(
          "Error",
          "danger",
          "Invalid request, please log in again."
        );
      }
    }
  };

  const handleDecline = (depositId) => {
    if (socket) {
      try {
        socket.send(
          JSON.stringify({
            type: "depositDeclined",
            depositId,
            personId,
            explanation,
          })
        );
        setDeclinePopupVisible(false);
        setDetailPopupVisible(false);
      } catch (error) {
        console.error("Failed to decline deposit", error);
        showNotification(
          "Error",
          "danger",
          "Invalid request, please log in again."
        );
      }
    }
  };

  const handleAmountChangePopup = (id) => {
    customStore
      .byKey(id)
      .then((tempDeposit) => {
        if (tempDeposit) {
          console.log(tempDeposit);
          setDepositIdforAmount(tempDeposit._id);

          setAmount(
            tempDeposit.oldAmount ? tempDeposit.oldAmount : tempDeposit.amount
          );
          setNewAmount(tempDeposit.amount);
          setSender(tempDeposit.cardInfo?.name);
          setRecipient(tempDeposit.recipientName);
          setChangeAmountPopupVisible(true);
        } else {
          console.error("Deposit not found");
        }
      })
      .catch((error) => {
        console.error("Failed to retrieve deposit", error);
        showNotification(
          "Error",
          "danger",
          "Deposit not found, socket stuck, please refresh the page."
        );
      });
  };

  const handleChangeAmount = (depositId, newAmount) => {
    console.log(depositId, newAmount);
    const difference = Math.abs(amount - newAmount);
    if (difference > 10) {
      showNotification(
        "Error",
        "danger",
        "New amount must be within ±10 of the current amount."
      );
      return;
    }

    if (socket) {
      try {
        socket.send(
          JSON.stringify({
            type: "amountChangeRequest",
            depositId,
            personId,
            newAmount: newAmount,
          })
        );
        setChangeAmountPopupVisible(false);
        setAmount(null);
        setSender(null);
        setRecipient(null);
        setNewAmount(null);
      } catch (error) {
        console.error("Failed to change amount", error);
        showNotification(
          "Error",
          "danger",
          "Invalid request, please log in again."
        );
      }
    }
  };

  const handleDropDeposit = (depositId) => {
    if (!dropPermission) {
      showNotification(
        "Error",
        "danger",
        "You do not have permission to drop deposits."
      );
      return; // Exit the function if the user does not have "Deposit" permission
    }

    if (socket) {
      try {
        socket.send(
          JSON.stringify({
            type: "dropDepositRequest",
            depositId,
            personId,
          })
        );
      } catch (error) {
        console.error("Failed to drop deposit", error);
        showNotification(
          "Error",
          "danger",
          "Invalid request, please log in again."
        );
      }
    }
  };

  const canDrop = (deposit) =>
    deposit?.reservedPerson === personId && dropPermission;

  const handleAmountChangeBlur = () => {};

  const formatCardNumber = (number) => {
    return number.replace(/\D/g, "").replace(/(\d{4})(?=\d)/g, "$1 ");
  };

  //TODO:
  const renderChangeAmountPopup = useCallback(
    () => (
      <div className="popup-amount-change">
        <div className="popup-body-top-container">
          <div className="popup-body-left">
            <span className="popup-sender popup-person-title popup-sender-title">
              Sender
            </span>
            <span className="popup-sender popup-person-name popup-sender-name">
              {sender}
            </span>
          </div>
          <div className="popup-body-center">
            <div className="popup-brand">EasyProPay</div>
            <RiArrowRightDoubleFill className="popup-transfer-icon" />
            <div className="popup-amount">
              {amount
                ? new Intl.NumberFormat("tr-TR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(amount)
                : ""}{" "}
              ₼
            </div>
          </div>
          <div className="popup-body-right">
            <span className="popup-sender popup-person-title popup-receipent-title">
              Receipent
            </span>
            <span className="popup-sender popup-person-name popup-receipent-name">
              {recipient}
            </span>
          </div>
        </div>
        <div className="popup-body-bottom-container">
          {/* <CustomTextField
            InputProps={{
              inputComponent: ManatNumberFormat,
            }}
            variant="outlined"
            label="New Amount"
            value={newAmount}
            onChange={(e) => setNewAmount(e.target.value)}
          /> */}
          <NumberBox
            value={newAmount}
            format="#,##0.00 ₼"
            showSpinButtons={true}
            valueChangeEvent="input"
            onValueChange={(e) => setNewAmount(e)}
            onFocusOut={handleAmountChangeBlur}
          />
        </div>
        <Divider
          orientation="horizontal"
          variant="middle"
          flexItem
          sx={{ borderColor: "#ffffff14", margin: "30px 0 10px 0" }}
        />
        <div className="popup-footer">
          <div className="popup-footer-left">
            <Button
              variant="outlined"
              color="error"
              sx={{
                width: "12rem",
                fontSize: "1.1rem",
                fontWeight: "700",
                height: "3.5rem",
              }}
              onClick={() => setChangeAmountPopupVisible(false)}
            >
              Cancel
            </Button>
          </div>
          <div className="popup-footer-right">
            <Button
              variant="outlined"
              color="success"
              sx={{
                width: "12rem",
                fontSize: "1.1rem",
                fontWeight: "700",
                height: "3.5rem",
                backgroundColor: "#73B816",
                "&:hover": {
                  backgroundColor: "#73B816",
                },
              }}
              onClick={() => handleChangeAmount(depositIdforAmount, newAmount)}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    ),
    [amount, sender, recipient, newAmount, depositIdforAmount]
  );

  const renderDetailPopup = useCallback(
    () =>
      // FIXME:
      detailPopupVisible && (
        <div className="popup-deposit-detail">
          <div className="popup-deposit-detail-body">
            <Accordion
              collapsible={true}
              multiple={false}
              animationDuration={300}
              id="accordion-container"
              defaultSelectedIndex={2}
            >
              <Item
                titleRender={() => (
                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    className="acordion-green"
                  >
                    <BsSendArrowUpFill style={{ marginRight: 8 }} />
                    <span className="acordion-green">Sender Information</span>
                  </div>
                )}
              >
                <div className="deposit-info-table">
                  <table className="customTable">
                    <thead>
                      <tr>
                        <th>Trader</th>
                        <th>Website Username</th>
                        <th>Website User ID</th>
                        <th>Name</th>
                        <th>Card Number</th>
                        <th>Exp Date</th>
                        <th>Cvv</th>
                        <th>Amount</th>
                        <th>Transacation ID</th>
                        <th>IP Address</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <span className="badge badge-orange">
                            {selectedDeposit?.traderName}
                          </span>
                        </td>
                        <td>
                          <span className="badge badge-purple">
                            {selectedDeposit?.userId}
                          </span>
                        </td>
                        <td>
                          <span className="badge badge-purple">
                            {selectedDeposit?.userId}
                          </span>
                        </td>
                        <td>
                          <span className="badge badge-purple">
                            {selectedDeposit?.cardInfo?.name}
                          </span>
                        </td>
                        <td>{selectedDeposit?.cardInfo?.number}</td>
                        <td>{selectedDeposit?.cardInfo?.expiry}</td>
                        <td>{selectedDeposit?.cardInfo?.cvc}</td>
                        <td>
                          <span className="badge badge-green">
                            {selectedDeposit?.amount}
                          </span>
                        </td>
                        <td>{selectedDeposit?.transactionId}</td>
                        <td>1.1.1.1</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Item>

              <Item
                titleRender={() => (
                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    className="acordion-red"
                  >
                    <RiUserReceivedFill style={{ marginRight: 8 }} />
                    <span className="acordion-red">Receipent Information</span>
                  </div>
                )}
              >
                <div className="deposit-info-table">
                  <table className="customTable">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Card Number</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <span className="badge badge-purple">
                            {selectedDeposit?.recipientName}
                          </span>
                        </td>
                        <td>
                          <span className="badge badge-orange">
                            {selectedDeposit?.recipientAccount}
                          </span>
                        </td>
                        <td>
                          <span className="badge badge-green">
                            {selectedDeposit?.amount}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Item>

              <Item
                titleRender={() => (
                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    className="acordion-orange"
                  >
                    <GrTarget style={{ marginRight: 8 }} />
                    <span className="acordion-orange">Get Progress</span>
                  </div>
                )}
              >
                <div className="deposit-detail-action">
                  <div className="deposit-detail-action">
                    <div className="deposit-detail-action-row">
                      <div className="deposit-info">
                        Receipent: {selectedDeposit?.recipientAccount}
                      </div>
                    </div>
                    <Divider
                      orientation="horizontal"
                      variant="middle"
                      flexItem
                      sx={{ borderColor: "#ffffff14", margin: "" }}
                    />
                    <div className="deposit-detail-action-row">
                      <div className="deposit-info">
                        Sender: &nbsp;
                        <span className="span-orange">
                          {selectedDeposit?.cardInfo?.number
                            ? formatCardNumber(selectedDeposit.cardInfo.number)
                            : ""}
                        </span>
                      </div>
                      <div className="deposit-info">
                        Exp. Date: &nbsp;
                        <span className="span-orange">
                          {selectedDeposit?.cardInfo.expiry}
                        </span>
                      </div>
                      <div className="deposit-info">
                        Cvv/2: &nbsp;
                        <span className="span-orange">
                          {selectedDeposit?.cardInfo.cvc}
                        </span>
                      </div>
                      <div className="deposit-info">
                        Amount: &nbsp;
                        <span className="span-orange">
                          {selectedDeposit
                            ? new Intl.NumberFormat("tr-TR", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }).format(selectedDeposit.amount)
                            : ""}
                        </span>
                      </div>
                    </div>
                    <Divider
                      orientation="horizontal"
                      variant="middle"
                      flexItem
                      sx={{ borderColor: "#ffffff14", margin: "" }}
                    />
                    <div className="deposit-detail-action-row">
                      {/* <Button
                        icon="message"
                        type="default"
                        text="Request SMS"
                        style={{ marginRight: "1rem", width: "20% !important" }}
                        onClick={() => handleGetSms(selectedDeposit?._id)}
                        disabled={smsReqButtonDisabled || isManualSms}
                      /> */}

                      <Button
                        id="btn-deposit-request-sms"
                        variant="contained"
                        sx={{
                          fontSize: "1.3rem",
                          // fontWeight: "700",
                          marginRight: "1rem",
                        }}
                        startIcon={<AiOutlineMessage />}
                        onClick={() => {
                          if (!smsReqButtonDisabled && !isManualSms)
                            handleGetSms(selectedDeposit?._id);
                        }}
                        disabled={smsReqButtonDisabled || isManualSms}
                      >
                        Request SMS
                      </Button>
                      <Button
                        id="btn-deposit-manual"
                        variant="contained"
                        sx={{
                          fontSize: "1.3rem",
                          // fontWeight: "700",
                          marginRight: "1rem",
                          backgroundColor: "#FEAF27",
                          "&:hover": {
                            backgroundColor: "#FEAF27",
                          },
                        }}
                        startIcon={<MdRoomPreferences />}
                        onClick={handleManualAccessClick}
                      >
                        Manual
                      </Button>

                      {/* <Button
                        icon="preferences"
                        className="deposit-detail-orange-button"
                        text="Manual"
                        style={{ marginRight: "1rem" }}
                        onClick={handleManualAccessClick}
                      /> */}
                      <div className="deposit-info">
                        Code:&nbsp;
                        <span className="deposit-detail-sms-area span-orange">
                          {selectedDeposit?.smsCode}
                        </span>
                      </div>
                    </div>

                    <div className="deposit-detail-action-row deposit-detail-action-row--buttons">
                      {/* <Button
                        className="deposit-detail-action-button"
                        icon="check"
                        type="success"
                        text="Approve"
                        height={45}
                        onClick={() => setConfirmationPopupVisible(true)}
                      /> */}
                      {/* <Button
                        className="deposit-detail-action-button"
                        icon="close"
                        type="danger"
                        text="Reject"
                        height={45}
                        onClick={() => setDeclinePopupVisible(true)}
                      /> */}
                      <Button
                        id="btn-deposit-approve"
                        className="deposit-detail-action-button"
                        variant="contained"
                        color="success"
                        sx={{
                          // width: "12rem",
                          fontSize: "1.3rem",
                          fontWeight: "700",
                          height: "4.5rem",
                        }}
                        onClick={() => setConfirmationPopupVisible(true)}
                        startIcon={<FaCheck />}
                        disabled={
                          !selectedDeposit?.smsCode && approveButtonDisabled
                        }
                      >
                        Approve
                      </Button>
                      <Button
                        id="btn-deposit-reject"
                        className="deposit-detail-action-button"
                        variant="contained"
                        color="error"
                        sx={{
                          // width: "12rem",
                          fontSize: "1.3rem",
                          fontWeight: "700",
                          height: "4.5rem",
                        }}
                        onClick={() => setDeclinePopupVisible(true)}
                        startIcon={<ImCancelCircle />}
                      >
                        Reject
                      </Button>
                    </div>
                    <div className="deposit-detail-action-row">
                      {/* TODO: */}
                      <TextField
                        id="tb-deposit-explanation"
                        value={explanation}
                        onChange={(e) => setExplanation(e.target.value)}
                        fullWidth
                        label="Explanation"
                        multiline
                        rows={2}
                        variant="filled"
                        sx={{
                          "& .MuiInputLabel-root": {
                            color: "#cecece", // Label color
                            fontSize: "1.4rem", // Label font size
                          },
                          "& .MuiInputBase-input": {
                            color: "#fafafa", // Text color
                            fontSize: "1.6rem", // Text font size
                          },
                          "& .MuiFilledInput-root": {
                            backgroundColor: "#1B2434", // Background color
                          },
                          "& .MuiInputLabel-root.Mui-focused": {
                            color: "#dedede", // Label color when focused
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Item>
            </Accordion>
          </div>
          <Divider
            orientation="horizontal"
            variant="middle"
            flexItem
            sx={{ borderColor: "#ffffff14", margin: "30px 0 10px 0" }}
          />
          <div className="popup-footer">
            <div className="popup-footer-left">
              <Button
                variant="outlined"
                color="error"
                sx={{
                  width: "12rem",
                  fontSize: "1.3rem",
                  fontWeight: "700",
                }}
                onClick={() => {
                  setDetailPopupVisible(false);
                  setSelectedDeposit(null);
                }}
              >
                Cancel
              </Button>
            </div>
            <div className="popup-footer-right"></div>
          </div>
        </div>
      ),
    [
      detailPopupVisible,
      selectedDeposit,
      smsReqButtonDisabled,
      isManualSms,
      explanation,
      approveButtonDisabled,
    ]
  );

  const renderConfirmationPopup = useCallback(
    () => (
      <div className="confirmation-popup">
        <div className="confirmation-box">
          <div className="icon-info icon-green">
            <FaCheckCircle />
          </div>
          <h3>Transaction Will Be Confirmed!</h3>
          <p style={{ marginBottom: "2rem" }}>
            Are you sure you want to continue?
          </p>
          <Divider orientation="horizontal" variant="middle" flexItem />
          <div className="transaction-info">
            <div className="info">
              <span className="label">Sender</span>
              <span className="label">Receipent</span>
            </div>
            <div className="info">
              <span className="value badge badge-pink">
                {selectedDeposit?.cardInfo?.name}
              </span>
              <span className="value badge badge-pink">
                {selectedDeposit?.recipientName}
              </span>
            </div>
            <div className="amount">
              <span className=" badge badge-green" style={{ fontSize: "2rem" }}>
                {selectedDeposit
                  ? new Intl.NumberFormat("tr-TR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(selectedDeposit.amount)
                  : ""}{" "}
                <span>₼</span>
              </span>
            </div>
          </div>
          <Divider orientation="horizontal" variant="middle" flexItem />
          <div className="buttons">
            {/* FIXME: */}
            <Button
              id="btn-deposit-approve-cancel"
              variant="outlined"
              color="error"
              sx={{
                width: "12rem",
                fontSize: "1.1rem",
                fontWeight: "700",
                height: "3.5rem",
              }}
              onClick={() => setConfirmationPopupVisible(false)}
            >
              Cancel
            </Button>
            <Button
              id="btn-deposit-approve-confirm"
              variant="contained"
              color="success"
              sx={{
                width: "12rem",
                fontSize: "1.1rem",
                fontWeight: "700",
                height: "3.5rem",
                backgroundColor: "#73B816",
                "&:hover": {
                  backgroundColor: "#73B816",
                },
              }}
              onClick={() => handleApprove(selectedDeposit?._id)}
            >
              Approve!
            </Button>
          </div>
        </div>
      </div>
    ),
    [confirmationPopupVisible]
  );

  const renderDeclinePopup = useCallback(
    () => (
      <div className="confirmation-popup">
        <div className="confirmation-box">
          <div className="icon-info icon-red">
            <MdCancel />
          </div>
          <h3>Transaction Will Be Rejected!</h3>
          <p style={{ marginBottom: "2rem" }}>
            Are you sure you want to continue?
          </p>
          <Divider orientation="horizontal" variant="middle" flexItem />
          <div className="transaction-info">
            <div className="info">
              <span className="label">Sender</span>
              <span className="label">Receipent</span>
            </div>
            <div className="info">
              <span className="value badge badge-pink">
                {selectedDeposit?.cardInfo?.name}
              </span>
              <span className="value badge badge-pink">
                {selectedDeposit?.recipientName}
              </span>
            </div>
            <div className="amount">
              <span className=" badge badge-red" style={{ fontSize: "2rem" }}>
                {selectedDeposit
                  ? new Intl.NumberFormat("tr-TR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(selectedDeposit.amount)
                  : ""}{" "}
                <span>₼</span>
              </span>
            </div>
          </div>
          <Divider orientation="horizontal" variant="middle" flexItem />
          <div className="buttons">
            <Button
              id="btn-deposit-reject-cancel"
              color="error"
              sx={{
                width: "12rem",
                fontSize: "1.1rem",
                fontWeight: "700",
                height: "3.5rem",
              }}
              onClick={() => setDeclinePopupVisible(false)}
            >
              Cancel
            </Button>
            <Button
              id="btn-deposit-reject-confirm"
              variant="contained"
              color="error"
              sx={{
                width: "12rem",
                fontSize: "1.1rem",
                fontWeight: "700",
                height: "3.5rem",
              }}
              onClick={() => handleDecline(selectedDeposit?._id)}
            >
              Reject!
            </Button>
          </div>
        </div>
      </div>
    ),
    [declinePopupVisible]
  );

  const renderManualAccessPopup = useCallback(
    () => (
      <div className="popup-manual-access">
        <CustomTextField
          id="tb-manual-access-code"
          label="Manual Access Code"
          sx={{
            "& .MuiOutlinedInput-root": { width: "20rem" },
            margin: "2.5rem 0",
          }}
          value={manualAccessCode}
          onChange={handleManualAccessCodeChange}
        />

        <div className="popup-footer">
          <div className="popup-footer-left">
            <Button
              id="btn-deposit-manual-cancel"
              variant="outlined"
              color="error"
              sx={{
                width: "12rem",
                fontSize: "1.1rem",
                fontWeight: "700",
                height: "3.5rem",
              }}
              onClick={() => setManualAccessPopupVisible(false)}
            >
              Cancel
            </Button>
          </div>
          <div className="popup-footer-right">
            <Button
              id="btn-deposit-manual-save"
              variant="contained"
              color="success"
              sx={{
                width: "12rem",
                fontSize: "1.1rem",
                fontWeight: "700",
                height: "3.5rem",
                backgroundColor: "#73B816",
                "&:hover": {
                  backgroundColor: "#73B816",
                },
              }}
              onClick={() => handleSetManualSms(selectedDeposit?._id)}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    ),
    [manualAccessCode, isApproveDisabled]
  );

  //TODO:
  const [filterType, setFilterType] = useState("All Transactions");

  const applyFilters = useCallback(() => {
    let filters = [];
    let filteredData = depositsArray;

    // Filter by transaction type
    switch (filterType) {
      case "Free and My Transactions":
        filters.push([
          ["reservedPerson", "=", null],
          "or",
          ["reservedPerson", "=", personId],
        ]);
        filteredData = filteredData.filter(
          (item) => !item.reservedPerson || item.reservedPerson === personId
        );
        break;
      case "My Transactions":
        filters.push(["reservedPerson", "=", personId]);
        filteredData = filteredData.filter(
          (item) => item.reservedPerson === personId
        );
        break;
      case "Free Transactions":
        filters.push(["reservedPerson", "=", null]);
        filteredData = filteredData.filter(
          (item) => item.reservedPerson === null
        );
        break;
      default:
        break;
    }

    // Filter by minAmount and maxAmount
    const amountFilters = [];
    if (!isNaN(parseFloat(minAmount))) {
      amountFilters.push(["amount", ">=", parseFloat(minAmount)]);
      filteredData = filteredData.filter(
        (item) => item.amount >= parseFloat(minAmount)
      );
    }
    if (!isNaN(parseFloat(maxAmount))) {
      amountFilters.push(["amount", "<=", parseFloat(maxAmount)]);
      filteredData = filteredData.filter(
        (item) => item.amount <= parseFloat(maxAmount)
      );
    }

    // Combine both filters
    if (amountFilters.length > 0) {
      filters.push(
        amountFilters.length > 1 ? [amountFilters, "and"] : amountFilters[0]
      );
    }

    const total = filteredData.reduce((sum, item) => sum + item.amount, 0);
    setTotalAmount(total); // Toplam miktarı güncelle

    // Apply the combined filters
    dxSource.filter(filters.length > 1 ? [filters, "and"] : filters[0] || null);
    dxSource.load();
  }, [filterType, minAmount, maxAmount]);

  const debouncedApplyFilters = useCallback(_.debounce(applyFilters, 300), [
    applyFilters,
  ]);

  useEffect(() => {
    debouncedApplyFilters();
  }, [filterType, minAmount, maxAmount, debouncedApplyFilters]);

  const handleFilterChange = (selectedValue) => {
    setFilterType(selectedValue);
  };

  const handleMinAmountChange = (e) => {
    setMinAmount(e.target.value);
  };

  const handleMaxAmountChange = (e) => {
    setMaxAmount(e.target.value);
  };

  //TODO:
  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: "before",
        widget: "dxButton",
        options: {
          text: `Total ${rowCount} Piece`,

          onClick: () => {
            navigator.clipboard.writeText(`Total ${rowCount} Piece`).then(
              () => {
                showNotification(
                  "Success",
                  "success",
                  "Total deposit count copied to clipboard"
                );
              },
              () => {}
            );
          },
          elementAttr: {
            class: "info-button",
          },
          width: 150,
          stylingMode: "outlined",
        },
      },
      {
        location: "before",
        widget: "dxButton",
        options: {
          text: `${new Intl.NumberFormat("tr-TR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(totalAmount)} ₼`,
          size: "large",
          onClick: () => {
            const formattedAmount = new Intl.NumberFormat("tr-TR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format();
            navigator.clipboard.writeText(`Total: ${formattedAmount} ₼`).then(
              () => {
                showNotification(
                  "Success",
                  "success",
                  "Total amount copied to clipboard"
                );
              },
              () => {}
            );
          },
          elementAttr: {
            class: "info-button info-button--amount",
          },
          width: 120,
          stylingMode: "outlined",
        },
      },
      {
        location: "before",
        widget: "dxSelectBox",
        options: {
          items: [
            "All Transactions",
            "Free and My Transactions",
            "Free Transactions",
            "My Transactions",
          ],
          value: filterType,
          showClearButton: false,
          width: 210,
          elementAttr: {
            class: "transaction-type-select",
          },
          onValueChanged: function (e) {
            let selectedValue = e.value;

            handleFilterChange(selectedValue);
          },
        },
      }
    );
  };

  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  const [contextMenuTargetRowId, setContextMenuTargetRowId] = useState(null);

  const menuItems = [
    {
      text: "Drop Deposit",
      onClick: () => handleDropDeposit(contextMenuTargetRowId),
    },
  ];

  const onContextMenuPreparing = (e) => {
    if (e.row && e.row.rowType === "data") {
      e.items = [
        {
          text: "Drop Deposit",
          onItemClick: () => handleDropDeposit(e.row.data.id),
        },
      ];
    }
  };

  const [rowCount, setRowCount] = useState(0);

  const calculateTotals = useCallback(
    _.debounce(async (dataSource) => {
      const loadOptions = {};
      const allData = await dataSource.store().load(loadOptions);

      const total = allData.reduce((sum, item) => sum + item.amount, 0);
      setTotalAmount(total);
      setRowCount(allData.length);
    }, 100), // 100ms debounce süresi
    []
  );

  const onContentReady = (e) => {
    const dataSource = e.component.getDataSource();
    calculateTotals(dataSource);
  };

  return (
    <div className="deposit">
      <div
        className="deposit-body deposit-pending-body"
        style={{ height: containerHeight }}
      >
        <div className="deposit-header-container">
          <div className="deposit-header">
            <FaClock className="deposit-header-icon" />
            <span>Pending Deposits</span>
          </div>
          <div
            id="btn-deposit-refresh"
            className="deposit-header-refresh"
            onClick={() => handleRefreshClick()}
          >
            <IoMdRefresh className="refresh-icon" />
            <span>Refresh</span>
          </div>
          {/* TODO: */}
          <Popup
            width={600}
            height={413}
            showTitle={true}
            dragEnabled={false}
            hideOnOutsideClick={true}
            visible={changeAmountPopupVisible}
            onHiding={handleChangeAmountPopupHidden}
            contentRender={renderChangeAmountPopup}
            showCloseButton={true}
            container=".dx-viewport"
            title="Change the deposit amount"
            animation={undefined}
            position={{ my: "center", at: "center", of: window }}
          />
          <Popup
            style={{ backgroundColor: "black" }}
            width={730}
            height={"auto"}
            showTitle={true}
            dragEnabled={true}
            hideOnOutsideClick={true}
            visible={detailPopupVisible}
            onHiding={handleDetailPopupHidden}
            contentRender={renderDetailPopup}
            showCloseButton={true}
            container=".dx-viewport"
            title="Deposit Details"
            animation={undefined}
            position={{ my: "center", at: "center", of: window }}
          />
          <Popup
            width={400}
            height={255}
            showTitle={true}
            dragEnabled={false}
            hideOnOutsideClick={true}
            visible={manualAccessPopupVisible}
            onHiding={handleManualAccessPopupHidden}
            contentRender={renderManualAccessPopup}
            showCloseButton={true}
            container=".dx-viewport"
            title="Manual Access"
            animation={undefined}
            position={{ my: "center", at: "center", of: window }}
          />
          <Popup
            id="popup-confirmation"
            width={400}
            height={420}
            hideOnOutsideClick={true}
            showTitle={true}
            title="Deposit Confirmation"
            visible={confirmationPopupVisible}
            onHiding={handleConfirmationPopupHidden}
            contentRender={renderConfirmationPopup}
            container=".dx-viewport"
            animation={undefined}
            position={{ my: "center", at: "center", of: window }}
          />
          <Popup
            id="popup-decline"
            width={400}
            height={420}
            hideOnOutsideClick={true}
            showTitle={true}
            title="Deposit Decline"
            visible={declinePopupVisible}
            onHiding={handleDeclinePopupHidden}
            contentRender={renderDeclinePopup}
            container=".dx-viewport"
            animation={undefined}
            position={{ my: "center", at: "center", of: window }}
          />
        </div>

        <div className="deposit-table ag-theme-quartz deposit-view-table">
          <div className="amount-filter-container">
            <CustomAmountTextField
              placeholder="Min Amount"
              value={minAmount}
              // onChange={(e) => setMinAmount(e.target.value)}
              onChange={handleMinAmountChange}
            />
            <CustomAmountTextField
              placeholder="Max Amount"
              value={maxAmount}
              // onChange={(e) => setMaxAmount(e.target.value)}
              onChange={handleMaxAmountChange}
            />
          </div>
          {/* TODO: */}
          <DataGrid
            // dataSource={dataGridSourceMemo}
            // repaintChangesOnly={true}

            id="dxDataGrid"
            dataSource={dxSource}
            showBorders={false}
            showRowLines={false}
            hoverStateEnabled={true}
            rowAlternationEnabled={false}
            allowColumnResizing={true}
            columnAutoWidth={true}
            columnMinWidth={50}
            columnResizingMode="nextColumn"
            height="100%"
            onSelectionChanged={onSelectionChanged}
            onToolbarPreparing={onToolbarPreparing}
            onContextMenuPreparing={onContextMenuPreparing}
            onContentReady={onContentReady}
          >
            <Selection mode="single" />
            <ColumnFixing enabled />
            <GroupPanel visible={false} />
            <SearchPanel visible={true} width={240} placeholder="Search" />
            <Paging defaultPageSize={25} />
            <Pager
              visible
              allowedPageSizes={[25, 50, 100]}
              showPageSizeSelector
              showInfo
              showNavigationButtons
            />
            <Scrolling
              useNative={false}
              scrollByContent
              scrollByThumb
              showScrollbar="onHover"
            />
            <Export
              enabled={false}
              fileName="easypropay-pending-deposit"
              allowExportSelectedData={false}
            />
            <Column
              caption="ID"
              dataField="id"
              alignment="center"
              cellRender={(data) => (
                <div className="badge badge-blue">{data.value}</div>
              )}
            />
            <Column
              caption="Transaction ID"
              dataField="transaction_id"
              alignment="center"
              cellRender={(data) => (
                <div className="badge badge-pink">{data.value}</div>
              )}
            />
            <Column
              caption="Trader"
              dataField="trader"
              alignment="center"
              cellRender={(data) => (
                <div className="badge badge-blue">{data.value}</div>
              )}
            />
            <Column
              caption="Host User ID"
              dataField="host_user_id"
              alignment="center"
              cellRender={(data) => (
                <div className="badge badge-orange">{data.value}</div>
              )}
            />
            <Column
              caption="Host User Name"
              dataField="host_user_name"
              alignment="center"
              cellRender={(data) => (
                <div className="badge badge-pink">{data.value}</div>
              )}
            />
            <Column
              caption="Sender"
              dataField="sender_full_name"
              alignment="center"
              cellRender={(data) => (
                <div className="badge badge-pink">{data.value}</div>
              )}
            />
            <Column
              caption="Amount"
              dataField="amount"
              alignment="center"
              dataType="number"
              cellRender={(data) => {
                // Format the amount using Intl.NumberFormat
                const formattedAmount = new Intl.NumberFormat("tr-TR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(data.value);

                return (
                  <div className="grid-cell-amount">
                    <div className="grid-amount">
                      <span
                        className={`badge ${
                          data.data.isAmountChanged
                            ? "badge-orange"
                            : "badge-green"
                        }`}
                      >{`${formattedAmount} ₼`}</span>
                    </div>
                    <BsPencilSquare
                      className="grid-amount-button"
                      onClick={() => handleAmountChangePopup(data.data.id)}
                    />
                  </div>
                );
              }}
            />
            <Column
              caption="Recipient"
              dataField="recipient"
              alignment="center"
              cellRender={(data) => (
                <div className="badge badge-red">{data.value}</div>
              )}
            />
            <Column
              caption="Create Date"
              dataField="create_date"
              alignment="center"
              dataType="datetime"
              sortIndex={0}
              sortOrder="asc"
              customizeText={(cellInfo) => {
                const options = {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                };
                return new Date(cellInfo.value)
                  .toLocaleDateString("tr-TR", options)
                  .replace(",", "");
              }}
            />
            <Column
              caption="Trx. Status"
              dataField="smsStatus"
              alignment="center"
              cellRender={(data) => {
                const rowData = data.data;

                return data.value !== "received" ? (
                  <Button
                    variant="contained"
                    color="success"
                    sx={{
                      fontSize: "1.1rem",
                      fontWeight: "700",
                      height: "2.4rem",
                      backgroundColor: "#F59F00",
                      "&:hover": {
                        backgroundColor: "#F59F00",
                      },
                    }}
                    startIcon={
                      <MdOutlineDoNotDisturbOff
                        style={{ fontSize: "1.3rem" }}
                      />
                    }
                  >
                    {data.value}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="success"
                    sx={{
                      fontSize: "1.1rem",
                      fontWeight: "700",
                      height: "2.4rem",
                      backgroundColor: "#73B816",
                      "&:hover": {
                        backgroundColor: "#73B816",
                      },
                    }}
                    startIcon={
                      <IoCheckmarkDoneSharp style={{ fontSize: "1.3rem" }} />
                    }
                  >
                    {data.data.smsCode}
                  </Button>
                );
              }}
            />
            {/* //FIXME: */}
            <Column
              caption="Reserve"
              dataField="reservedPerson"
              alignment="center"
              fixed
              fixedPosition="right"
              cellRender={(data) => {
                console.log("Reserve data", data);
                const rowData = data.data;

                return data.value === null || data.value === undefined ? (
                  <Button
                    id={`reserve-button-${data.data.id}`}
                    variant="contained"
                    color="success"
                    sx={{
                      width: "11.187rem",
                      fontSize: "1.1rem",
                      fontWeight: "700",
                      height: "2.4rem",
                      backgroundColor: "#73B816",
                      "&:hover": {
                        backgroundColor: "#73B816",
                      },
                    }}
                    startIcon={<FiTarget style={{ fontSize: "1.3rem" }} />}
                    onClick={() => {
                      handleReserve(data.data.id);
                    }}
                  >
                    Reserve
                  </Button>
                ) : data.value === personId ? (
                  <Button
                    id={`in-progress-button-${data.data.id}`}
                    variant="contained"
                    sx={{
                      width: "11.187rem",
                      fontSize: "1.1rem",
                      fontWeight: "700",
                      height: "2.4rem",
                      backgroundColor: "#D6336C",
                      "&:hover": {
                        backgroundColor: "#D6336C",
                      },
                    }}
                    startIcon={
                      <RiProgress5Line style={{ fontSize: "1.3rem" }} />
                    }
                  >
                    In Progress
                  </Button>
                ) : (
                  <Button
                    id={`person-button-${data.data.id}`}
                    variant="contained"
                    sx={{
                      width: "11.187rem",
                      fontSize: "1.1rem",
                      fontWeight: "700",
                      height: "2.4rem",
                    }}
                    startIcon={<FaUser style={{ fontSize: "1.3rem" }} />}
                  >
                    {data.data?.reservedPersonName?.length > 7
                      ? `${data.data?.reservedPersonName?.substring(0, 7)}..`
                      : data.data?.reservedPersonName}
                  </Button>
                );
              }}
            />
            {/* TODO: */}
            <Column
              caption="Details"
              dataField="detail"
              alignment="center"
              fixed
              fixedPosition="right"
              cellRender={(data) => (
                <Button
                  variant="contained"
                  id={`detail-button-${data.data.id}`}
                  sx={{
                    fontSize: "1.1rem",
                    fontWeight: "700",
                    height: "2.4rem",
                    backgroundColor: "#F59F00",
                    "&:hover": {
                      backgroundColor: "#F59F00",
                    },
                  }}
                  startIcon={<CgDetailsMore style={{ fontSize: "1.3rem" }} />}
                  onClick={() => {
                    if (data.data.reservedPerson === personId) {
                      console.log("Detail clicked", data.data.reservedPerson);
                      handleDetailClick(data.data.id);
                    }
                  }}
                >
                  Detail
                </Button>
              )}
            />
          </DataGrid>
          <ContextMenu
            dataSource={menuItems}
            target=".dx-row" // DataGrid satırlarına sağ tıklama menüsü ekler
            visible={contextMenuVisible}
            onHidden={() => setContextMenuVisible(false)}
            onItemClick={(e) => e.itemData.onClick()} // Menü öğesi tıklama işleyicisi
          />
        </div>
      </div>
    </div>
  );
};

export default DepositView;
