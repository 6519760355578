import react, { useState, useEffect } from "react";
import "./PanelScreen.css";

import { FaCreditCard } from "react-icons/fa";
import { PiHandWithdrawFill } from "react-icons/pi";
import { MdBlockFlipped } from "react-icons/md";
import { MdLogout } from "react-icons/md";
import { BiSolidReport } from "react-icons/bi";
import { FaHome } from "react-icons/fa";
import { IoSettingsSharp } from "react-icons/io5";
import { FaCashRegister } from "react-icons/fa6";
import { RiAdminFill } from "react-icons/ri";

import OptionView from "../views/OptionView";
import MainView from "../views/MainView";
import DepositView from "../views/DepositView";
import DepositReportView from "../views/DepositReportView";
import InCardView from "../views/InCardView";
import InCardReport from "../views/InCardReport";
import BlackListView from "../views/BlackListView";
import CashOperations from "../views/CashOperations";
import AdminController from "../views/AdminController";

import Header from "../components/Header";
import axios from "axios";
import { showNotification } from "../components/NotificationProvider";

import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";

// const renewSession = async () => {
//   try {
//     const response = await axios.post(
//       "http://localhost:3001/user/renewSession",
//       {},
//       { withCredentials: true }
//     );
//     console.log(response.data.message);
//   } catch (err) {
//     console.error("Failed to renew session", err);
//     showNotification(
//       "Error",
//       "danger",
//       "Failed to renew session. Please log in again."
//     );
//   }
// };

const PanelScreen = ({ onExit, tempUsername, role }) => {
  const [panelScreen, setPanelScreen] = useState("home");

  const [menuHide, setMenuHide] = useState(false);
  const [headerMenuHide, setHeaderMenuHide] = useState(true);

  const [navType, setNavType] = useState(
    window.innerWidth > 1140 ? "desktop" : "mobile"
  );

  // useEffect(() => {
  //   const intervalId = setInterval(renewSession, 10 * 1000); // 5 minutes in milliseconds

  //   return () => clearInterval(intervalId); // Cleanup on component unmount
  // }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1140) {
        setNavType("desktop");
        setHeaderMenuHide(true);
      } else {
        setNavType("mobile");
      }
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function to remove the event listener when the component unmounts
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleMenu = () => {
    if (navType === "desktop") {
      setMenuHide(!menuHide);
    } else if (navType === "mobile") {
      setHeaderMenuHide(!headerMenuHide);
    }
  };

  const handleHeaderNavClick = (screen) => {
    setPanelScreen(screen);
  };

  const screens = {
    "panel-main": MainView,
    "panel-development": () => (
      <div className="panel-body-cons">
        <div className="panel-body__container">
          <img
            className="undercons"
            src={require("../assets/images/undercons.png")}
          />
          <div className="undercons-warning">
            This site is currently under development.
          </div>
        </div>
      </div>
    ),
    "panel-deposit": DepositView,
    "panel-deposit-report": DepositReportView,
    "panel-incard": InCardView,
    "panel-incard-report": InCardReport,
    "panel-blacklist": BlackListView,
    "panel-cash-operations": CashOperations,
    "panel-options": OptionView,
    "panel-admin": AdminController,
  };

  const ActiveScreen = screens[panelScreen] || MainView;

  const menuItems = [
    { screen: "panel-main", label: "Home", Icon: FaHome },
    { screen: "panel-deposit", label: "Pending Deposits", Icon: FaCreditCard },
    {
      screen: "panel-deposit-report",
      label: "Deposit Report",
      Icon: BiSolidReport,
    },
    {
      screen: "panel-incard",
      label: "Pending Withdrawals",
      Icon: PiHandWithdrawFill,
    },
    {
      screen: "panel-incard-report",
      label: "Withdrawal Report",
      Icon: BiSolidReport,
    },
    {
      screen: "panel-blacklist",
      label: "Black List Management",
      Icon: MdBlockFlipped,
    },
    { screen: "panel-options", label: "Settings", Icon: IoSettingsSharp },
    ...(role === "admin"
      ? [
          {
            screen: "panel-cash-operations",
            label: "Cash Operations",
            Icon: IoSettingsSharp,
          },
        ]
      : []),
    ...(role === "admin"
      ? [{ screen: "panel-admin", label: "Admin Panel", Icon: RiAdminFill }]
      : []),
  ];

  const bottomMenuItems = [{ label: "Logout", action: onExit, Icon: MdLogout }];

  return (
    <div className="panel-main">
      <div className={menuHide ? "panel-nav-hide panel-nav" : "panel-nav"}>
        <div
          className="panel-brand"
          onClick={() => {
            window.location.href = "/";
          }}
        >
          <img
            src={require("../assets/images/easypropaylogo.png")}
            alt="Logo"
            className="logo"
          />
          EASYPROPAY
        </div>

        <div className="panel-menu">
          <ul>
            {menuItems.map((item) => (
              <li
                id={item.screen}
                className={
                  panelScreen === item.screen ? "active" : "panel-menu-item"
                }
                key={item.screen}
                onClick={() => setPanelScreen(item.screen)}
              >
                <item.Icon className="panel-menu-item-logo" />
                {item.label}
              </li>
            ))}
          </ul>
          <ul className="bottom-menu">
            {bottomMenuItems.map((item) => (
              <li onClick={item.action} key={item.label}>
                <item.Icon className="panel-menu-item-logo" />
                {item.label}
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="panel-body">
        {panelScreen !== "panel-development" && (
          <Header
            onMenuHandle={handleMenu}
            tempUsername={tempUsername}
            type={navType}
            navHide={headerMenuHide}
            handleHeaderNavClick={handleHeaderNavClick}
            screens={screens}
            activeScreen={panelScreen}
            role={role}
          />
        )}
        <ActiveScreen />
      </div>
    </div>
  );
};

export default PanelScreen;
