import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import { userReducer } from "./slices/userSlice";
import { traderReducer } from "./slices/traderSlice";
import { depositReducer } from "./slices/depositSlice";
import { withdrawalReducer } from "./slices/withdrawalSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    trader: traderReducer,
    deposits: depositReducer,
    withdrawals: withdrawalReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

setupListeners(store.dispatch);

export * from "./thunks/User";
export * from "./thunks/Trader";
export * from "./thunks/Deposit";
export * from "./thunks/Withdrawal";
