import { createSlice } from "@reduxjs/toolkit";

const withdrawalSlice = createSlice({
  name: "withdrawals",
  initialState: {
    withdrawals: [],
  },

  extraReducers(builder) {},
});

export const withdrawalReducer = withdrawalSlice.reducer;
